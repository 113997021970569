export enum PurchaseOrderNature {
    COMPONENT = 'component',
    PROCESS = 'process',
    PROCESSING = 'processing',
    OUTSOURCE = 'outsource',
    RAW_MATERIAL = 'raw_material',
}

export const PurchaseOrderNatureLabels = {
    [PurchaseOrderNature.COMPONENT]: 'Component',
    [PurchaseOrderNature.PROCESS]: 'Process',
    [PurchaseOrderNature.OUTSOURCE]: 'Outsource',
    [PurchaseOrderNature.RAW_MATERIAL]: 'Raw Material',
    [PurchaseOrderNature.PROCESSING]: 'Production - In-house',
};
