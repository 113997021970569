import { zodResolver } from '@hookform/resolvers/zod';
import { ColumnDef } from '@tanstack/react-table';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { useCreateLink } from 'src/app/_api_adb2c/purchase/link/hooks/use-create-link';
import { PurchaseOrderDataLinkDataObject } from 'src/app/_api_adb2c/purchase/link/requests/purchase-order-link-data-object';
import { usePurchases } from 'src/app/_api_adb2c/purchase/purchase/hooks/use-purchases';
import { ManifestItemModel } from 'src/app/_api_adb2c/purchase/purchase/models/manifest-item.model';
import { useSalesOrdersV2 } from 'src/app/_api_adb2c/sales/sales/hooks/use-sales-orders-v2';
import { DetailLabel } from 'src/app/components-v2/detail-label';
import { DialogV2 } from 'src/app/components-v2/dialog-v2';
import { TableV2 } from 'src/app/components-v2/table-v2';
import { SelectInputV2 } from 'src/app/components/Form/SelectInputV2';
import { generateOrderTitle } from 'src/app/utils/generate-order-title';
import { z } from 'zod';
import { useMemo } from 'react';

const linkSchema = z.object({
    purchaseOrderId: z.string(),
});

function getLinkColumns() {
    const columns: ColumnDef<ManifestItemModel>[] = [
        {
            id: 'product',
            header: 'Product',
            accessorFn: (row) => row.product.name,
        },
        {
            id: 'quantity',
            header: 'Quantity',
            accessorFn: (row) => row.quantity,
        },
        {
            id: 'price',
            header: 'Price',
            accessorFn: (row) => row.ppu,
        },
    ];

    return columns;
}

interface SalesOrderLinkProps {
    isOpen: boolean;
    onClose: () => void;
}

export function SalesOrderLink({ isOpen, onClose }: SalesOrderLinkProps) {
    const [searchParams] = useSearchParams();
    const delegateId = searchParams.get('delegateId') || '';
    const orderId = searchParams.get('order');

    const { data } = useSalesOrdersV2(delegateId);
    const { data: purchases } = usePurchases(
        { page: 0, limit: 0, draft: false },
        delegateId
    );

    const { mutateAsync: createLink, isLoading } = useCreateLink();

    const form = useForm<z.infer<typeof linkSchema>>({
        mode: 'onChange',
        resolver: zodResolver(linkSchema),
    });

    const onSubmit = async (data: z.infer<typeof linkSchema>) => {
        const request: PurchaseOrderDataLinkDataObject = {
            purchaseOrders: [
                ...(memoizedOrder?.links?.map((x) => x._id) || []),
                data.purchaseOrderId,
            ],
        };

        await createLink({
            body: request,
            order: memoizedOrder?.purchaseOrderId || '',
            delegateId: delegateId,
        });

        reset();
    };

    const reset = () => {
        onClose();
        form.reset();
    };

    const purchaseOrderId = form.watch('purchaseOrderId');

    const memoizedOrder = useMemo(() => {
        return data?.find((o) => o._id === orderId);
    }, [data, orderId]);

    const memoizedPurchase = useMemo(() => {
        return purchases?.data?.find((p) => p._id === purchaseOrderId);
    }, [purchases, purchaseOrderId]);

    const memoizedItems = useMemo(() => {
        if (!purchaseOrderId) return [];

        const purchase = purchases?.data?.find(
            (p) => p._id === purchaseOrderId
        );

        if (!purchase) return [];

        const latestVersion = purchase.versions[purchase.versions.length - 1];
        if (!latestVersion) return [];

        return latestVersion.manifest;
    }, [purchases, purchaseOrderId]);

    return (
        <DialogV2
            open={isOpen}
            title='Link Po'
            isStepDialog
            form={form}
            formName='linkForm'
            isLoading={isLoading}
            onSubmit={onSubmit}
            onClose={onClose}
            steps={[
                {
                    title: 'Select Purchase Order',
                    description:
                        'Select the purchase order to link to the sales order',
                    content: (
                        <div className='grid h-full grid-rows-[auto_1fr] gap-4 overflow-auto px-2'>
                            <SelectInputV2
                                className='w-1/2'
                                name='purchaseOrderId'
                                label='Purchase Order'
                                options={
                                    purchases?.data
                                        .filter(
                                            (x) =>
                                                !memoizedOrder?.links?.some(
                                                    (y) => y._id === x._id
                                                )
                                        )
                                        .map((p) => ({
                                            label: generateOrderTitle(p),
                                            value: p._id,
                                        })) || []
                                }
                            />

                            <TableV2
                                label='Purchase Order Items'
                                columns={getLinkColumns()}
                                data={memoizedItems || []}
                                fixedHeight='300px'
                            />
                        </div>
                    ),
                },
                {
                    title: 'Verify & Confirm',
                    description: 'Verify and confirm the link',
                    content: (
                        <div className='flex h-full flex-col gap-8 px-2'>
                            <DetailLabel title='Link Purchase Order'>
                                {memoizedPurchase
                                    ? generateOrderTitle(memoizedPurchase)
                                    : '--'}
                            </DetailLabel>

                            <DetailLabel title='To Buyer Purchase Order'>
                                {memoizedOrder?.purchase
                                    ? generateOrderTitle(memoizedOrder.purchase)
                                    : '--'}
                            </DetailLabel>
                        </div>
                    ),
                },
            ]}
        />
    );
}
