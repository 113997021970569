import { useQuery } from '@tanstack/react-query';
import { useContextStore } from 'src/app/stores/context-store';
import { useSalesOrderService } from '../../../sales/sales/hooks/use-sales-order-service';
import { SalesOrderModel } from '../../../sales/sales/models/sales-order.model';
import {
    PurchaseModel,
    PurchaseOrderTraceModel,
} from '../models/purchase.model';
import { usePurchaseService } from './use-purchase-service';
import { useRulesetService } from '../../../workspace/ruleset/hooks/use-ruleset-service';
import { WorkspaceRulesetModel } from '../../../workspace/ruleset/ruleset.model';
import { useWorkspaceService } from 'src/app/_api_adb2c/workspace/workspace/hooks/use-workspace-service';
import { WorkspaceModel } from 'src/app/_api_adb2c/workspace/workspace/workspace.model';

export interface PurchaseInformation {
    purchase?: PurchaseModel;
    traces?: PurchaseOrderTraceModel[];
    salesOrders?: {
        [purchaseId: string]: SalesOrderModel;
    };
    rulesets?: {
        [purchaseId: string]: WorkspaceRulesetModel;
    };
    workspaces?: {
        [workspaceId: string]: WorkspaceModel;
    };
}

const PURCHASE_INFORMATION_QUERY_KEY = (
    purchaseId: string,
    delegateId: string
) => ['purchase-information', purchaseId, delegateId];

export function usePurchaseInformation(
    purchaseId: string,
    delegateId: string = '',
    enabled: boolean = true
) {
    const context = useContextStore();
    const { service: purchaseService } = usePurchaseService();
    const { service: salesService } = useSalesOrderService();
    const { service: rulesetService } = useRulesetService();
    const { service: workspaceService } = useWorkspaceService();

    return useQuery<PurchaseInformation>({
        queryKey: PURCHASE_INFORMATION_QUERY_KEY(purchaseId, delegateId),
        queryFn: async () => {
            // Fetch purchase data
            const purchase = await purchaseService.get(
                delegateId || context.workspace?._id || '',
                purchaseId,
                '1'
            );

            const workspace = await workspaceService.get(
                delegateId || context.workspace?._id || '',
                purchase.workspace
            );

            // Fetch purchase traces
            const traces = await purchaseService.trace(
                delegateId || context.workspace?._id || '',
                purchaseId,
                '1'
            );

            // Get all purchase IDs and ruleset IDs
            const purchaseIds = [
                purchaseId,
                ...(traces?.map(
                    (trace: PurchaseOrderTraceModel) => trace._id
                ) || []),
            ];
            const rulesetIds = [
                ...(purchase?.rules || []),
                ...(traces?.flatMap(
                    (trace: PurchaseOrderTraceModel) => trace.rules || []
                ) || []),
            ];

            const workspaceIds = [
                purchase.workspace,
                ...(traces?.map(
                    (trace: PurchaseOrderTraceModel) => trace.workspace
                ) || []),
            ];

            // Fetch sales orders
            const salesOrders = await salesService.batch(
                delegateId || context.workspace?._id || '',
                { targets: purchaseIds },
                '1'
            );

            // Fetch rulesets
            const rulesets = await rulesetService.batch({
                targets: [...rulesetIds],
            });

            // Fetch workspace
            const workspaces = await workspaceService.batchWorkspace(
                delegateId || context.workspace?._id || '',
                { workspaces: workspaceIds }
            );

            // Convert sales orders to map
            const salesOrdersMap = salesOrders?.reduce(
                (
                    acc: { [key: string]: SalesOrderModel },
                    order: SalesOrderModel
                ) => {
                    if (order.purchaseOrderId) {
                        acc[order.purchaseOrderId] = order;
                    }
                    return acc;
                },
                {}
            );

            // Convert rulesets to map
            const rulesetsMap = rulesets.reduce(
                (acc: { [key: string]: WorkspaceRulesetModel }, ruleset) => {
                    if (ruleset._id) {
                        acc[ruleset._id] = ruleset;
                    }
                    return acc;
                },
                {}
            );

            // Convert workspaces to map
            const workspacesMap = workspaces.reduce(
                (
                    acc: { [key: string]: WorkspaceModel },
                    workspace: WorkspaceModel
                ) => {
                    if (workspace._id) {
                        acc[workspace._id] = workspace;
                    }
                    return acc;
                },
                {}
            );

            return {
                purchase: { ...purchase, workspace },
                traces,
                salesOrders: salesOrdersMap,
                rulesets: rulesetsMap,
                workspaces: workspacesMap,
            };
        },
        enabled:
            enabled &&
            !!purchaseId &&
            !!purchaseService &&
            !!salesService &&
            !!rulesetService,
    });
}
