import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { DialogV2 } from 'src/app/components-v2/dialog-v2';
import { VirtualTable } from 'src/app/components-v2/virtual-table';
import { ColumnDef } from '@tanstack/react-table';
import { useContextStore } from 'src/app/stores/context-store';
import { useSalesOrdersV2 } from 'src/app/_api_adb2c/sales/sales/hooks/use-sales-orders-v2';
import { SalesOrderDocModel } from 'src/app/_api_adb2c/sales/sales/models/sales-order-doc.model';
import { SalesOrderAttachDataObject } from 'src/app/_api_adb2c/sales/sales/request/sales-order-attach-data-object';
import { SalesOrderDocumentDataObject } from 'src/app/_api_adb2c/sales/sales/request/sales-order-document-data-object';
import {
    SupportDocType,
    SupportDocTypeLabel,
} from 'src/app/_api_adb2c/shared/support-doc-type.enum';
import { useAttachmentUpload } from 'src/app/_api_adb2c/attachment/hooks/use-attachment-upload';
import { useAttachSalesOrderFiles } from 'src/app/_api_adb2c/sales/sales/hooks/use-attach-sales-order-files';
import { FileCreateDataObject } from 'src/app/_api_adb2c/attachment/file-create-data-object';
import axios from 'axios';
import saveAs from 'file-saver';
import { getFileIdFromAssetName } from 'src/infrastructure/utils/extract-asset-id';
import { FileListItem, PendingFileItem } from './FileItems';
import { FileUploadSection } from './FileUploadSection';
import { OptionalDocumentsSection } from './OptionalDocumentsSection';
import { useDownload } from '../hooks/useDownload';

interface OptionalFileWithType {
    file: File;
    preview?: string;
    documentType?: string;
    existingFile?: SalesOrderDocModel;
}

interface Document {
    type: string;
    label: string;
    files: SalesOrderDocModel[];
}

interface DocumentColumnsProps {
    uploadedFiles: { [key: string]: { file: File; preview?: string }[] };
    handleFileUpload: (files: FileList, documentType: string) => void;
    removeUploadedFile: (documentType: string, index: number) => void;
    filesToDelete: string[];
    handleMarkForDeletion: (assetName: string) => void;
    handleUndoDelete: (assetName: string) => void;
    downloadingFile: string | null;
    handleDownload: (file: SalesOrderDocModel) => void;
    handleNewFileDownload: (file: File) => void;
}

const getDocumentColumns = (
    props: DocumentColumnsProps
): ColumnDef<Document>[] => [
    {
        id: 'type',
        header: 'Type',
        accessorFn: (row: Document) => row.label,
    },
    {
        id: 'files',
        header: 'Files',
        cell: ({ row }) => (
            <div className='flex w-full flex-col gap-2'>
                {row.original.files.map((file, index) => (
                    <FileListItem
                        key={file.file.assetName || index}
                        file={file}
                        index={index}
                        filesToDelete={props.filesToDelete}
                        downloadingFile={props.downloadingFile}
                        onDownload={props.handleDownload}
                        onDelete={props.handleMarkForDeletion}
                        onUndoDelete={props.handleUndoDelete}
                    />
                ))}

                {props.uploadedFiles[row.original.type]?.map((file, index) => (
                    <PendingFileItem
                        key={file.preview || index}
                        file={file}
                        onRemove={() =>
                            props.removeUploadedFile(row.original.type, index)
                        }
                    />
                ))}

                <FileUploadSection
                    type={row.original.type}
                    uploadedFiles={props.uploadedFiles}
                    onUpload={(files) =>
                        props.handleFileUpload(files, row.original.type)
                    }
                />
            </div>
        ),
    },
];

interface SalesOrderUploadProps {
    isOpen: boolean;
    onClose: () => void;
}

export function SalesOrderUpload({ isOpen, onClose }: SalesOrderUploadProps) {
    const [searchParams] = useSearchParams();
    const delegateId = searchParams.get('delegateId') || '';
    const orderId = searchParams.get('order') || '';
    const [uploadedFiles, setUploadedFiles] = useState<{
        [key: string]: { file: File; preview?: string }[];
    }>({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const context = useContextStore();
    const { download } = useDownload();
    const [downloadingFile, setDownloadingFile] = useState<string | null>(null);
    const [filesToDelete, setFilesToDelete] = useState<string[]>([]);
    const [optionalFiles, setOptionalFiles] = useState<OptionalFileWithType[]>(
        []
    );

    const handleDownload = useCallback(
        async (file: SalesOrderDocModel) => {
            try {
                setDownloadingFile(file.file.assetName);
                await download(
                    getFileIdFromAssetName(file.file.assetName),
                    file.file.originalName || 'download'
                );
            } catch (error) {
                console.error('Error downloading file:', error);
            } finally {
                setDownloadingFile(null);
            }
        },
        [download]
    );

    const handleNewFileDownload = useCallback((file: File) => {
        saveAs(file, file.name);
    }, []);

    const { mutateAsync: upload } = useAttachmentUpload();
    const { mutateAsync: attach } = useAttachSalesOrderFiles();

    const uploadAndReturnFile = async (type: string, file: File) => {
        const request: FileCreateDataObject = {
            contentType: file.type,
            size: file.size,
            name: file.name,
            genre: 'temporary',
            uploadedBy: context.user?._id || '',
            workspace: context.workspace?._id || '',
        };

        const token = await upload({
            file: request,
            assetType: 'salesOrder',
            delegateId: delegateId,
        });

        await axios.put(token.token, file, {
            headers: {
                'Content-Type': file.type,
                'x-ms-blob-type': 'BlockBlob',
            },
        });

        return {
            file: {
                assetName: token.attachment.assetName,
                autoResign: token.attachment.autoResign,
                container: token.attachment.container,
                contentType: token.attachment.contentType,
                extension: file.name.split('.').pop() || '',
                originalName: token.attachment.originalName,
                readReleaseTime: token.attachment.readReleaseTime,
                size: file.size,
            },
            type: type as SupportDocType,
        };
    };

    const handleMarkForDeletion = useCallback((assetName: string) => {
        setFilesToDelete((prev) => [...prev, assetName]);
    }, []);

    const handleUndoDelete = useCallback((assetName: string) => {
        setFilesToDelete((prev) => prev.filter((id) => id !== assetName));
    }, []);

    const handleOptionalFileUpload = useCallback((files: File[]) => {
        const newFiles = files.map((file) => ({
            file,
            preview: URL.createObjectURL(file),
        }));
        setOptionalFiles((prev) => [...prev, ...newFiles]);
    }, []);

    const handleOptionalFileRemove = useCallback((index: number) => {
        setOptionalFiles((prev) => {
            const newFiles = [...prev];
            if (newFiles[index].preview) {
                URL.revokeObjectURL(newFiles[index].preview || '');
            }
            newFiles.splice(index, 1);
            return newFiles;
        });
    }, []);

    const handleOptionalFileTypeSelect = useCallback(
        (index: number, type: string) => {
            setOptionalFiles((prev) => {
                const newFiles = [...prev];
                newFiles[index] = { ...newFiles[index], documentType: type };
                return newFiles;
            });
        },
        []
    );

    const handleSubmit = async () => {
        if (!orderId) return;

        try {
            setIsSubmitting(true);
            const documents: SalesOrderDocumentDataObject[] = [];

            // Include existing files that aren't marked for deletion
            order?.documents?.forEach((doc) => {
                if (!filesToDelete.includes(doc.file.assetName)) {
                    documents.push({
                        file: doc.file,
                        type: doc.type,
                        id: doc.id,
                        comment: doc.comment,
                        certificateNumber: doc.certificateNumber,
                    });
                }
            });

            // Upload all new files for each document type
            for (const [type, files] of Object.entries(uploadedFiles)) {
                for (const { file } of files) {
                    const response = await uploadAndReturnFile(type, file);
                    documents.push(response);
                }
            }

            // Handle optional files
            for (const file of optionalFiles) {
                if (file.documentType) {
                    if ('existingFile' in file) {
                        // This is an existing file, add it to documents if not marked for deletion
                        const existingDoc =
                            file.existingFile as SalesOrderDocModel;
                        if (
                            !filesToDelete.includes(existingDoc.file.assetName)
                        ) {
                            documents.push({
                                file: existingDoc.file,
                                type: file.documentType as SupportDocType,
                                id: existingDoc.id,
                                comment: existingDoc.comment,
                                certificateNumber:
                                    existingDoc.certificateNumber,
                            });
                        }
                    } else {
                        // This is a new file, upload it
                        const response = await uploadAndReturnFile(
                            file.documentType as SupportDocType,
                            file.file
                        );
                        documents.push(response);
                    }
                }
            }

            // Attach documents to sales order
            const request: SalesOrderAttachDataObject = {
                documents: documents,
            };

            await attach({
                id: orderId,
                body: request,
                delegateId: delegateId,
            });

            onClose();
        } catch (error) {
            console.error('Error uploading documents:', error);
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleFileUpload = useCallback(
        (files: FileList | null, documentType: string) => {
            if (!files) return;

            const newFiles = Array.from(files).map((file) => ({
                file,
                preview: URL.createObjectURL(file),
            }));

            setUploadedFiles((prev) => ({
                ...prev,
                [documentType]: newFiles, // Replace instead of append
            }));
        },
        []
    );

    const removeUploadedFile = useCallback(
        (documentType: string, index: number) => {
            setUploadedFiles((prev) => {
                const newFiles = { ...prev };
                if (newFiles[documentType]) {
                    URL.revokeObjectURL(
                        newFiles[documentType][index].preview || ''
                    );
                    newFiles[documentType] = newFiles[documentType].filter(
                        (_, i) => i !== index
                    );
                    if (newFiles[documentType].length === 0) {
                        delete newFiles[documentType];
                    }
                }
                return newFiles;
            });
        },
        []
    );

    useEffect(() => {
        return () => {
            // Cleanup object URLs on unmount
            Object.values(uploadedFiles).forEach((files) => {
                files.forEach((file) => {
                    if (file.preview) {
                        URL.revokeObjectURL(file.preview);
                    }
                });
            });
        };
    }, [uploadedFiles]);

    const { data } = useSalesOrdersV2(delegateId);

    const order = useMemo(() => {
        return data?.find((o) => o._id === orderId);
    }, [data, orderId]);

    const rulesetInformation = useMemo(() => {
        const ruleset = order?.rulesets?.[0];

        if (!ruleset) return { requiredDocuments: [], baseDocuments: [] };

        const processes = order.purchase?.processes || [];

        const requiredDocuments = ruleset.rules
            .filter((rule) =>
                rule.processes.some((process) => processes.includes(process))
            )
            .map((rule) => rule.document);

        return { requiredDocuments, baseDocuments: ruleset.rules };
    }, [order]);

    const items = useMemo(() => {
        const requiredDocuments: any = [];
        const optionalDocuments: any = [];

        // Add required documents from ruleset
        rulesetInformation.requiredDocuments.forEach((document) => {
            requiredDocuments.push({
                type: document,
                label: SupportDocTypeLabel[document as SupportDocType],
                files: [],
            });
        });

        // Add ALL document types as optional documents
        Object.entries(SupportDocType).forEach(([key, value]) => {
            // Skip if it's already a required document
            if (!rulesetInformation.requiredDocuments.includes(value)) {
                optionalDocuments.push({
                    type: value,
                    label: SupportDocTypeLabel[value as SupportDocType],
                    files: [],
                });
            }
        });

        // Add existing documents to their respective arrays
        order?.documents?.forEach((document) => {
            if (requiredDocuments.some((x: any) => x.type === document.type)) {
                requiredDocuments
                    .find((x: any) => x.type === document.type)
                    ?.files.push(document);
            } else {
                const optionalDoc = optionalDocuments.find(
                    (x: any) => x.type === document.type
                );
                if (optionalDoc) {
                    optionalDoc.files.push(document);
                }
            }
        });

        return { requiredDocuments, optionalDocuments };
    }, [rulesetInformation, order]);

    const documentColumns = useMemo(() => {
        const props: DocumentColumnsProps = {
            uploadedFiles,
            handleFileUpload,
            removeUploadedFile,
            filesToDelete,
            handleMarkForDeletion,
            handleUndoDelete,
            downloadingFile,
            handleDownload,
            handleNewFileDownload,
        };

        return getDocumentColumns(props);
    }, [
        uploadedFiles,
        handleFileUpload,
        removeUploadedFile,
        filesToDelete,
        handleMarkForDeletion,
        handleUndoDelete,
        downloadingFile,
        handleDownload,
        handleNewFileDownload,
    ]);

    useEffect(() => {
        if (order?.documents) {
            // Get optional documents (documents that aren't in required types)
            const existingOptionalDocs = order.documents
                .filter(
                    (doc) =>
                        !rulesetInformation.requiredDocuments.includes(doc.type)
                )
                .map((doc) => ({
                    file: new File([], doc.file.originalName || 'file', {
                        type: doc.file.contentType,
                    }),
                    documentType: doc.type,
                    existingFile: doc, // Add this to identify existing files
                }));

            setOptionalFiles(existingOptionalDocs);
        }
    }, [order, rulesetInformation]);

    return (
        <DialogV2
            open={isOpen}
            onClose={onClose}
            title='Upload Documents'
            size='lg'
            steps={[
                {
                    title: 'Required Documents',
                    description:
                        'Upload the required documents for the sales order',
                    content: (
                        <VirtualTable
                            title='Required Documents'
                            headerClassName='bg-red-800 text-white'
                            columns={documentColumns}
                            data={items.requiredDocuments || []}
                        />
                    ),
                },
                {
                    title: 'Optional Documents',
                    description:
                        'Upload optional documents and select their types',
                    content: (
                        <OptionalDocumentsSection
                            availableTypes={items.optionalDocuments.map(
                                (doc: Document) => ({
                                    value: doc.type,
                                    label: doc.label,
                                })
                            )}
                            uploadedFiles={optionalFiles}
                            onUpload={handleOptionalFileUpload}
                            onRemove={handleOptionalFileRemove}
                            onTypeSelect={handleOptionalFileTypeSelect}
                        />
                    ),
                },
            ]}
            isStepDialog
            formName='uploadForm'
            onFinish={handleSubmit}
            isLoading={isSubmitting}
        />
    );
}
