import { ServiceConfigInterface } from 'src/domain/interfaces/config.context.interface';
import { CompanyUpdateRequestObject } from './company-update-request-object';
import { WorkspaceUpdateRequestObject } from './workspace-update-request-object';
import { WorkspaceApiClient } from './workspace.client';
import { BatchWorkspaceRequestObject } from './batch-workspace.dto';

export class WorkspaceService {
    private server: WorkspaceApiClient;

    constructor(
        config: ServiceConfigInterface,
        private readonly token: string
    ) {
        this.server = new WorkspaceApiClient(config, token);
    }

    async getMembers(workspace: string) {
        console.log(`service<workspace>| getMembers(): Enter`);
        console.log(
            `service<workspace>| getMembers(): $workspace = ${workspace}`
        );

        return await this.server.getMembers(workspace);
    }

    async updateWorkspace(
        workspace: string,
        body: WorkspaceUpdateRequestObject
    ) {
        console.log(`service<workspace>| updateWorkspace(): Enter`);
        console.log(
            `service<workspace>| updateWorkspace(): $workspace = ${workspace}`
        );
        console.log(`service<workspace>| updateWorkspace(): $body = ${body}`);

        return await this.server.updateWorkspace(workspace, body);
    }

    async getWorkspacesByAccount(account: string) {
        console.log(`service<workspace>| getWorkspacesByAccount(): Enter`);
        console.log(
            `service<workspace>| getWorkspacesByAccount(): $account = ${account}`
        );

        return await this.server.getWorkspacesByAccount(account);
    }

    async updateWorkspaceCompany(
        workspace: string,
        body: CompanyUpdateRequestObject
    ) {
        console.log(`service<workspace>| updateWorkspaceCompany(): Enter`);
        console.log(
            `service<workspace>| updateWorkspaceCompany(): $workspace = ${workspace}`
        );
        console.log(
            `service<workspace>| updateWorkspaceCompany(): $body = ${body}`
        );

        return await this.server.updateWorkspaceCompany(workspace, body);
    }

    async searchByCompanyName(name: string, diversion = '0') {
        console.log(`service<workspace>| searchByCompanyName(): Enter`);
        console.log(
            `service<workspace>| searchByCompanyName(): $name = ${name}`
        );
        console.log(
            `service<workspace>| searchByCompanyName(): $diversion = ${diversion}`
        );

        return await this.server.searchByCompanyName(name, diversion);
    }

    async get(workspace: string, diversion: string = '0') {
        console.log(`service<workspace>| get(): Enter`);
        console.log(`service<workspace>| get(): $workspace = ${workspace}`);
        return await this.server.get(workspace, diversion);
    }

    async getCertificationTypes() {
        console.log(`service<workspace>| getCertificationTypes(): Enter`);

        return await this.server.getCertificationTypes();
    }

    async batchWorkspace(
        workspaceId: string,
        data: BatchWorkspaceRequestObject
    ) {
        console.log(`service<workspace>| batchWorkspace(): Enter`);
        console.log(
            `service<workspace>| batchWorkspace(): $workspaceId = ${workspaceId}`
        );
        console.log(
            `service<workspace>| batchWorkspace(): $data = ${JSON.stringify(
                data
            )}`
        );

        return await this.server.getBatchWorkspaces(workspaceId, data);
    }
}
