import { ColumnDef } from '@tanstack/react-table';
import { ArrowRight, CloudDownload, Download, Plus, Trash } from 'lucide-react';
import { useCallback, useMemo, useState } from 'react';
import { DefaultExtensionType, defaultStyles, FileIcon } from 'react-file-icon';
import { useSearchParams } from 'react-router-dom';
import { useMaterial } from 'src/app/_api_adb2c/product/material/hooks/use-material';
import { useUpdateMaterial } from 'src/app/_api_adb2c/product/material/hooks/use-update-material';
import { ProductMaterialDataObject } from 'src/app/_api_adb2c/product/material/request/product-material-data-object';
import { useSuppliers } from 'src/app/_api_adb2c/purchase/suppliers/hooks/use-suppliers';
import {
    SupportDocType,
    SupportDocTypeLabel,
} from 'src/app/_api_adb2c/shared/support-doc-type.enum';
import { useBatchGetFiles } from 'src/app/_api_adb2c/workspace/file/hooks/use-batch-get-files';
import { useWorkspaceCertificationTypes } from 'src/app/_api_adb2c/workspace/workspace/hooks/use-workspace-certification-types';
import { TableV2 } from 'src/app/components-v2/table-v2';
import { useFileDownloader } from 'src/app/hooks/use-file-downloader';
import { getFileIdFromAssetName } from 'src/infrastructure/utils/extract-asset-id';
import { cn } from 'src/lib/utils';
import { AddMaterialDocument } from './add-material-document';

interface Props {
    materialId: string;
}

interface VirtualDocument {
    _id: string;
    name: string;
    type: string;
    format: string;
    uploadedBy: string;
    fileType: 'Component' | 'Substance';
    subRows?: VirtualDocument[];
    isCustom?: boolean;
    certificateNumber?: string;
}

export function MaterialDocuments({ materialId }: Props) {
    const [searchParams] = useSearchParams();
    const delegateId = searchParams.get('delegateId') || '';

    const [mode, setMode] = useState<'create' | 'delete'>();

    const { data } = useMaterial(materialId, delegateId);
    const { data: certificateType } = useWorkspaceCertificationTypes();
    const { data: suppliers } = useSuppliers(delegateId);

    const { downloadFiles } = useFileDownloader();
    const { mutateAsync: update } = useUpdateMaterial();

    const removeDocument = useCallback(
        async (id: string) => {
            if (!data) return;

            const existingDocument = data?.documents?.find((doc) => {
                const docFileId = getFileIdFromAssetName(doc.file.assetName);

                return docFileId === id;
            });

            if (!existingDocument) return;

            const request: ProductMaterialDataObject = {
                name: data.name,
                unitOfMeasurement: data.unitOfMeasurement,
                unitCost: data.unitCost,
                unitCostCurrency: data.unitCostCurrency,
                workspace: data.workspace,
                documents: data?.documents?.filter((doc) => {
                    const docFileId = getFileIdFromAssetName(
                        doc.file.assetName
                    );

                    return docFileId !== id;
                }),
            };

            await update({
                id: materialId,
                body: request,
            });
        },
        [data, materialId, update]
    );

    const memoizedCompositions = useMemo(() => {
        if (!data) return [];
        if (!data.compositions) return [];

        return data.compositions.map((comp) => {
            const supplier = suppliers?.find(
                (supplier) => supplier.seller._id === comp.supplier
            );

            return {
                ...comp,
                supplier: supplier,
            };
        });
    }, [data, suppliers]);

    const memoizedSdsFiles = useMemo(() => {
        const sdsFiles: string[] = [];

        memoizedCompositions.forEach((comp) => {
            if (comp.sdsFileId) {
                sdsFiles.push(comp.sdsFileId);
            }
        });

        return sdsFiles;
    }, [memoizedCompositions]);

    const { data: documents } = useBatchGetFiles(memoizedSdsFiles);

    const memoizedData = useMemo(() => {
        const compositions: VirtualDocument[] = [];

        data?.documents?.forEach((doc) => {
            const fileId = getFileIdFromAssetName(doc.file.assetName);

            const certType = certificateType?.find(
                (type) => type.id === doc.type
            );

            console.log('certificate type', certType);

            compositions.push({
                _id: fileId,
                name: doc.file.originalName,
                type: certType ? certType.name : doc.type,
                format: doc.file.originalName.split('.').pop() || '',
                uploadedBy: doc.uploader,
                fileType: 'Component',
                certificateNumber: doc.certificateNumber,
            });
        });

        if (memoizedCompositions.length > 0) {
            memoizedCompositions.forEach((comp) => {
                if (comp.sdsFileId) {
                    const existingDocument = documents?.find(
                        (doc) => doc._id === comp.sdsFileId
                    );

                    if (existingDocument) {
                        compositions.push({
                            _id: comp.sdsFileId,
                            name: existingDocument.name,
                            type: SupportDocType.SAFETY_DATA_SHEET,
                            format:
                                existingDocument.name.split('.').pop() || '',
                            uploadedBy: existingDocument.uploadedBy,
                            fileType: 'Substance',
                        });
                    }
                }
            });
        }

        return compositions;
    }, [data, memoizedCompositions, documents, certificateType]);

    console.log(memoizedData);

    const columns: ColumnDef<VirtualDocument>[] = useMemo(() => {
        const columns: ColumnDef<VirtualDocument>[] = [
            {
                id: 'document',
                header: 'Document',
                accessorFn: (row) => row.name,
                cell: ({ row }) => {
                    if (row.depth > 0) {
                        return row.original.name;
                    }

                    return (
                        <div
                            className='flex items-center gap-4'
                            onClick={() => row.toggleExpanded()}
                        >
                            {row.subRows.length > 0 && (
                                <ArrowRight
                                    size={16}
                                    className={
                                        row.getIsExpanded()
                                            ? 'rotate-90 transform'
                                            : ''
                                    }
                                />
                            )}

                            <span>{row.original.name}</span>
                        </div>
                    );
                },
            },
            {
                id: 'type',
                header: 'Type',
                accessorFn: (row) => {
                    return (
                        SupportDocTypeLabel[row.type as SupportDocType] ||
                        row.type
                    );
                },
            },
            {
                id: 'certificateNumber',
                header: 'Certificate Number',
                accessorFn: (row) => row.certificateNumber || '--',
            },
            {
                id: 'format',
                header: 'Format',
                size: 10,
                cell: ({ row }) => {
                    const extension = row.original.name.split('.').pop();

                    if (!extension) return '--';

                    const styles =
                        defaultStyles?.[extension as DefaultExtensionType];

                    return (
                        <div className='h-5 w-5'>
                            <FileIcon
                                {...styles}
                                radius={4}
                                extension={extension}
                            />
                        </div>
                    );
                },
            },
            {
                id: 'uploadedBy',
                header: 'Uploaded By',
                accessorFn: (row) => row.uploadedBy || '--',
            },
            {
                id: 'actions',
                size: 10,
                header: 'Actions',
                cell: ({ row }) => {
                    return (
                        <div className='flex gap-4'>
                            <CloudDownload
                                size={16}
                                onClick={() => {
                                    if (row.original.name) {
                                        downloadFiles([row.original._id]);
                                    }
                                }}
                                className='hover:scale-125'
                            />

                            <Trash
                                size={16}
                                onClick={() => {
                                    removeDocument(row.original._id);
                                }}
                                className={cn(
                                    'hover:scale-125',
                                    row.original.fileType === 'Substance'
                                        ? 'hidden'
                                        : 'block'
                                )}
                            />
                        </div>
                    );
                },
            },
        ];

        return columns;
    }, [downloadFiles, removeDocument]);

    return (
        <>
            <div className='flex h-full flex-col'>
                <TableV2
                    columns={columns}
                    data={memoizedData}
                    subRowsField='subRows'
                    label='Documents'
                    groupBy='fileType'
                    groupLabels={{
                        Component: 'Component Documents',
                        Substance: 'Substance Documents',
                    }}
                    actions={[
                        {
                            id: 'add',
                            label: 'Add Document',
                            icon: <Plus size={16} />,
                            onClick: () => setMode('create'),
                        },
                        {
                            id: 'download',
                            label: 'Download All',
                            icon: <Download size={16} />,
                            onClick: () =>
                                downloadFiles(
                                    memoizedData.map((doc) => doc._id)
                                ),
                        },
                    ]}
                />
            </div>

            {mode === 'create' && (
                <AddMaterialDocument
                    open={mode === 'create'}
                    materialId={materialId}
                    onClose={() => setMode(undefined)}
                />
            )}
        </>
    );
}
