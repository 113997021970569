import { useQuery } from '@tanstack/react-query';
import { toast } from 'sonner';
import { useContextStore } from 'src/app/stores/context-store';
import { useSupplierService } from './use-supplier-service';
import { SupplierModel } from '../models/supplier.model';
import { SUPPLIERS_QUERY_KEY } from './supplier-query-keys';

export function useSuppliers(delegateId?: string) {
    const context = useContextStore();
    const { service } = useSupplierService();

    return useQuery<SupplierModel[]>({
        queryKey: SUPPLIERS_QUERY_KEY(
            delegateId || context.workspace?._id || ''
        ),
        queryFn: async () => {
            return await service.list(
                delegateId || context.workspace?._id || ''
            );
        },
        onError: (err) => {
            const error = err as Error;

            toast.error(`Failed to fetch suppliers: ${error.message}`);
        },
    });
}
