import {
    PurchaseModel,
    PurchaseOrderTraceModel,
} from 'src/app/_api_adb2c/purchase/purchase/models/purchase.model';
import { DisplayLabel } from '../../Products/Details';
import { PurchaseOrderNatureLabels } from 'src/app/_api_adb2c/purchase/purchase/enums/purchase-order-nature.enum';
import { Badge } from 'src/components/ui/badge';
import { cn } from 'src/app/utils/cn';
import { useSuppliers } from 'src/app/_api_adb2c/purchase/suppliers/hooks/use-suppliers';
import { DateTime } from 'luxon';
import { usePurchaseTrace } from 'src/app/_api_adb2c/purchase/purchase/hooks/use-purchase-trace';
import { useParams, useSearchParams } from 'react-router-dom';

interface Props {
    data?: PurchaseOrderTraceModel | PurchaseModel;
}

function findLargestDepth(data: PurchaseOrderTraceModel[]): number | null {
    if (data.length === 0) {
        return null; // Return null if the list is empty
    }

    return Math.max(...data.map((item) => item.depth + 1));
}

const checkIsPurchaseTrace = (
    data: PurchaseOrderTraceModel | PurchaseModel
): data is PurchaseOrderTraceModel => {
    return (data as PurchaseOrderTraceModel).depth !== undefined;
};

type StatisticsCardProps = {
    label: string;
    subtitle?: string;
    value?: string | number;
};

export const StatisticsCard: React.FC<StatisticsCardProps> = ({
    label,
    subtitle,
    value,
}) => {
    return (
        <div className='flex max-h-[70px] min-h-[70px] min-w-[150px] max-w-[150px] flex-col gap-4 rounded-lg border px-2 py-2 text-[10px] font-bold text-gray-500'>
            <span className='uppercase'>{label}</span>

            <span className='flex items-end justify-end gap-1'>
                <span className='truncate text-xs font-bold text-black'>
                    {value}
                </span>

                <span className='uppercase'>{subtitle}</span>
            </span>
        </div>
    );
};

export const PurchaseOverview = ({ data }: Props) => {
    const { id } = useParams<{ id: string }>();
    const [searchParams] = useSearchParams();
    const delegateId = searchParams.get('delegateId') || '';

    const { data: suppliers } = useSuppliers();
    const { data: traces } = usePurchaseTrace(id || '', delegateId);

    const version = data?.versions?.[data?.versions?.length - 1];
    const totalOrderQuantity = version?.manifest
        ?.reduce((acc, curr) => acc + curr.quantity, 0)
        .toString();

    const totalOrderAmount = version?.manifest
        ?.reduce((acc, curr) => acc + curr.quantity * curr.ppu, 0)
        .toString();

    if (!data) {
        return <></>;
    }

    return (
        <>
            <div id='details' className='flex gap-8'>
                <div className='flex max-w-[150px] flex-col flex-wrap gap-3 text-xs'>
                    <DisplayLabel
                        label='Supplier'
                        // value={data?.supplier?.name || '--'}
                        value={
                            checkIsPurchaseTrace(data)
                                ? data?.supplier?.seller?.name || '--'
                                : data?.supplier?.seller?.name || '--'
                        }
                    />

                    <DisplayLabel
                        label='Supplier (Secondary)'
                        value={
                            suppliers?.find(
                                (x) =>
                                    x.seller._id ===
                                    data?.intermediary?.workspace
                            )?.seller?.name
                        }
                    />

                    <DisplayLabel
                        label='Nature'
                        value={
                            data?.nature
                                ? PurchaseOrderNatureLabels[data.nature]
                                : '-'
                        }
                    />

                    <DisplayLabel label='Currency' value={data?.currency} />
                </div>

                <div className='flex max-w-[150px] flex-col flex-wrap gap-3 text-xs'>
                    <DisplayLabel
                        label='Issued On'
                        value={
                            data?.createdOn
                                ? new Date(data.createdOn)
                                      .toLocaleDateString()
                                      .slice(0, 10)
                                : '-'
                        }
                    />

                    <DisplayLabel
                        label='1st Delivery (Expected)'
                        value={
                            data?.shippedOn && data?.shippedOn.length > 0
                                ? DateTime.fromJSDate(
                                      new Date(data?.shippedOn[0])
                                  ).toFormat('yyyy-MM-dd')
                                : '--'
                        }
                    />

                    <DisplayLabel
                        label='Applicable Processes'
                        value={
                            <div className='flex max-w-[150px] gap-2 overflow-auto'>
                                {data?.processes?.map((x) => {
                                    return (
                                        <Badge
                                            variant='outline'
                                            className='min-w-[80px] max-w-[80px] justify-center truncate bg-gray-300 text-[8px]'
                                        >
                                            <span className='truncate'>
                                                {x}
                                            </span>
                                        </Badge>
                                    );
                                })}
                            </div>
                        }
                    />
                </div>

                <div className='flex max-w-[150px] flex-col flex-wrap gap-3 text-xs'>
                    <DisplayLabel
                        label='Ship To (Delivery) Address'
                        // value={data?.owner?.supplier.seller?.name}
                        value={data?.shipToAddress}
                        className='flex-[2]'
                    />

                    <DisplayLabel
                        label='Shipment Dates'
                        value={
                            data?.shippedOn && data?.shippedOn.length > 0
                                ? data.shippedOn
                                      .map((date) =>
                                          DateTime.fromJSDate(
                                              new Date(date)
                                          ).toFormat('yyyy-MM-dd')
                                      )
                                      .join(', ')
                                : '--'
                        }
                    />
                </div>

                <div className='flex flex-col flex-wrap gap-3 text-xs'>
                    <DisplayLabel
                        label='Key Statistics'
                        value={
                            <div
                                className={cn(
                                    `flex flex-nowrap gap-2 overflow-auto`,
                                    ''
                                )}
                            >
                                <StatisticsCard
                                    label='Order Total'
                                    subtitle='USD'
                                    value={totalOrderAmount || 0}
                                />

                                <StatisticsCard
                                    label='Order Quantities'
                                    subtitle='PCS'
                                    value={
                                        parseInt(totalOrderQuantity || '0') || 0
                                    }
                                />

                                <StatisticsCard
                                    label='Cascade Levels'
                                    subtitle='Tiers'
                                    value={findLargestDepth(traces || []) || 0}
                                    // value='7'
                                />
                            </div>
                        }
                    />

                    <DisplayLabel
                        label='Mandatory Doc. Readiness'
                        // value={data?.owner?.supplier.seller?.name}
                    />
                </div>
            </div>
        </>
    );
};
