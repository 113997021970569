import 'reactflow/dist/style.css';
import './App.css';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { StrictMode, useEffect } from 'react';
import { hotjar } from 'react-hotjar';

import './i18n';

import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import { Toaster } from 'src/components/ui/sonner';
import ErrorBoundary from './components-v2/error-boundary';
import { AppRouterV2 } from './router/app-router';

// Do not remove these as these are antd dependencies to work with dayjs.
// https://github.com/ant-design/ant-design/issues/26190#issuecomment-703673400
dayjs.extend(weekday);
dayjs.extend(localeData);

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 2 * 60 * 1000,
            retry: false,
        },
    },
});

interface Props {
    pca: PublicClientApplication;
}

const App: React.FC<Props> = ({ pca }) => {
    useEffect(() => {
        hotjar.initialize(3314138, 6);
    }, []);

    useEffect(() => {
        window.addEventListener('error', (e) => {
            if (e.message === 'ResizeObserver loop limit exceeded') {
                const resizeObserverErrDiv = document.getElementById(
                    'webpack-dev-server-client-overlay-div'
                );
                const resizeObserverErr = document.getElementById(
                    'webpack-dev-server-client-overlay'
                );
                if (resizeObserverErr) {
                    resizeObserverErr.setAttribute('style', 'display: none');
                }
                if (resizeObserverErrDiv) {
                    resizeObserverErrDiv.setAttribute('style', 'display: none');
                }
            }
        });
    }, []);

    const customFallback = (
        <div className='flex min-h-screen flex-col items-center justify-center p-4'>
            <h2 className='mb-2 text-xl font-semibold text-red-600'>
                Something went wrong
            </h2>
            <p className='mb-4 text-gray-600'>
                We're sorry, but something went wrong. Please try refreshing the
                page.
            </p>
            <button
                onClick={() => window.location.reload()}
                className='rounded-md bg-red-600 px-4 py-2 text-white hover:bg-red-700'
            >
                Refresh Page
            </button>
        </div>
    );

    return (
        <StrictMode>
            <ErrorBoundary fallback={customFallback}>
                <MsalProvider instance={pca}>
                    <QueryClientProvider client={queryClient}>
                        <AppRouterV2 pca={pca} />
                        <Toaster duration={3000} />
                        <ReactQueryDevtools />
                    </QueryClientProvider>
                </MsalProvider>
            </ErrorBoundary>
        </StrictMode>
    );
};

export default App;
