import { Copy, Edit, Trash } from 'lucide-react';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FileResultObject } from 'src/app/_api_adb2c/attachment/file-result-object';
import { useAttachmentDownload } from 'src/app/_api_adb2c/attachment/hooks/use-attachment-download';
import { useMaterial } from 'src/app/_api_adb2c/product/material/hooks/use-material';
import {
    ProductUom,
    ProductUomLabels,
} from 'src/app/_api_adb2c/purchase/purchase/enums/product-uom.enum';
import { DetailLabel } from 'src/app/components-v2/detail-label';
import { TooltipWrapper } from 'src/app/components/TooltipWrapper';
import { useBreadcrumbStore } from 'src/app/stores/breadcrumb-store';
import { useContextStore } from 'src/app/stores/context-store';
import BlankImage from 'src/assets/blank-image.png';
import DocImage from 'src/assets/document.png';
import OrderImage from 'src/assets/orders.png';
import ProductImage from 'src/assets/products.png';
import SubstanceImage from 'src/assets/substance.png';
import {
    Tabs,
    TabsContent,
    TabsList,
    TabsTrigger,
} from 'src/components/ui/tabs';
import { DeleteMaterial } from '../delete-material';
import { EditMaterialV2 } from '../edit-material';
import { MaterialDocuments } from './Document/material-documents';
import { MaterialProduct } from './Product';
import { MaterialBillOfSubstance } from './Substance';
import { CloneMaterial } from './clone-material';
import { MaterialOrder } from './Order';

enum MenuOptions {
    BILL_OF_SUBSTANCE = 'B.O.S',
    PRODUCTS = 'Products',
    ORDERS = 'Orders',
    DOCS = 'Docs',
}

interface Material2Model {
    _id: string;
    description?: string;
    name: string;
    pictures?: string[];
    specifications?: {
        width: number;
        height: number;
        length: number;
        weight: number;
    };
    unitOfMeasurement: string;
    unitCost: number;
    unitCostCurrency: string;
    isReleased: boolean;
    supplier?: string[];
    workspace: string;
    documents: string[];
    compositions: {
        _id: string;
        name: string;
        supplier: string;
        isManMade: boolean;
        substanceName: string;
        substanceRegCode: string;
        subCompositions: {
            name: string;
            substanceCode: string;
            isManmade: boolean;
            weight: number;
            actualWeight: number;
            subCompositions: {
                name: string;
                substanceCode: string;
                isManmade: boolean;
                weight: number;
                actualWeight: number;
                subCompositions: {}[];
                supplier: string;
                sdsFileId: string;
                substanceRegCode: string;
            }[];
            supplier: string;
            sdsFileId: string;
            substanceRegCode: string;
        }[];
        weight: number;
        actualWeight: number;
        createdOn: string;
        lastUpdatedOn: string;
    }[];
}

export const MaterialDetails = () => {
    const context = useContextStore();
    const { setBreadcrumbs } = useBreadcrumbStore();
    const { id } = useParams<{ id: string }>();

    const { data } = useMaterial(id || '');
    const [action, setAction] = useState<'edit' | 'delete' | 'clone'>();
    const [images, setImages] = useState<FileResultObject[]>([]);

    const { mutateAsync: download } = useAttachmentDownload();

    const menuOptions = [
        {
            title: MenuOptions.BILL_OF_SUBSTANCE,
            icon: SubstanceImage,
            alt: 'bom-icon',
        },
        {
            title: MenuOptions.PRODUCTS,
            icon: ProductImage,
            alt: 'product-icon',
        },
        {
            title: MenuOptions.ORDERS,
            icon: OrderImage,
            alt: 'order-icon',
        },
        {
            title: MenuOptions.DOCS,
            icon: DocImage,
            alt: 'doc-icon',
        },
    ];

    const getRenderedContent = useCallback(
        (title: string) => {
            switch (title) {
                case MenuOptions.BILL_OF_SUBSTANCE:
                    return <MaterialBillOfSubstance material={id || ''} />;
                case MenuOptions.PRODUCTS:
                    return <MaterialProduct materialId={id || ''} />;
                case MenuOptions.ORDERS:
                    return <MaterialOrder materialId={id || ''} />;
                case MenuOptions.DOCS:
                    return <MaterialDocuments materialId={id || ''} />;
                default:
                    return <></>;
            }
        },
        [id]
    );

    const downloadImages = useCallback(async () => {
        if (!data || !data.pictures) return [];

        const images = await Promise.all(
            data.pictures.map(async (picture) => {
                const image = await download({
                    body: {
                        id: picture,
                        genre: 'product',
                        uploadedBy: context.user?._id || '',
                        workspace: context.workspace?._id || '',
                    },
                });

                return image;
            })
        );

        setImages(images);
    }, [data, download, context.user, context.workspace]);

    useEffect(() => {
        downloadImages();
    }, [downloadImages]);

    useEffect(() => {
        if (!data) return;

        setBreadcrumbs([
            {
                label: 'Components',
                navigateTo: '/components',
            },
            {
                label: data.name,
            },
        ]);
    }, [setBreadcrumbs, data]);

    return (
        <>
            <div className='flex h-full flex-col gap-6'>
                <div className='grid grid-cols-12 gap-4'>
                    <div className='col-span-12'>
                        <span className='text-md font-bold'>{data?.name}</span>
                    </div>

                    <div className='col-span-12 grid grid-cols-12 gap-6 rounded-lg border border-gray-200 bg-white px-4 py-4'>
                        <div className='col-span-12 flex w-full items-center justify-between'>
                            <span className='text-xs font-bold underline decoration-red-800 decoration-2 underline-offset-8'>
                                General Information
                            </span>

                            <div className='flex gap-4'>
                                <TooltipWrapper label='Clone'>
                                    <Copy
                                        size={16}
                                        className='cursor-pointer hover:scale-125'
                                        onClick={() => {
                                            setAction('clone');
                                        }}
                                    />
                                </TooltipWrapper>

                                <TooltipWrapper label='Edit'>
                                    <Edit
                                        size={16}
                                        className='cursor-pointer hover:scale-125'
                                        onClick={() => setAction('edit')}
                                    />
                                </TooltipWrapper>

                                <TooltipWrapper label='Delete'>
                                    <Trash
                                        size={16}
                                        className='cursor-pointer hover:scale-125'
                                        onClick={() => setAction('delete')}
                                    />
                                </TooltipWrapper>
                            </div>
                        </div>

                        <div className='col-span-8 grid grid-cols-2 gap-4'>
                            <DetailLabel title='Component Name'>
                                {data?.name}
                            </DetailLabel>

                            <DetailLabel title='Unit of Measurement'>
                                {data?.unitOfMeasurement
                                    ? ProductUomLabels[
                                          data.unitOfMeasurement as ProductUom
                                      ]
                                    : '--'}
                            </DetailLabel>

                            <DetailLabel title='Description'>
                                {data?.description || '--'}
                            </DetailLabel>

                            <DetailLabel title='Specifications'>
                                W: {data?.specifications?.width} x H:{' '}
                                {data?.specifications?.height} x L:{' '}
                                {data?.specifications?.length}
                            </DetailLabel>

                            <DetailLabel title='Weight (KG)'>
                                {data?.specifications?.weight || '--'}
                            </DetailLabel>
                        </div>

                        <div className='col-span-4 flex justify-end gap-4'>
                            <img
                                className='max-h-[200px] min-h-[200px] min-w-[200px] max-w-[200px] rounded-l p-1'
                                src={images?.[0]?.token || BlankImage}
                                alt={images?.[0]?.attachment.originalName || ''}
                            />
                            <div>
                                {Array.from({ length: 4 }).map((_, index) => (
                                    <img
                                        className='max-h-[50px] min-h-[50px] min-w-[50px] max-w-[50px] rounded-l p-1'
                                        src={
                                            images?.[index + 1]?.token ||
                                            BlankImage
                                        }
                                        alt={
                                            images?.[index + 1]?.attachment
                                                .originalName || ''
                                        }
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>

                <div className='flex-1'>
                    <Tabs
                        defaultValue={MenuOptions.BILL_OF_SUBSTANCE}
                        className='flex h-full flex-col justify-start'
                    >
                        <TabsList className='my-4 self-start'>
                            {menuOptions.map((x, index) => {
                                return (
                                    <TabsTrigger
                                        value={x.title}
                                        className='w-24'
                                    >
                                        <figure className='flex cursor-pointer flex-col items-center rounded capitalize'>
                                            <img
                                                src={x.icon}
                                                alt={x.alt}
                                                className='min-h-8 min-w-8 max-w-8 max-h-8 rounded-l p-1'
                                                key={index}
                                                id={`${
                                                    x.alt
                                                }-${index.toString()}`}
                                            />
                                            <figcaption className='text-sm text-gray-500 dark:text-gray-400'>
                                                {x.title}
                                            </figcaption>
                                        </figure>
                                    </TabsTrigger>
                                );
                            })}
                        </TabsList>

                        {menuOptions.map((x) => {
                            return (
                                <TabsContent
                                    className='h-full flex-1'
                                    key={`menu-${x.title}`}
                                    value={x.title}
                                >
                                    {getRenderedContent(x.title)}
                                </TabsContent>
                            );
                        })}
                    </Tabs>
                </div>
            </div>

            {action === 'edit' && data && (
                <EditMaterialV2
                    open={action === 'edit'}
                    data={data}
                    onClose={() => setAction(undefined)}
                />
            )}

            {action === 'delete' && data && (
                <DeleteMaterial
                    open={action === 'delete'}
                    material={data}
                    onClose={() => setAction(undefined)}
                />
            )}

            {action === 'clone' && data && (
                <CloneMaterial
                    open={action === 'clone'}
                    onClose={() => setAction(undefined)}
                    material={data}
                />
            )}
        </>
    );
};
