import { DateTime } from 'luxon';
import { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { DatePicker } from 'src/app/components-v2/date-picker';
import { Input } from 'src/components/ui/input';
import { Label } from 'src/components/ui/label';
import { SubstanceReportTable } from './substance-report-table';

export function SubstanceReport() {
    const [searchParams, setSearchParams] = useSearchParams({
        type: 'apparel',
    });

    const startDate = useMemo(() => {
        const startDate = searchParams.get('startDate');

        if (!startDate) return undefined;

        return DateTime.fromISO(startDate).toJSDate();
    }, [searchParams]);

    const endDate = useMemo(() => {
        const endDate = searchParams.get('endDate');

        if (!endDate) return undefined;

        return DateTime.fromISO(endDate).toJSDate();
    }, [searchParams]);

    useEffect(() => {
        const paramStartDate = searchParams.get('startDate');
        const paramEndDate = searchParams.get('endDate');

        if (!paramStartDate || !paramEndDate) {
            setSearchParams((prev) => {
                const now = DateTime.now();
                prev.set(
                    'startDate',
                    now.startOf('month').toFormat('yyyy-MM-dd')
                );
                prev.set('endDate', now.toFormat('yyyy-MM-dd'));

                return prev;
            });
        }
    }, [searchParams, setSearchParams]);

    return (
        <div className='grid h-full grid-cols-1 grid-rows-[auto_1fr] gap-8'>
            <div className='grid grid-cols-1 gap-4 rounded-lg bg-white px-4 py-2 shadow'>
                <span className='text-sm font-bold uppercase'>
                    Report Filter
                </span>

                <div className='grid grid-cols-6 gap-4'>
                    <div>
                        <Label
                            htmlFor='startDate'
                            className='text-xs font-normal uppercase text-gray-400'
                        >
                            From
                        </Label>
                        <DatePicker
                            id='startDate'
                            className='w-full text-xs'
                            date={startDate}
                            maxDate={endDate}
                            onSelect={(date) => {
                                setSearchParams((prev) => {
                                    const newDate =
                                        DateTime.fromJSDate(date).toFormat(
                                            'yyyy-MM-dd'
                                        );

                                    prev.set('startDate', newDate);

                                    return prev;
                                });
                            }}
                        />
                    </div>

                    <div>
                        <Label
                            htmlFor='endDate'
                            className='text-xs font-normal uppercase text-gray-400'
                        >
                            Until
                        </Label>
                        <DatePicker
                            id='endDate'
                            className='w-full text-xs'
                            date={endDate}
                            minDate={startDate}
                            onSelect={(date) => {
                                setSearchParams((prev) => {
                                    const newDate =
                                        DateTime.fromJSDate(date).toFormat(
                                            'yyyy-MM-dd'
                                        );
                                    prev.set('endDate', newDate);

                                    return prev;
                                });
                            }}
                        />
                    </div>

                    <div className=''>
                        <Label
                            htmlFor='category'
                            className='text-xs font-normal uppercase text-gray-400'
                        >
                            Category
                        </Label>

                        <Input
                            id='category'
                            className='w-full text-xs'
                            value={searchParams.get('category') ?? ''}
                            placeholder='Category'
                            onChange={(e) => {
                                setSearchParams((prev) => {
                                    prev.set('category', e.target.value);

                                    if (e.target.value === '') {
                                        prev.delete('category');
                                    }

                                    return prev;
                                });
                            }}
                        />
                    </div>

                    <div>
                        <Label
                            htmlFor='substance'
                            className='text-xs font-normal uppercase text-gray-400'
                        >
                            Threshold Weight
                        </Label>
                        <Input
                            id='thresholdWeight'
                            type='number'
                            className='w-full text-xs'
                            value={searchParams.get('thresholdWeight') ?? ''}
                            placeholder='Max Weight'
                            onChange={(e) => {
                                setSearchParams((prev) => {
                                    prev.set('thresholdWeight', e.target.value);

                                    if (e.target.value === '') {
                                        prev.delete('thresholdWeight');
                                    }

                                    return prev;
                                });
                            }}
                        />
                    </div>

                    <div>
                        <Label
                            htmlFor='substance'
                            className='text-xs font-normal uppercase text-gray-400'
                        >
                            Substance
                        </Label>
                        <Input
                            id='substanceName'
                            className='w-full text-xs'
                            value={searchParams.get('substanceName') ?? ''}
                            placeholder='Substance'
                            onChange={(e) => {
                                setSearchParams((prev) => {
                                    prev.set('substanceName', e.target.value);

                                    if (e.target.value === '') {
                                        prev.delete('substanceName');
                                    }

                                    return prev;
                                });
                            }}
                        />
                    </div>

                    <div>
                        <Label
                            htmlFor='casNumber'
                            className='text-xs font-normal uppercase text-gray-400'
                        >
                            CAS Number
                        </Label>
                        <Input
                            id='casNumber'
                            className='w-full text-xs'
                            value={searchParams.get('casNumber') ?? ''}
                            placeholder='CAS Number'
                            onChange={(e) => {
                                setSearchParams((prev) => {
                                    prev.set('casNumber', e.target.value);

                                    if (e.target.value === '') {
                                        prev.delete('casNumber');
                                    }

                                    return prev;
                                });
                            }}
                        />
                    </div>
                </div>
            </div>

            <SubstanceReportTable />
        </div>
    );
}
