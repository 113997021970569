import { CaretSortIcon } from '@radix-ui/react-icons';
import { ColumnDef } from '@tanstack/react-table';
import { Plus, Trash } from 'lucide-react';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'sonner';
import { useRoles } from 'src/app/_api_adb2c/workspace/roles/hooks/use-roles';
import { useUnassignMember } from 'src/app/_api_adb2c/workspace/roles/hooks/use-unassign-member';
import { UserModel } from 'src/app/_api_adb2c/workspace/users/user.model';
import { useWorkspaceMembers } from 'src/app/_api_adb2c/workspace/workspace/hooks/use-workspace-members';
import { TableV2 } from 'src/app/components-v2/table-v2';
import { Button } from 'src/components/ui/button';
import { Checkbox } from 'src/components/ui/checkbox';
import { CreateMember } from './create-member';
import { DeleteMember } from './delete-member';

export const WorkspaceUsers = () => {
    const [searchParams] = useSearchParams();
    const delegateId = searchParams.get('delegateId') || '';
    const { data, isLoading } = useWorkspaceMembers(delegateId);
    const { data: roles } = useRoles(delegateId);

    const getRole = (id: string) => {
        const role = roles?.find((role) => role.members.includes(id));

        return role;
    };

    const [isCreate, setIsCreate] = useState(false);
    const [isDelete, setIsDelete] = useState(false);
    const [user, setUser] = useState<UserModel>();
    const [rowSelection, setRowSelection] = useState({});

    const { mutateAsync: unassignMember } = useUnassignMember();

    const removeMember = async () => {
        const role = getRole(user?._id || '');

        if (!user || !role) {
            toast.error('Unable to remove user from role');
            return;
        }

        await unassignMember({
            id: role._id,
            delegateId: delegateId,
            body: {
                members: [
                    {
                        email: user.contact.email,
                    },
                ],
            },
        });

        reset();
    };

    const reset = () => {
        setRowSelection({});
        setIsCreate(false);
        setIsDelete(false);
        setUser(undefined);
    };

    const columns: ColumnDef<UserModel>[] = [
        {
            id: 'select',
            header: ({ table }) => (
                <Checkbox
                    checked={table.getIsAllPageRowsSelected()}
                    onCheckedChange={(value) =>
                        table.toggleAllPageRowsSelected(!!value)
                    }
                    aria-label='Select all'
                />
            ),
            cell: ({ row }) => (
                <Checkbox
                    checked={row.getIsSelected()}
                    onCheckedChange={(value) => row.toggleSelected(!!value)}
                    aria-label='Select row'
                />
            ),
            enableSorting: false,
            enableHiding: false,
            size: 0,
        },
        {
            id: 'email',
            accessorKey: 'email',
            accessorFn: (row) => row.contact.email,
            header: ({ column }) => {
                return (
                    <Button
                        variant='ghost'
                        className='text-xs'
                        onClick={() =>
                            column.toggleSorting(column.getIsSorted() === 'asc')
                        }
                    >
                        Email
                        <CaretSortIcon
                            className={`inline ${
                                column.getIsSorted() === 'asc'
                                    ? 'rotate-180 transform'
                                    : ''
                            }`}
                        />
                    </Button>
                );
            },
        },
        {
            id: 'title',
            accessorKey: 'title',
            accessorFn: (row) => getRole(row._id)?.name,
            header: ({ column }) => {
                return (
                    <Button
                        variant='ghost'
                        className='text-xs'
                        onClick={() =>
                            column.toggleSorting(column.getIsSorted() === 'asc')
                        }
                    >
                        Title
                        <CaretSortIcon
                            className={`inline ${
                                column.getIsSorted() === 'asc'
                                    ? 'rotate-180 transform'
                                    : ''
                            }`}
                        />
                    </Button>
                );
            },
        },
        {
            id: 'role',
            accessorKey: 'role',
            accessorFn: (row) => getRole(row._id)?.code,
            header: ({ column }) => {
                return (
                    <Button
                        variant='ghost'
                        className='text-xs'
                        onClick={() =>
                            column.toggleSorting(column.getIsSorted() === 'asc')
                        }
                    >
                        Role
                        <CaretSortIcon
                            className={`inline ${
                                column.getIsSorted() === 'asc'
                                    ? 'rotate-180 transform'
                                    : ''
                            }`}
                        />
                    </Button>
                );
            },
        },
        {
            id: 'actions',
            header: 'Actions',
            cell: ({ row }) => (
                <div>
                    <Button
                        size='sm'
                        variant='ghost'
                        onClick={() => {
                            setIsDelete(true);
                            setUser(row.original);
                        }}
                    >
                        <Trash className='h-4 w-4' />
                    </Button>
                </div>
            ),
        },
    ];

    return (
        <div className='flex h-full flex-col'>
            <TableV2
                columns={columns}
                data={data || []}
                label='Users'
                isLoading={isLoading}
                controls={{
                    enableDelegation: true,
                    enableSearch: true,
                }}
                rowSelection={rowSelection}
                setRowSelection={setRowSelection}
                actions={[
                    {
                        id: 'add',
                        icon: <Plus size={16} />,
                        onClick: () => setIsCreate(true),
                    },
                ]}
            />

            {isDelete && user && (
                <DeleteMember
                    open={isDelete}
                    onClose={() => setIsDelete(false)}
                    onSubmit={() => removeMember()}
                />
            )}

            {isCreate && (
                <CreateMember
                    open={isCreate}
                    onClose={() => setIsCreate(false)}
                />
            )}
        </div>
    );
};
