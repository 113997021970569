import axios from 'axios';
import saveAs from 'file-saver';
import { useAttachmentDownload } from 'src/app/_api_adb2c/attachment/hooks/use-attachment-download';

export function useDownload() {
    const { mutateAsync } = useAttachmentDownload();

    const download = async (id: string, name: string, autoDownload = true) => {
        const response = await mutateAsync({
            body: { id },
            diversion: '1',
        });

        if (autoDownload) {
            const blob = await axios.get(response.token, {
                responseType: 'blob',
            });

            saveAs(blob.data, name);
        }

        return response;
    };

    return { download };
}
