import { Trash } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import ReactFlow, {
    Background,
    ControlButton,
    Controls,
    useEdgesState,
    useNodesState,
} from 'reactflow';
import { usePurchase } from 'src/app/_api_adb2c/purchase/purchase/hooks/use-purchase';
import { usePurchaseCottonWeight } from 'src/app/_api_adb2c/purchase/purchase/hooks/use-purchase-cotton-weight';
import { usePurchaseTrace } from 'src/app/_api_adb2c/purchase/purchase/hooks/use-purchase-trace';
import { TooltipWrapper } from 'src/app/components-v2/tooltip';
import { useContextStore } from 'src/app/stores/context-store';
import { Label } from 'src/components/ui/label';
import { Switch } from 'src/components/ui/switch';
import { PurchaseCascadeDownloader } from '../Cascade/purchase-cascade-downloader';
import { useEdgeBuilder } from './hooks/useEdgeBuilder';
import { useNodeBuilder } from './hooks/useNodeBuilder';
import { PurchaseClearCascade } from './modals/purchase-clear-cascade';
import { Legend } from './purchase-cascade-legend';
import { nodeTypes } from './types';

interface Props {
    purchaseId: string;
}

export function PurchaseCascadeV2({ purchaseId }: Props) {
    const [searchParams] = useSearchParams();
    const delegateId = searchParams.get('delegateId') || '';
    const context = useContextStore();
    const isApparelMode =
        (context.workspace?.features.supplyChainLoaderType || 0) === 0;

    // State
    const [supplierMode, setSupplierMode] = useState(false);
    const [isDelete, setIsDelete] = useState(false);
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);

    // Data fetching
    const { data: purchase } = usePurchase(purchaseId, delegateId);
    const { data: traces } = usePurchaseTrace(purchaseId, delegateId);
    const { data: purchaseCottonWeight } = usePurchaseCottonWeight(
        purchaseId,
        delegateId
    );

    // Custom hooks for building nodes and edges
    const { buildNodes } = useNodeBuilder({
        isApparelMode,
        supplierMode,
        purchaseCottonWeight,
    });
    const { buildEdges } = useEdgeBuilder();

    // Effects for updating nodes and edges
    useEffect(() => {
        if (purchase && traces) {
            const newNodes = buildNodes(purchase, traces);
            setNodes(newNodes);
        }
    }, [purchase, traces, buildNodes, setNodes]);

    useEffect(() => {
        if (purchase && traces) {
            const newEdges = buildEdges(purchase, traces);
            setEdges(newEdges);
        }
    }, [purchase, traces, buildEdges, setEdges]);

    return (
        <div className='flex h-full flex-col gap-4'>
            {/* Header */}
            <div className='flex items-center justify-between text-xs'>
                <span className='font-bold'>Purchase Order Tracing</span>
                <div className='flex items-center space-x-2'>
                    <Label className='text-xs'>Order</Label>
                    <Switch
                        checked={supplierMode}
                        onCheckedChange={setSupplierMode}
                    />
                    <Label className='text-xs'>Supplier</Label>
                </div>
            </div>

            {/* Flow Chart */}
            <div className='relative flex-1 rounded-lg border border-gray-200 bg-white'>
                <ReactFlow
                    proOptions={{ hideAttribution: true }}
                    nodes={nodes}
                    edges={edges}
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChange}
                    nodeTypes={nodeTypes}
                    defaultViewport={{ zoom: 0.8, x: 0, y: 0 }}
                    minZoom={0.2}
                    maxZoom={1.5}
                    className='bg-gray-50'
                >
                    <Background />
                    <Controls position='bottom-right' showInteractive={false}>
                        <ControlButton onClick={() => setIsDelete(true)}>
                            <TooltipWrapper
                                content={<Trash />}
                                tooltipContent='Delete Cascade'
                            ></TooltipWrapper>
                        </ControlButton>
                        <PurchaseCascadeDownloader />
                    </Controls>
                </ReactFlow>

                <Legend />
            </div>

            {/* Delete Modal */}
            {isDelete && (
                <PurchaseClearCascade
                    open={isDelete}
                    onClose={() => setIsDelete(false)}
                    purchase={purchase?._id || ''}
                    deleteParent={false}
                />
            )}
        </div>
    );
}
