import { zodResolver } from '@hookform/resolvers/zod';
import axios from 'axios';
import { LoaderCircle, Plus, SaveAll } from 'lucide-react';
import { useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'sonner';
import { FileCreateDataObject } from 'src/app/_api_adb2c/attachment/file-create-data-object';
import { useAttachmentUpload } from 'src/app/_api_adb2c/attachment/hooks/use-attachment-upload';
import { useMaterial } from 'src/app/_api_adb2c/product/material/hooks/use-material';
import { useUpdateMaterial } from 'src/app/_api_adb2c/product/material/hooks/use-update-material';
import {
    MaterialComposition,
    ProductMaterialDataObject,
} from 'src/app/_api_adb2c/product/material/request/product-material-data-object';
import { DialogV2 } from 'src/app/components-v2/dialog-v2';
import { TreeInputV2 } from 'src/app/components/Form/TreeInputV2';
import { AddSupplier } from 'src/app/pages/Suppliers/add-supplier';
import { useContextStore } from 'src/app/stores/context-store';
import { Button } from 'src/components/ui/button';
import CompoundJson from 'src/infrastructure/config/data/compound.json';
import FibreJson from 'src/infrastructure/config/data/fibre.json';
import { getFileIdFromAssetName } from 'src/infrastructure/utils/extract-asset-id';
import { z } from 'zod';
import { AboutManualSubstance } from './about-manual-substance';
import { AboutSubstance } from './about-substance';
import { AddManualSubstance } from './add-manual-substance';
import { AddSubstanceConfirmation } from './add-substance-confirmation';
import { AddSubstanceDocumentation } from './add-substance-documentation';
import { AddSubstanceSource } from './add-substance-source';

interface Props {
    open: boolean;
    materialId: string;
    onClose: () => void;
}

export const substanceItemSchema = z.object({
    name: z.string(),
    cas: z.string(),
    percentage: z.coerce.number(),
    projectedWeight: z.coerce.number(),
    isManMade: z.boolean(),
    isManual: z.boolean(),
    composition: z.array(z.any()).optional(),
    substance: z
        .array(
            z.object({
                label: z.string(),
                value: z.string(),
            })
        )
        .optional(),
    supplier: z.string().optional(),
    sdsFileId: z.string().optional(),
    // File[] | null
    sdsFile: z.array(z.instanceof(File)).nullable().optional(),
    // sdsFile: z.any().optional(),
});

export const schema = z.object({
    _id: z.string().optional(),
    substanceType: z.array(
        z.object({
            label: z.string(),
            value: z.string(),
        })
    ),
    cas: z.string(),
    isManual: z.boolean(),
    percentage: z.coerce.number(),
    projectedWeight: z.coerce.number(),
    isMixedSubstance: z.boolean().optional(),
    isManMade: z.boolean().optional(),
    items: z.array(substanceItemSchema),
    name: z.string().optional(),
    // Source
    supplier: z.string(),
    reachRegistrationNumber: z.string().optional(),
    // Documentation
    files: z.instanceof(File).optional(),
});

export const treeOptions = [
    {
        label: 'Fibre',
        value: 'fibre',
        children: FibreJson.map((x) => ({
            label: x.name,
            value: x.value,
        })),
    },
    {
        label: 'Chemical',
        value: 'chemical',
        children: CompoundJson.map((x) => ({
            label: x.name,
            value: x.cas,
        })),
    },
    {
        label: 'Manual',
        value: 'manual',
        children: [
            {
                label: 'Manual Input',
                value: 'manualInput',
            },
        ],
    },
];

export function AddSubstanceV3({ open, materialId, onClose }: Props) {
    const context = useContextStore();
    const [searchParams] = useSearchParams();
    const delegateId = searchParams.get('delegateId') || '';

    const [addManualSubstance, setAddManualSubstance] = useState(false);
    const [currentStep, setCurrentStep] = useState(1);
    const [isSaveDraft, setIsSaveDraft] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const { data: material } = useMaterial(materialId);
    const { mutateAsync: update } = useUpdateMaterial();
    const { mutateAsync: upload } = useAttachmentUpload();

    const form = useForm<z.infer<typeof schema>>({
        resolver: zodResolver(schema),
        mode: 'onChange',
    });

    const substanceType = form.watch('substanceType');
    const isManual = form.watch('isManual');
    const isMixedSubstance = form.watch('isMixedSubstance');
    const projectedWeight = form.watch('projectedWeight');

    const isAddMode = form.watch('supplier') === 'new';

    const { fields, append, remove, replace } = useFieldArray({
        control: form.control,
        name: 'items',
    });

    const appendSubstance = (data: z.infer<typeof substanceItemSchema>) => {
        append(data);
    };

    const substance = useMemo(() => {
        if (substanceType && substanceType.length > 0) {
            const parentType = substanceType[0].value;
            if (parentType === 'manual') return;

            const selectedType = substanceType[substanceType.length - 1];

            return selectedType;
        }
    }, [substanceType]);

    const generateComposition = async (
        composition: z.infer<typeof schema>,
        isDraft?: boolean,
        fileId?: string
    ): Promise<MaterialComposition> => {
        const isManual = composition.substanceType?.some(
            (x) => x.value === 'manualInput'
        );

        const processSubCompositions = async (items: any[]): Promise<any[]> => {
            return Promise.all(
                items.map(async (x) => {
                    let itemSdsFileId = '';
                    if (x.sdsFile && x.sdsFile?.length > 0) {
                        const file = x.sdsFile[0];
                        const fileRequest: FileCreateDataObject = {
                            contentType: file.type,
                            name: file.name,
                            genre: 'substance',
                            size: file.size,
                            uploadedBy: context.user?._id || '',
                            workspace:
                                delegateId || context.workspace?._id || '',
                        };

                        const fileResp = await upload({
                            file: fileRequest,
                            assetType: 'material',
                            delegateId: delegateId,
                        });

                        await axios.put(fileResp.token, file, {
                            headers: {
                                'Content-Type': file.type,
                                'x-ms-blob-type': 'BlockBlob',
                            },
                        });

                        itemSdsFileId = getFileIdFromAssetName(
                            fileResp.attachment.assetName
                        );
                    }

                    return {
                        name: x.name,
                        substanceCode: x.cas,
                        isManmade: x.isManMade,
                        weight: x.projectedWeight || 0,
                        actualWeight: x.projectedWeight || 0,
                        subCompositions: x.composition
                            ? await processSubCompositions(x.composition)
                            : [],
                        supplier: x.supplier,
                        sdsFileId: itemSdsFileId,
                        substanceRegCode: x.reachRegistrationNumber,
                    };
                })
            );
        };

        const subCompositions = await processSubCompositions(composition.items);

        return {
            name: isManual ? composition.name || '' : substance?.label || '',
            supplier: composition.supplier,
            substanceCode: composition.cas,
            substanceName: isManual
                ? composition.name || ''
                : substance?.label || '',
            isManMade: composition.isManMade,
            substanceRegCode: composition.reachRegistrationNumber,
            sdsFileId: fileId || '',
            subCompositions: composition.isMixedSubstance
                ? subCompositions
                : [],
            weight: composition.projectedWeight || 0,
            actualWeight: composition.projectedWeight || 0,
            stepInDraft: isDraft ? currentStep : undefined,
            isDraft: isDraft || false,
        };
    };

    const saveDraft = async (data: z.infer<typeof schema>) => {
        if (!material) return;
        // setIsSaveDraft(true);

        const request: ProductMaterialDataObject = {
            ...material,
        };

        if (material.compositions) {
            const compositions = material.compositions;
            compositions.push(await generateComposition(data, true));

            request.compositions = compositions;
        }

        await update({
            delegateId: delegateId,
            id: materialId,
            body: request,
        });

        reset();
    };

    const onSubmit = async (data: z.infer<typeof schema>) => {
        if (!material) return;

        setLoading(true);

        const maxWeight = material.specifications?.weight || 0;

        if (data.projectedWeight > maxWeight) {
            toast.error(
                'The total weight of the compositions exceeds the maximum weight'
            );
            return;
        }

        const request: ProductMaterialDataObject = {
            ...material,
        };

        let sdsFileId: string = '';

        if (data.files) {
            const fileRequest: FileCreateDataObject = {
                contentType: data.files.type,
                name: data.files.name,
                genre: 'temporary',
                size: data.files.size,
                uploadedBy: context.user?._id || '',
                workspace: delegateId || context.workspace?._id || '',
            };

            const fileResp = await upload({
                file: fileRequest,
                assetType: 'material',
                delegateId: delegateId,
            });

            await axios.put(fileResp.token, data.files, {
                headers: {
                    'Content-Type': data.files.type,
                    'x-ms-blob-type': 'BlockBlob',
                },
            });

            sdsFileId = getFileIdFromAssetName(fileResp.attachment.assetName);
        }

        if (material.compositions) {
            const compositions = material.compositions;
            compositions.push(
                await generateComposition(form.getValues(), false, sdsFileId)
            );

            request.compositions = compositions;
        }

        await update({
            delegateId: delegateId,
            id: materialId,
            body: request,
        });

        reset();
    };

    const reset = () => {
        form.reset();
        setAddManualSubstance(false);
        form.setValue('supplier', '');
        setIsSaveDraft(false);
        onClose();
        setLoading(false);
    };

    return (
        <>
            <DialogV2
                open={open}
                onClose={onClose}
                title='Add Substance'
                size='xl'
                isStepDialog
                formName='add-substance-form'
                form={form}
                onSubmit={onSubmit}
                isLoading={isLoading}
                currentStep={currentStep}
                onCurrentStepChange={setCurrentStep}
                extraControls={
                    <Button
                        type='button'
                        size='sm'
                        variant='outline'
                        className='flex min-w-[100px] gap-2'
                        onClick={() => saveDraft(form.getValues())}
                        loading={isSaveDraft}
                    >
                        {isSaveDraft ? (
                            <LoaderCircle size={16} className='animate-spin' />
                        ) : (
                            <SaveAll
                                size={16}
                                className='cursor-pointer hover:scale-125'
                            />
                        )}
                        Save Draft
                    </Button>
                }
                steps={[
                    {
                        title: 'About Your Substance',
                        description: 'Provide information about the substance',
                        content: (
                            <div className='grid grid-cols-2 gap-2'>
                                {
                                    <TreeInputV2
                                        name='substanceType'
                                        label='Substance Type'
                                        options={treeOptions}
                                        onChange={(value) => {
                                            if (value.value === 'manualInput') {
                                                form.setValue('isManual', true);
                                            } else {
                                                form.setValue(
                                                    'cas',
                                                    value.value
                                                );
                                                form.setValue(
                                                    'isManual',
                                                    false
                                                );
                                            }
                                        }}
                                    />
                                }

                                {isManual && substanceType && (
                                    <AboutManualSubstance
                                        material={material}
                                        items={fields}
                                        remove={remove}
                                        replace={replace}
                                    />
                                )}

                                {!isManual && substanceType && (
                                    <AboutSubstance material={material} />
                                )}
                            </div>
                        ),
                        pageControls: (
                            <>
                                {isManual && isMixedSubstance && (
                                    <Button
                                        variant='outline'
                                        type='button'
                                        onClick={() =>
                                            setAddManualSubstance(true)
                                        }
                                        size='sm'
                                        className='flex min-w-[100px] gap-2'
                                    >
                                        {isSaveDraft ? (
                                            <LoaderCircle
                                                size={16}
                                                className='animate-spin'
                                            />
                                        ) : (
                                            <Plus size={16} />
                                        )}
                                        Add Substance
                                    </Button>
                                )}
                            </>
                        ),
                    },
                    {
                        title: 'Identify Your Source',
                        description: 'Identify the source of the substance',
                        content: <AddSubstanceSource />,
                    },
                    {
                        title: 'Include Documentation',
                        description:
                            'Include the documentation for the substance',
                        content: <AddSubstanceDocumentation name='files' />,
                    },
                    {
                        title: 'Verify and Confirm',
                        description: 'Verify and confirm the substance',
                        content: <AddSubstanceConfirmation />,
                    },
                ]}
            ></DialogV2>

            <AddManualSubstance
                open={addManualSubstance}
                onClose={() => setAddManualSubstance(false)}
                projectedWeight={projectedWeight}
                append={appendSubstance}
            />

            {isAddMode && (
                <AddSupplier
                    open={isAddMode}
                    onClose={() => form.setValue('supplier', '')}
                />
            )}
        </>
    );
}
