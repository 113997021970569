import { useQuery } from '@tanstack/react-query';
import { toast } from 'sonner';
import { useContextStore } from 'src/app/stores/context-store';
import { PurchaseOrderTraceModel } from '../models/purchase.model';
import { PURCHASE_TRACE_QUERY_KEY } from './purchase-query-keys';
import { usePurchaseService } from './use-purchase-service';
import { useSalesOrderService } from 'src/app/_api_adb2c/sales/sales/hooks/use-sales-order-service';
import { SalesOrderModel } from 'src/app/_api_adb2c/sales/sales/models/sales-order.model';

interface PurchaseOrderTraceModelWithSalesOrders
    extends PurchaseOrderTraceModel {
    salesOrder: SalesOrderModel;
}

export function usePurchaseTrace(
    id: string,
    delegateId?: string,
    diversion?: string,
    withSalesOrders?: boolean
) {
    const { service } = usePurchaseService();
    const { service: salesOrderService } = useSalesOrderService();
    const context = useContextStore();

    return useQuery<PurchaseOrderTraceModel[]>({
        queryKey: PURCHASE_TRACE_QUERY_KEY(
            delegateId || context.workspace?._id || '',
            id
        ),
        queryFn: async () => {
            const responses: PurchaseOrderTraceModelWithSalesOrders[] =
                await service.trace(
                    delegateId || context.workspace?._id || '',
                    id,
                    diversion
                );

            if (withSalesOrders) {
                const purchaseIds = responses.map((r) => r._id);

                const salesOrders = await salesOrderService.batch(
                    delegateId || context.workspace?._id || '',
                    { targets: purchaseIds },
                    '1'
                );

                responses.forEach((r, index) => {
                    r.salesOrder = salesOrders[index];
                });
            }

            return responses;
        },
        onError: (err) => {
            const error = err as Error;

            toast.error(`Failed to load purchase trace: ${error.message}`);
        },
        enabled: !!id,
    });
}
