import { ColumnDef } from '@tanstack/react-table';
import { Plus, Trash } from 'lucide-react';
import { useCallback, useEffect, useMemo } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useProducts } from 'src/app/_api_adb2c/product/product/hooks/use-products';
import { usePurchaseItems } from 'src/app/_api_adb2c/purchase/purchase-item/hooks/use-purchase-items';
import {
    ProductUom,
    ProductUomLabels,
} from 'src/app/_api_adb2c/purchase/purchase/enums/product-uom.enum';
import { StepComponentProps } from 'src/app/components-v2/dialog-v2';
import { TableV2 } from 'src/app/components-v2/table-v2';
import { SelectInputV2 } from 'src/app/components/Form/SelectInputV2';
import { TextInputV2 } from 'src/app/components/Form/TextInputV2';
import { Button } from 'src/components/ui/button';
import { z } from 'zod';
import { formSchema, purchaseOrderItemDataObjectSchema } from './add-purchase';
import { useSearchParams } from 'react-router-dom';

interface Props extends Partial<StepComponentProps> {}

export const AddPurchaseItemFields = ({ setFooterControls }: Props) => {
    const name = 'items';
    const [searchParams] = useSearchParams();

    const form = useFormContext<z.infer<typeof formSchema>>();
    const delegateId = form.watch('delegateId');
    const paramsDelegateId = searchParams.get('delegateId') || '';

    const { data } = usePurchaseItems(delegateId || paramsDelegateId);
    const { data: products } = useProducts(delegateId || paramsDelegateId);

    const { fields, append, replace } = useFieldArray({
        control: form.control,
        name: 'items',
    });

    const addedItems = useMemo(() => {
        return fields.filter((x) => x.product);
    }, [fields]);

    const handleAppendItem = useCallback(() => {
        append({
            ppu: 0,
            quantity: 0,
            product: '',
        });
    }, [append]);

    useEffect(() => {
        if (!setFooterControls) return;

        setFooterControls(
            <Button
                size='sm'
                variant='outline'
                type='button'
                onClick={handleAppendItem}
            >
                <Plus size={16} />
            </Button>
        );
    }, [setFooterControls, handleAppendItem]);

    const columns: ColumnDef<
        z.infer<typeof purchaseOrderItemDataObjectSchema>
    >[] = useMemo(
        () => [
            {
                id: 'material',
                header: 'Material',
                accessor: 'material',
                cell: ({ row }) => {
                    return (
                        <SelectInputV2
                            name={`${name}.${row.index}.product`}
                            options={(data?.data || []).map((x) => {
                                const product = products?.data?.find(
                                    (p) => p._id === x._id
                                );

                                return {
                                    label: product?.name || x._id,
                                    value: x._id,
                                    disabled: addedItems.some(
                                        (y) => y.product === x._id
                                    ),
                                };
                            })}
                        />
                    );
                },
            },
            {
                id: 'unit',
                header: 'Unit',
                accessor: 'unit',
                cell: ({ row }) => {
                    const productId = form.watch(
                        `${name}.${row.index}.product`
                    );
                    const product = products?.data?.find(
                        (p) => p._id === productId
                    );

                    if (product && product.unitOfMeasurement) {
                        form.setValue(
                            `${name}.${row.index}.unit`,
                            product?.unitOfMeasurement
                        );
                    }

                    return (
                        <SelectInputV2
                            name={`${name}.${row.index}.unit`}
                            readOnly
                            options={Object.values(ProductUom).map((x) => ({
                                label: ProductUomLabels[x],
                                value: x,
                            }))}
                        />
                    );
                },
            },
            {
                id: 'ppu',
                header: 'PPU',
                accessor: 'ppu',
                cell: ({ row }) => {
                    return (
                        <TextInputV2
                            name={`${name}.${row.index}.ppu`}
                            type='number'
                        />
                    );
                },
            },
            {
                id: 'quantity',
                header: 'Quantity',
                accessor: 'quantity',
                cell: ({ row }) => {
                    return (
                        <TextInputV2
                            name={`${name}.${row.index}.quantity`}
                            type='number'
                        />
                    );
                },
            },
            {
                id: 'actions',
                header: 'Actions',
                cell: ({ row }) => {
                    return (
                        <Button
                            variant='outline'
                            className='text-red-800'
                            type='button'
                            onClick={() => {
                                const newItems = fields.filter(
                                    (_, i) => i !== row.index
                                );

                                replace(newItems);
                            }}
                        >
                            <Trash className='h-4 w-4' />
                        </Button>
                    );
                },
            },
        ],
        [data?.data, products, form, addedItems, replace, fields]
    );

    return (
        <TableV2
            columns={columns}
            data={fields}
            fixedHeight='500px'
            disableTooltips
            tableClassName='table-fixed'
        />
    );
};
