import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';
import { useContextStore } from 'src/app/stores/context-store';
import { PurchaseOrderDataLinkDataObject } from '../requests/purchase-order-link-data-object';
import { LINKS_QUERY_KEY } from './links-query-keys';
import { useLinkService } from './use-link-service';
import { SALES_ORDERS_QUERY_KEY } from 'src/app/_api_adb2c/sales/sales/hooks/sales-order-query-keys';
import { BASE_PURCHASE_TRACE_QUERY_KEY } from '../../purchase/hooks/purchase-query-keys';
import { BASE_PURCHASE_ITEM_QUERY_KEY } from '../../purchase-item/hooks/purchase-item-query-keys';

export function useCreateLink() {
    const client = useQueryClient();
    const context = useContextStore();
    const { service } = useLinkService();

    const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

    return useMutation({
        mutationFn: async ({
            order,
            body,
            delegateId,
        }: {
            delegateId?: string;
            order: string;
            body: PurchaseOrderDataLinkDataObject;
        }) => {
            return await service.link(
                delegateId || context.workspace?._id || '',
                order,
                body
            );
        },
        onSuccess: async (_, { delegateId }) => {
            await delay(1000);
            client.invalidateQueries(
                LINKS_QUERY_KEY(delegateId || context.workspace?._id || '')
            );

            client.invalidateQueries(
                SALES_ORDERS_QUERY_KEY(
                    delegateId || context.workspace?._id || ''
                )
            );

            client.invalidateQueries(BASE_PURCHASE_TRACE_QUERY_KEY);

            client.invalidateQueries({
                queryKey: [BASE_PURCHASE_ITEM_QUERY_KEY],
            });

            toast.success('Successfully created link');
        },
        onError: (err) => {
            const error = err as Error;

            toast.error(`Failed to create link: ${error.message}`);
        },
    });
}
