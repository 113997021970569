import { useEdgesState, useNodesState } from '@reactflow/core';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import ReactFlow, { Background, Controls } from 'reactflow';
import { usePurchase } from 'src/app/_api_adb2c/purchase/purchase/hooks/use-purchase';
import { usePurchaseCottonWeight } from 'src/app/_api_adb2c/purchase/purchase/hooks/use-purchase-cotton-weight';
import { usePurchaseTrace } from 'src/app/_api_adb2c/purchase/purchase/hooks/use-purchase-trace';
import { useSalesOrdersV2 } from 'src/app/_api_adb2c/sales/sales/hooks/use-sales-orders-v2';
import { DialogV2 } from 'src/app/components-v2/dialog-v2';
import { PurchaseCascadeDownloader } from '../../Purchases/DetailsV2/Cascade/purchase-cascade-downloader';
import { useEdgeBuilder } from '../../Purchases/DetailsV2/CascadeV2/hooks/useEdgeBuilder';
import { useNodeBuilder } from '../../Purchases/DetailsV2/CascadeV2/hooks/useNodeBuilder';
import { nodeTypes } from '../../Purchases/DetailsV2/CascadeV2/types';

interface CascadePreviewProps {
    isOpen: boolean;
    onClose: () => void;
}

export function CascadePreview({ isOpen, onClose }: CascadePreviewProps) {
    const [searchParams] = useSearchParams();
    const delegateId = searchParams.get('delegateId') || '';
    const orderId = searchParams.get('order') || '';

    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);

    const { data: orders } = useSalesOrdersV2(delegateId);

    const order = orders?.find((o) => o._id === orderId);

    const { data: purchase } = usePurchase(
        order?.purchase?._id || '',
        order?.purchase?.workspace || '',
        '1'
    );

    const { data: traces } = usePurchaseTrace(
        order?.purchase?._id || '',
        order?.purchase?.workspace || '',
        '1'
    );

    const { data: purchaseCottonWeight } = usePurchaseCottonWeight(
        order?.purchase?._id || '',
        order?.purchase?.workspace || '',
        '1'
    );

    const { buildNodes } = useNodeBuilder({
        isApparelMode: true,
        supplierMode: false,
        purchaseCottonWeight: purchaseCottonWeight || 0,
    });
    const { buildEdges } = useEdgeBuilder();

    useEffect(() => {
        if (purchase && traces) {
            const newNodes = buildNodes(purchase, traces);

            setNodes(newNodes);
        }
    }, [purchase, traces, buildNodes, setNodes]);

    useEffect(() => {
        if (purchase && traces) {
            const newEdges = buildEdges(purchase, traces);

            setEdges(newEdges);
        }
    }, [purchase, traces, buildEdges, setEdges]);

    return (
        <DialogV2
            open={isOpen}
            onClose={onClose}
            title='Cascade Preview'
            size='lg'
            singleStepContent={
                <div className='flex h-full flex-col gap-4'>
                    <ReactFlow
                        proOptions={{ hideAttribution: true }}
                        nodes={nodes}
                        edges={edges}
                        onNodesChange={onNodesChange}
                        onEdgesChange={onEdgesChange}
                        nodeTypes={nodeTypes}
                        defaultViewport={{ zoom: 0.8, x: 0, y: 0 }}
                        minZoom={0.2}
                        maxZoom={1.5}
                        className='bg-gray-50'
                    >
                        <Background />
                        <Controls
                            position='bottom-right'
                            showInteractive={false}
                        >
                            <PurchaseCascadeDownloader />
                        </Controls>
                    </ReactFlow>
                </div>
            }
        />
    );
}
