import { useQueryClient } from '@tanstack/react-query';
import { memo, useCallback, useEffect, useState } from 'react';
import { useRemoveLink } from 'src/app/_api_adb2c/purchase/link/hooks/use-remove-link';
import { BASE_PURCHASE_TRACE_QUERY_KEY } from 'src/app/_api_adb2c/purchase/purchase/hooks/purchase-query-keys';
import { usePurchaseService } from 'src/app/_api_adb2c/purchase/purchase/hooks/use-purchase-service';
import { PurchaseModel } from 'src/app/_api_adb2c/purchase/purchase/models/purchase.model';
import { useSalesOrderService } from 'src/app/_api_adb2c/sales/sales/hooks/use-sales-order-service';
import { DialogV2 } from 'src/app/components-v2/dialog-v2';
import { generateOrderTitle } from 'src/app/utils/generate-order-title';
import { Skeleton } from 'src/components/ui/skeleton';

interface Props {
    open: boolean;
    onClose: () => void;
    purchase: PurchaseModel;
    parentPurchaseId: string;
}

export const UnlinkConfirmationModal = memo(
    ({ open, onClose, purchase, parentPurchaseId }: Props) => {
        const [isLoading, setIsLoading] = useState(false);
        const [isLoadingParent, setIsLoadingParent] = useState(true);
        const [parentPurchase, setParentPurchase] =
            useState<PurchaseModel | null>(null);

        const queryClient = useQueryClient();
        const { service: purchaseService } = usePurchaseService();
        const { service: salesService } = useSalesOrderService();
        const { mutateAsync: unlink } = useRemoveLink();

        const fetchParentPurchase = useCallback(async () => {
            if (!open) return;

            try {
                setIsLoadingParent(true);
                const parentSales = await salesService.getByPurchaseOrder(
                    purchase.supplier.workspace as unknown as string,
                    parentPurchaseId
                );

                const parent = await purchaseService.get(
                    parentSales.customer.buyer._id,
                    parentSales.purchaseOrderId
                );

                setParentPurchase(parent);
            } catch (error) {
                console.error('Error fetching parent purchase:', error);
            } finally {
                setIsLoadingParent(false);
            }
        }, [
            open,
            purchase.supplier.workspace,
            parentPurchaseId,
            purchaseService,
            salesService,
        ]);

        const handleUnlink = useCallback(async () => {
            setIsLoading(true);
            try {
                await Promise.all(
                    (purchase.parent || [])
                        .filter((x) => x)
                        .map(async (parent) => {
                            await unlink({
                                delegateId: purchase.supplier
                                    .workspace as unknown as string,
                                purchaseId: purchase._id,
                                orderId: parent,
                            });
                        })
                );

                queryClient.invalidateQueries({
                    queryKey: [BASE_PURCHASE_TRACE_QUERY_KEY],
                });

                onClose();
            } catch (error) {
                console.error('Error unlinking purchase:', error);
            } finally {
                setIsLoading(false);
            }
        }, [purchase, unlink, queryClient, onClose]);

        useEffect(() => {
            fetchParentPurchase();
        }, [fetchParentPurchase]);

        const renderContent = () => {
            if (isLoadingParent) {
                return (
                    <div className='flex flex-col items-center justify-center gap-4 p-4'>
                        <Skeleton className='h-4 w-full' />
                    </div>
                );
            }

            return (
                <div className='flex flex-col gap-4'>
                    <div className='text-sm text-gray-500'>
                        Are you sure you want to unlink this purchase order from{' '}
                        <span className='font-semibold'>
                            {parentPurchase
                                ? generateOrderTitle(parentPurchase)
                                : ''}
                        </span>
                        ? This action cannot be undone.
                    </div>
                </div>
            );
        };

        return (
            <DialogV2
                open={open}
                onClose={onClose}
                onFinish={handleUnlink}
                title='Unlink Purchase Order'
                size='sm'
                heightClass='h-[200px]'
                isLoading={isLoading || isLoadingParent}
                singleStepContent={renderContent()}
            />
        );
    }
);
