import { memo } from 'react';

interface Props {
    data: {
        label: string;
        height: number;
    };
}

export const PurchaseCascadeApparelGroupNode = memo(({ data }: Props) => {
    return (
        <div
            style={{ height: data.height }}
            className='flex min-w-[260px] flex-col rounded-md border-2 border-gray-300 bg-gray-50'
        >
            <div className='w-full border-b-2 border-gray-300 bg-red-800 px-3 py-1.5'>
                <span className='text-sm font-semibold text-white'>
                    {data.label}
                </span>
            </div>
        </div>
    );
});

PurchaseCascadeApparelGroupNode.displayName = 'PurchaseCascadeApparelGroupNode';
