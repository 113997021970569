import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useSupplierInvite } from 'src/app/_api_adb2c/workspace/account/hooks/use-supplier-invite';
import { DialogV2 } from 'src/app/components-v2/dialog-v2';
import { DelegationInput } from 'src/app/components/Form/DelegationInput';
import { z } from 'zod';
import { SupplierContactsFields } from './supplier-contact-fields';
import { SupplierRequiredFields } from './supplier-required-fields';

interface Props {
    open: boolean;
    onClose: () => void;
    predefinedDelegateId?: string;
}

const formSchema = z.object({
    delegateId: z.string().optional(),
    firstName: z.string(),
    lastName: z.string(),
    email: z.string().email(),
    registrationNumber: z.string(),
    country: z.string(),
    companyName: z.string(),
    assignDelegate: z.boolean().default(false),
});

export const AddSupplier = ({ open, onClose, predefinedDelegateId }: Props) => {
    const form = useForm<z.infer<typeof formSchema>>({
        mode: 'onChange',
        resolver: zodResolver(formSchema),
        defaultValues: {
            delegateId: predefinedDelegateId,
        },
    });

    const { mutateAsync: invite, isLoading: inviteLoading } =
        useSupplierInvite();

    const onSubmit = async (
        data: z.infer<typeof formSchema>,
        e?: React.BaseSyntheticEvent
    ) => {
        e?.preventDefault();
        e?.stopPropagation();

        try {
            await invite({
                delegateId: data.delegateId,
                body: {
                    country: data.country!,
                    company: data.companyName!,
                    registrationNumber: data.registrationNumber!,
                    contact: [
                        {
                            contact: {
                                email: data.email!,
                                firstName: data.firstName!,
                                lastName: data.lastName!,
                                device: {},
                            },
                        },
                    ],
                    disableDelegate: false,
                },
            });

            reset();
        } catch (error) {
            console.error('Error creating supplier:', error);
        }
    };

    const reset = () => {
        onClose();
    };

    return (
        <DialogV2
            title='Add Supplier'
            open={open}
            onClose={reset}
            form={form}
            formName='add-supplier-form'
            onSubmit={onSubmit}
            isLoading={inviteLoading}
            isStepDialog
            steps={[
                {
                    title: 'On Behalf Of.',
                    description: 'Select the delegate for this supplier.',
                    content: (
                        <DelegationInput
                            name='delegateId'
                            label='On Behalf Of.'
                            readOnly={!!predefinedDelegateId}
                        />
                    ),
                },
                {
                    title: 'Supplier Information',
                    description:
                        'Enter the basic information for this supplier.',
                    content: <SupplierRequiredFields />,
                },
                {
                    title: 'Supplier Contacts',
                    description: 'Add the contacts for this supplier.',
                    content: <SupplierContactsFields />,
                },
            ]}
        />
    );
};
