import { zodResolver } from '@hookform/resolvers/zod';
import axios from 'axios';
import { useFieldArray, useForm } from 'react-hook-form';
import { AttachmentModel } from 'src/app/_api_adb2c/attachment/attachment.model';
import { FileCreateDataObject } from 'src/app/_api_adb2c/attachment/file-create-data-object';
import { useAttachmentUpload } from 'src/app/_api_adb2c/attachment/hooks/use-attachment-upload';
import { useCreateMaterial } from 'src/app/_api_adb2c/product/material/hooks/use-create-material';
import { IFile } from 'src/app/_api_adb2c/product/product/models/product.model';
import {
    ProductUom,
    ProductUomLabels,
} from 'src/app/_api_adb2c/purchase/purchase/enums/product-uom.enum';
import { DialogV2 } from 'src/app/components-v2/dialog-v2';
import { DelegationInput } from 'src/app/components/Form/DelegationInput';
import { SelectInputV2 } from 'src/app/components/Form/SelectInputV2';
import { TextInputV2 } from 'src/app/components/Form/TextInputV2';
import { PhotoGrid } from 'src/app/components/PhotoGrid';
import { useContextStore } from 'src/app/stores/context-store';
import { getFileIdFromAssetName } from 'src/infrastructure/utils/extract-asset-id';
import { z } from 'zod';
import { AddMaterialCertificates } from './Details/add-material-certificates';

interface Props {
    open: boolean;
    onClose: () => void;
}
export interface MaterialDocument extends IFile {
    certificateNumber?: string;
    certificateType?: string;
    weight?: number;
    attachment: AttachmentModel;
}

const formSchema = z.object({
    delegateId: z.string().optional(),
    comment: z.string().optional(),
    description: z.string().optional(),
    name: z.string(),
    unitOfMeasurement: z.string(),
    unitCost: z.coerce.number(),
    unitCostCurrency: z.string(),
    pictures: z.instanceof(FileList).optional(),
    width: z.coerce.number(),
    height: z.coerce.number(),
    length: z.coerce.number(),
    weight: z.coerce.number().optional(),
    certificates: z
        .array(
            z.object({
                certificationType: z.string(),
                certificateNumber: z.string(),
                expiryDate: z.date(),
                certificateFile: z.array(z.instanceof(File)),
                weight: z.coerce.number().optional(),
            })
        )
        .optional(),
    photos: z
        .array(
            z.object({
                id: z.string(),
                url: z.string(),
                file: z.any().optional(),
                contentType: z.string().optional(),
                size: z.number().optional(),
                name: z.string().optional(),
            })
        )
        .default([]),
});

export function AddMaterialV2({ open, onClose }: Props) {
    const context = useContextStore();
    const currencies = ['USD', 'EUR', 'HKD', 'RMB', 'SGB'];

    const { mutateAsync: create, isLoading: isCreating } = useCreateMaterial();
    const { mutateAsync: upload, isLoading: isUploading } =
        useAttachmentUpload();

    const form = useForm<z.infer<typeof formSchema>>({
        mode: 'onChange',
        resolver: zodResolver(formSchema),
        defaultValues: {
            certificates: [],
        },
    });

    const photos = form.watch('photos');
    const delegateId = form.watch('delegateId');

    const { fields, append, remove } = useFieldArray({
        control: form.control,
        name: 'certificates',
    });

    const onSubmit = async (data: z.infer<typeof formSchema>) => {
        const documents: MaterialDocument[] = [];

        if (data.certificates && data.certificates.length > 0) {
            const files = Array.from(data.certificates).map((file) => {
                const uploadedFile = file.certificateFile[0];
                const param: { data: FileCreateDataObject; info: any } = {
                    data: {
                        contentType: uploadedFile.type,
                        size: uploadedFile.size,
                        name: uploadedFile.name,
                        uploadedBy: context.user?._id || '',
                        workspace: context.workspace?._id || '',
                        genre: 'material',
                        file: uploadedFile,
                    },
                    info: {
                        certificateNumber: file.certificateNumber,
                        certificateType: file.certificationType,
                        weight: file.weight,
                    },
                };

                return param;
            });

            await Promise.all(
                files.map(async (file) => {
                    const response = await upload({
                        file: file.data,
                        assetType: 'material',
                        delegateId: data.delegateId || '',
                    });

                    await axios.put(response.token, file.data.file, {
                        headers: {
                            'x-ms-blob-type': 'BlockBlob',
                            'Content-Type':
                                response.attachment.contentType || '',
                        },
                    });

                    documents.push({
                        assetName: response.attachment.assetName,
                        container: response.attachment.container,
                        contentType: response.attachment.contentType,
                        extension:
                            response.attachment.originalName.split('.').pop() ||
                            '',
                        originalName: response.attachment.originalName,
                        readReleaseTime: response.attachment.readReleaseTime,
                        certificateNumber: file.info.certificateNumber,
                        certificateType: file.info.certificateType,
                        weight: file.info.weight,
                        attachment: response.attachment,
                    });
                })
            );
        }

        const pictures: string[] = data.photos.map((photo) => {
            return getFileIdFromAssetName(photo.id);
        });

        const documentIds = documents.map((doc) => {
            return {
                assetName: doc.assetName,
                certificateNumber: doc.certificateNumber,
                certificateType: doc.certificateType,
                weight: doc.weight,
                file: doc.attachment,
                _id: getFileIdFromAssetName(doc.assetName),
                attachment: doc.attachment,
            };
        });

        await create({
            delegateId: data.delegateId,
            body: {
                name: data.name,
                unitCost: data.unitCost,
                unitCostCurrency: data.unitCostCurrency,
                unitOfMeasurement: data.unitOfMeasurement,
                description: data.description,
                pictures: pictures,
                documents: documentIds.map((x) => {
                    return {
                        _id: x.assetName,
                        comment: x.weight?.toString(),
                        file: x.attachment,
                        type: x.certificateType || '',
                        uploader: context.user?._id || '',
                        certificateNumber: x.certificateNumber,
                    };
                }),
                specifications: {
                    width: data.width,
                    height: data.height,
                    length: data.length,
                    weight: data.weight,
                },
                workspace: data.delegateId || context.workspace?._id || '',
            },
        });

        reset();
    };

    const reset = () => {
        onClose();
        form.reset();
    };

    return (
        <DialogV2
            open={open}
            onClose={reset}
            form={form}
            onSubmit={onSubmit}
            title='Add Component'
            isStepDialog
            formName='add-material-form'
            isLoading={isCreating || isUploading}
            size='lg'
            steps={[
                {
                    title: 'Delegation Controls',
                    description: 'Select the delegate for this component.',
                    content: (
                        <DelegationInput
                            name='delegateId'
                            label='On Behalf Of.'
                        />
                    ),
                },
                {
                    title: 'Component Information',
                    description:
                        'Enter the basic information for this component.',
                    content: (
                        <>
                            <TextInputV2
                                name='name'
                                required
                                label='Component Name'
                            />
                            <TextInputV2
                                name='description'
                                label='Description'
                            />

                            <SelectInputV2
                                name='unitOfMeasurement'
                                label='Unit Of Measurement'
                                required
                                options={Object.values(ProductUom).map(
                                    (uom) => ({
                                        label: ProductUomLabels[uom],
                                        value: uom,
                                    })
                                )}
                            />

                            <div className='grid grid-cols-2 gap-4'>
                                <SelectInputV2
                                    label='Unit Currency'
                                    name='unitCostCurrency'
                                    required
                                    options={currencies.map((currency) => ({
                                        label: currency,
                                        value: currency,
                                    }))}
                                />

                                <TextInputV2
                                    name='unitCost'
                                    label='Unit Cost'
                                    required
                                    type='number'
                                />
                            </div>
                        </>
                    ),
                },
                {
                    title: 'Measurements',
                    description:
                        'Enter the physical specifications of the component.',
                    content: (
                        <div className='grid grid-rows-2 gap-4'>
                            <TextInputV2
                                name='weight'
                                label='Weight'
                                type='number'
                            />

                            <div className='grid grid-cols-3 gap-4'>
                                <TextInputV2
                                    name='length'
                                    label='Length'
                                    type='number'
                                    required
                                />

                                <TextInputV2
                                    name='width'
                                    label='Width'
                                    type='number'
                                    required
                                />

                                <TextInputV2
                                    name='height'
                                    label='Height'
                                    type='number'
                                    required
                                />
                            </div>
                        </div>
                    ),
                },
                {
                    title: 'Component Images',
                    description: 'Upload the images for this component.',
                    content: (
                        <PhotoGrid
                            photos={photos}
                            onChange={(newPhotos) =>
                                form.setValue('photos', newPhotos)
                            }
                            delegateId={delegateId}
                            assetType='material'
                        />
                    ),
                },
                {
                    title: 'Component Certificates',
                    description: 'Upload the certificates for this component.',
                    content: (
                        <AddMaterialCertificates
                            certificates={fields}
                            append={append}
                            remove={remove}
                        />
                    ),
                },
            ]}
        ></DialogV2>
    );
}
