import { ColumnDef } from '@tanstack/react-table';
import { Trash } from 'lucide-react';
import { useFormContext } from 'react-hook-form';
import { MaterialModel } from 'src/app/_api_adb2c/product/material/models/material.model';
import { TableV2 } from 'src/app/components-v2/table-v2';
import { RadioInput } from 'src/app/components/Form/RadioInput';
import { TextInputV2 } from 'src/app/components/Form/TextInputV2';
import { z } from 'zod';
import { substanceItemSchema } from './add-substance-v3';

export function AboutManualSubstance({
    material,
    items,
    remove,
    replace,
}: {
    material?: MaterialModel;
    items: z.infer<typeof substanceItemSchema>[];
    remove: (index: number) => void;
    replace: (value: z.infer<typeof substanceItemSchema>[]) => void;
}) {
    const form = useFormContext();
    const calculateProjectedWeight = (value: number) => {
        const materialWeight = material?.specifications?.weight || 0;
        const projectedWeight = materialWeight * (value / 100);

        return projectedWeight;
    };

    const isMixedSubstance = form.watch('isMixedSubstance');

    const getSubstanceName = (item: z.infer<typeof substanceItemSchema>) => {
        if (item.isManual) {
            const latest = item.substance?.[item.substance?.length - 1];

            return item.name || latest?.label;
        }

        return item.name;
    };

    const columns: ColumnDef<z.infer<typeof substanceItemSchema>>[] = [
        {
            id: 'substance',
            header: 'Substance',
            cell: ({ row }) => {
                return <div>{getSubstanceName(row.original)}</div>;
            },
        },
        {
            id: 'cas',
            header: 'CAS Number',
            cell: ({ row }) => {
                return <div>{row.original.cas}</div>;
            },
        },
        {
            id: 'percentage',
            header: '% Of Component Weight',
            cell: ({ row }) => {
                return <div>{row.original.percentage}</div>;
            },
        },
        {
            id: 'projectedWeight',
            header: 'Projected Weight (KG)',
            cell: ({ row }) => {
                return <div>{row.original.projectedWeight}</div>;
            },
        },
        {
            id: 'isManMade',
            header: 'Is Man Made Substance',
            cell: ({ row }) => {
                return <div>{row.original.isManMade ? 'Yes' : 'No'}</div>;
            },
        },
        {
            id: 'actions',
            header: 'Actions',
            cell: ({ row }) => {
                const parentRow = row.getParentRow()?.index ?? row.index;
                const isSubRow = row.depth > 0;

                return (
                    <div className='flex items-center gap-2'>
                        <Trash
                            size={16}
                            className='cursor-pointer hover:scale-125'
                            onClick={(e) => {
                                e.stopPropagation();
                                if (isSubRow) {
                                    const updatedComposition = [
                                        ...(items[parentRow].composition || []),
                                    ];

                                    updatedComposition.splice(row.index, 1);
                                    console.log(items);
                                    console.log(
                                        'this is my updated composition',
                                        updatedComposition
                                    );

                                    const clonedItems = [...items];

                                    clonedItems[parentRow].composition =
                                        updatedComposition;

                                    replace(clonedItems);
                                    // replace(updatedComposition);
                                } else {
                                    remove(row.index);
                                }
                            }}
                            role='button'
                        />
                    </div>
                );
            },
        },
    ];

    console.log('this is my items', items);

    return (
        <>
            <TextInputV2
                name='name'
                label='Substance Name'
                placeholder='Enter Substance Name'
            />

            <TextInputV2
                name='cas'
                label='CAS Number'
                placeholder='Enter CAS Number'
            />

            <TextInputV2
                name='percentage'
                label='% Of Component Weight'
                placeholder='Enter Percentage'
                onValueChange={(value) => {
                    const projectedWeight = calculateProjectedWeight(
                        Number(value)
                    );

                    form.setValue('projectedWeight', projectedWeight);
                }}
            />

            <TextInputV2
                name='projectedWeight'
                label='Projected Weight (KG)'
                placeholder='Enter Projected Weight'
                readOnly
                className='cursor-not-allowed'
                tooltip='This is dependent on the % of component weight'
            />

            <RadioInput
                name='isMixedSubstance'
                label='Is Mixed Substance'
                options={[
                    { label: 'Yes', value: 'true' },
                    { label: 'No', value: 'false' },
                ]}
            />

            <RadioInput
                name='isManMade'
                label='Is Man Made Substance'
                options={[
                    { label: 'Yes', value: 'true' },
                    { label: 'No', value: 'false' },
                ]}
            />

            {isMixedSubstance && (
                <TableV2
                    tableContainerClassName='col-span-2'
                    columns={columns}
                    disabledTooltipColumns={['actions']}
                    subRowsField='composition'
                    data={items}
                    fixedHeight='375px'
                />
            )}
        </>
    );
}
