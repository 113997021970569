import { SalesOrderDocCollectDataObject } from '../request/sales-order-doc-collection-data-object';

export const BASE_SALES_ORDER_STATISTICS_QUERY_KEY = 'sales-order-statistics';
export const BASE_SALES_ORDERS_QUERY_KEY = 'sales-orders';
export const BASE_SALES_ORDERS_V2_QUERY_KEY = 'sales-orders-v2';
export const BASE_SALES_ORDER_QUERY_KEY = 'sales-order';
export const BASE_BATCH_SALES_ORDERS_QUERY_KEY = 'batch-sales-orders';

export const SALES_ORDER_STATISTICS_QUERY_KEY = (workspace: string) => [
    BASE_SALES_ORDER_STATISTICS_QUERY_KEY,
    workspace,
];

export const SALES_ORDERS_QUERY_KEY = (workspace: string) => [
    BASE_SALES_ORDERS_QUERY_KEY,
    workspace,
];

export const SALES_ORDERS_V2_QUERY_KEY = (workspace: string) => [
    BASE_SALES_ORDERS_V2_QUERY_KEY,
    workspace,
];

export const SALES_ORDER_QUERY_KEY = (
    workspace: string,
    id: number | string
) => [BASE_SALES_ORDER_QUERY_KEY, workspace, id];

export const SALES_ORDER_SEARCH_QUERY_KEY = (
    workspace: string,
    item?: string[]
) => [BASE_SALES_ORDERS_QUERY_KEY, workspace, item];

export const SALES_ORDER_DOCUMENTS_QUERY_KEY = (
    workspace: string,
    item?: SalesOrderDocCollectDataObject
) => [BASE_SALES_ORDERS_QUERY_KEY, workspace, item];

export const BATCH_SALES_ORDERS_QUERY_KEY = (
    workspace: string,
    targets: string[]
) => [BASE_BATCH_SALES_ORDERS_QUERY_KEY, ...targets];
