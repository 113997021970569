import { useFormContext } from 'react-hook-form';
import {
    PurchaseOrderNature,
    PurchaseOrderNatureLabels,
} from 'src/app/_api_adb2c/purchase/purchase/enums/purchase-order-nature.enum';
import { useSuppliers } from 'src/app/_api_adb2c/purchase/suppliers/hooks/use-suppliers';
import {
    SupplyChainNodeType,
    SupplyChainNodeTypeLabel,
} from 'src/app/_api_adb2c/workspace/shared/enum/supply-chain-node-type.enum';
import { MultiSelectInputV2 } from 'src/app/components/Form/MultiSelectInputV2';
import { SelectInputV2 } from 'src/app/components/Form/SelectInputV2';
import { TextInputV2 } from 'src/app/components/Form/TextInputV2';

export const AddPurchaseRequiredFields = () => {
    const context = useFormContext();
    const currencies = ['USD', 'EUR', 'HKD', 'RMB', 'SGB'];

    const delegateId = context.watch('delegateId');

    const { data: suppliers } = useSuppliers(delegateId);
    const selectedSupplier = context.watch('supplier');

    return (
        <>
            <div className='flex w-full gap-4'>
                <SelectInputV2
                    label='Supplier'
                    name='supplier'
                    required
                    options={[
                        { label: '+ Add New', value: 'new' },
                        ...(suppliers || []).map((x) => ({
                            label: x.seller.name,
                            value: x.seller._id,
                        })),
                    ]}
                />

                <SelectInputV2
                    label='Secondary Supplier'
                    name='secondarySupplier'
                    options={
                        suppliers
                            ?.filter((x) => x.seller._id !== selectedSupplier)
                            .map((x) => ({
                                label: x.seller.name,
                                value: x.seller._id,
                            })) || []
                    }
                />
            </div>

            <TextInputV2
                label='Delivery Address'
                name='shipToAddress'
                placeholder='Enter Shipping Address'
                required
            />

            <TextInputV2
                label='Ref No.'
                name='externalDataId'
                placeholder='Enter reference number'
            />

            <SelectInputV2
                label='Currency'
                name='currency'
                required
                options={currencies.map((x) => ({ label: x, value: x }))}
            />

            <SelectInputV2
                label='Order Nature'
                name='orderNature'
                options={Object.values(PurchaseOrderNature).map((nature) => ({
                    label: PurchaseOrderNatureLabels[nature],
                    value: nature,
                }))}
            />

            <MultiSelectInputV2
                label='Purchase Processes'
                name='purchaseProcesses'
                options={Object.values(SupplyChainNodeType).map((x) => ({
                    label: SupplyChainNodeTypeLabel[x],
                    value: x,
                }))}
            />
        </>
    );
};
