import { ServiceConfigInterface } from 'src/domain/interfaces/config.context.interface';
import { ApiServer } from 'src/infrastructure/clients/api.server';
import { WorkspaceUpdateRequestObject } from './workspace-update-request-object';
import { CompanyUpdateRequestObject } from './company-update-request-object';
import { BatchWorkspaceRequestObject } from './batch-workspace.dto';

export class WorkspaceApiClient extends ApiServer {
    constructor(config: ServiceConfigInterface, token: string) {
        super(token, config, 'workspaces', 'workspace');
    }

    getMembers(workspace: string) {
        console.log(`client<workspace>| getMembers(): Enter`);
        console.log(
            `client<workspace>| getMembers(): $workspace = ${workspace}`
        );

        return super.get('getMembers', workspace);
    }

    updateWorkspace(workspace: string, body: WorkspaceUpdateRequestObject) {
        console.log(`client<workspace>| updateWorkspace(): Enter`);
        console.log(
            `client<workspace>| updateWorkspace(): $workspace = ${workspace}`
        );
        console.log(`client<workspace>| updateWorkspace(): $body = ${body}`);

        return super.patch('update', workspace, body);
    }

    getWorkspacesByAccount(account: string) {
        console.log(`client<workspace>| getWorkspacesByAccount(): Enter`);
        console.log(
            `client<workspace>| getWorkspacesByAccount(): $account = ${account}`
        );

        return super.get('getWorkspacesByAccount', account);
    }

    updateWorkspaceCompany(
        workspace: string,
        body: CompanyUpdateRequestObject
    ) {
        console.log(`client<workspace>| updateWorkspaceCompany(): Enter`);
        console.log(
            `client<workspace>| updateWorkspaceCompany(): $workspace = ${workspace}`
        );
        console.log(
            `client<workspace>| updateWorkspaceCompany(): $body = ${body}`
        );

        return super.patch('updateCompany', workspace, body);
    }

    searchByCompanyName(name: string, diversion = '0') {
        console.log(`client<workspace>| searchByCompanyName(): Enter`);
        console.log(
            `client<workspace>| searchByCompanyName(): $name = ${name}`
        );
        console.log(
            `client<workspace>| searchByCompanyName(): $diversion = ${diversion}`
        );

        return super.get('search-company', name, diversion);
    }

    async get(workspace: string, diversion: string = '0') {
        console.log(`client<workspace>| get(): Enter`);
        console.log(`client<workspace>| get(): $workspace = ${workspace}`);
        console.log(`client<workspace>| get(): $diversion = ${diversion}`);
        return super.get('get', workspace, diversion);
    }

    getCertificationTypes() {
        console.log(`client<workspace>| getCertificationTypes(): Enter`);
        return super.get('getCertificationTypes');
    }

    getBatchWorkspaces(workspaceId: string, data: BatchWorkspaceRequestObject) {
        console.log(`client<workspace>| getBatchWorkspaces(): Enter`);
        console.log(
            `client<workspace>| getBatchWorkspaces(): $workspaceId = ${workspaceId}`
        );
        console.log(
            `client<workspace>| getBatchWorkspaces(): $data = ${JSON.stringify(
                data
            )}`
        );

        return super.post('getBatchWorkspaces', workspaceId, data);
    }
}
