import { PurchaseOrderNature } from 'src/app/_api_adb2c/purchase/purchase/enums/purchase-order-nature.enum';
import { PurchaseCascadeGroupNode } from './nodes/purchase-cascade-group-node';
import { PurchaseCascadeNode } from './nodes/purchase-cascade-node';
import { PurchaseCascadeApparelGroupNode } from './nodes/purchase-cascade-apparel-group-node';

export enum CascadeNodeType {
    APPAREL_GROUP = 'APPAREL_GROUP',
    NODE_GROUP = 'NODE_GROUP',
    NODE = 'NODE',
}

export const nodeTypes = {
    [CascadeNodeType.APPAREL_GROUP]: PurchaseCascadeApparelGroupNode,
    [CascadeNodeType.NODE_GROUP]: PurchaseCascadeGroupNode,
    [CascadeNodeType.NODE]: PurchaseCascadeNode,
};

export const CustomOrderNatureLabel: Record<PurchaseOrderNature, string> = {
    [PurchaseOrderNature.COMPONENT]: 'Component',
    [PurchaseOrderNature.PROCESS]: 'Production - In-house',
    [PurchaseOrderNature.PROCESSING]: 'Production - In-house',
    [PurchaseOrderNature.OUTSOURCE]: 'Production - Outsource',
    [PurchaseOrderNature.RAW_MATERIAL]: 'Raw Material',
};
