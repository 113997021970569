import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';
import { SUPPLIERS_QUERY_KEY } from 'src/app/_api_adb2c/purchase/suppliers/hooks/supplier-query-keys';
import { useContextStore } from 'src/app/stores/context-store';
import { DELEGATIONS_QUERY_KEY } from '../../delegations/hooks/delegation-query-keys';
import { SolicitationCreateRequestObject } from '../solicitation-create-request-object';
import { useAccountService } from './use-account-service';

export function useSupplierInvite() {
    const { service } = useAccountService();
    const context = useContextStore();
    const client = useQueryClient();
    const delay = async (ms: number) =>
        new Promise((res) => setTimeout(res, ms));

    return useMutation({
        mutationFn: async ({
            body,
            delegateId,
            diversion = '0',
        }: {
            body: SolicitationCreateRequestObject;
            delegateId?: string;
            diversion?: string;
        }) => {
            return await service.invite(
                delegateId || context.workspace?._id || '',
                body,
                diversion
            );
        },
        onSuccess: async (_, { delegateId }) => {
            await delay(3000);

            client.invalidateQueries(
                SUPPLIERS_QUERY_KEY(delegateId || context.workspace?._id || '')
            );

            client.invalidateQueries({
                queryKey: DELEGATIONS_QUERY_KEY(context.workspace?._id || ''),
            });

            if (delegateId) {
                client.invalidateQueries({
                    queryKey: DELEGATIONS_QUERY_KEY(delegateId),
                });
            }

            toast.success('Supplier invited successfully');
        },
        onError: (err) => {
            const error = err as Error;

            toast.error(`Failed to invite supplier: ${error.message}`);
        },
    });
}
