import { useQuery } from '@tanstack/react-query';
import { toast } from 'sonner';
import { useContextStore } from 'src/app/stores/context-store';
import { PURCHASE_COTTON_WEIGHT_QUERY_KEY } from './purchase-query-keys';
import { usePurchaseService } from './use-purchase-service';

export function usePurchaseCottonWeight(
    id: string,
    delegateId?: string,
    diversion = '0'
) {
    const context = useContextStore();
    const { service } = usePurchaseService();

    return useQuery<number>({
        queryKey: PURCHASE_COTTON_WEIGHT_QUERY_KEY(
            delegateId || context.workspace?._id || '',
            id
        ),
        queryFn: async () => {
            return await service.getCottonWeight(
                delegateId || context.workspace?._id || '',
                id,
                diversion
            );
        },
        enabled: !!id,
        onError: (err) => {
            const error = err as Error;

            toast.error(
                `Failed to fetch purchase cotton weight: ${error.message}`
            );
        },
    });
}
