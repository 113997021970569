import { useMemo } from 'react';
import { EnrichedSalesOrder } from 'src/app/_api_adb2c/sales/sales/hooks/use-sales-orders-v2';
import { SalesOrderModel } from 'src/app/_api_adb2c/sales/sales/models/sales-order.model';
import { SupportDocType } from 'src/app/_api_adb2c/shared/support-doc-type.enum';

const isEnrichedSalesOrder = (
    order: EnrichedSalesOrder | SalesOrderModel
): order is EnrichedSalesOrder => {
    return 'rulesets' in order;
};

export const useDocumentCompletion = (
    order: EnrichedSalesOrder | SalesOrderModel
) => {
    return useMemo(() => {
        const { documents, purchase } = order;

        const processes = purchase?.processes || [];
        const ruleset = isEnrichedSalesOrder(order)
            ? order.rulesets[0]
            : order.ruleset;

        const requiredRules = ruleset?.rules.filter((rule) =>
            rule.processes.some((p) => processes.includes(p))
        );

        const requiredDocTypes = (requiredRules || []).map(
            (rule) => rule.document
        );

        const uploadedDocTypes = new Set(documents.map((doc) => doc.type));

        const completedCount = requiredDocTypes.filter((docType) =>
            uploadedDocTypes.has(docType as SupportDocType)
        ).length;

        return (completedCount / requiredDocTypes.length) * 100;
    }, [order]);
};
