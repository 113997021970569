import { useQueryClient } from '@tanstack/react-query';
import { useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'sonner';
import { useRemoveLink } from 'src/app/_api_adb2c/purchase/link/hooks/use-remove-link';
import {
    BASE_PURCHASE_QUERY_KEY,
    BASE_PURCHASE_TRACE_QUERY_KEY,
} from 'src/app/_api_adb2c/purchase/purchase/hooks/purchase-query-keys';
import { useDeletePurchase } from 'src/app/_api_adb2c/purchase/purchase/hooks/use-delete-purchase';
import { usePurchaseTrace } from 'src/app/_api_adb2c/purchase/purchase/hooks/use-purchase-trace';
import { PurchaseOrderTraceModel } from 'src/app/_api_adb2c/purchase/purchase/models/purchase.model';
import { BASE_SALES_ORDERS_QUERY_KEY } from 'src/app/_api_adb2c/sales/sales/hooks/sales-order-query-keys';
import { useSalesOrderBatch } from 'src/app/_api_adb2c/sales/sales/hooks/use-sales-order-batch';
import { SalesOrderModel } from 'src/app/_api_adb2c/sales/sales/models/sales-order.model';
import { BaseDialog } from 'src/app/components-v2/base-dialog';

interface Props {
    purchase: string;
    open: boolean;
    onClose: () => void;
    deleteParent?: boolean;
    workspaceId?: string;
}

interface VirtualItem {
    _id: string;
    purchase: PurchaseOrderTraceModel;
    salesOrder?: SalesOrderModel;
}

export function PurchaseClearCascade({
    purchase,
    open,
    onClose,
    deleteParent = true,
    workspaceId,
}: Props) {
    const [searchParams] = useSearchParams();
    const delegateId = searchParams.get('delegateId') || '';
    const [isLoading, setIsLoading] = useState(false);

    const client = useQueryClient();

    const delay = (ms: number) =>
        new Promise((resolve) => setTimeout(resolve, ms));

    const { data: traces } = usePurchaseTrace(
        purchase,
        workspaceId || delegateId
    );

    const { data: salesOrders } = useSalesOrderBatch([
        purchase,
        ...(traces || []).map((x) => x._id),
    ]);

    const memoizedTraces = useMemo(() => {
        const purchases: VirtualItem[] = [];

        traces?.forEach((trace) => {
            const salesOrder = salesOrders?.find(
                (x) => x.purchaseOrderId === trace._id
            );

            purchases.push({
                _id: trace._id,
                purchase: trace,
                salesOrder: salesOrder,
            });
        });

        return purchases.sort((a, b) => {
            return b.purchase.depth - a.purchase.depth;
        });
    }, [salesOrders, traces]);

    const { mutateAsync: remove } = useDeletePurchase();
    const { mutateAsync: unlink } = useRemoveLink();

    const onSubmit = async () => {
        setIsLoading(true);

        // Unlinking all the orders
        await Promise.all(
            memoizedTraces
                .filter((x) => x)
                .map(async (item) => {
                    await Promise.all(
                        (item.purchase.parent || [])
                            .filter((x) => x)
                            .map(async (parent) => {
                                console.debug('parent', parent);
                                await unlink({
                                    delegateId: delegateId,
                                    orderId: parent,
                                    purchaseId: item.purchase._id,
                                    diversion: '1',
                                });
                            })
                    );
                })
        );

        await delay(1500);

        // Removing all the orders
        await Promise.all(
            memoizedTraces.map(async (item) => {
                await remove({
                    id: item.purchase._id,
                    delegateId: item.purchase.workspace,
                    diversion: '1',
                });
            })
        );

        if (deleteParent) {
            // Remove the parent purchase order
            await remove({
                id: purchase,
                delegateId: workspaceId || delegateId,
            });
        }

        client.invalidateQueries({
            queryKey: BASE_PURCHASE_QUERY_KEY,
        });

        client.invalidateQueries({
            queryKey: BASE_PURCHASE_TRACE_QUERY_KEY,
        });

        client.invalidateQueries({
            queryKey: [BASE_SALES_ORDERS_QUERY_KEY],
        });

        setIsLoading(false);
        toast.success('Successfully cleared cascade, please refresh the page');

        onClose();
    };

    return (
        <BaseDialog
            open={open}
            onClose={onClose}
            title='Clear Cascade'
            isLoading={isLoading}
            onConfirm={onSubmit}
        >
            <div className='flex flex-col gap-2'>
                <span className='text-sm font-bold'>
                    Are you sure you want to clear the cascade?
                </span>

                <span className='text-sm font-light'>
                    * This action cannot be undone. This will permanently delete
                    the selected purchase and all its linked orders.
                </span>
            </div>
        </BaseDialog>
    );
}
