import { TabsTrigger } from '@radix-ui/react-tabs';
import { DateTime } from 'luxon';
import { useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useSupplier } from 'src/app/_api_adb2c/purchase/suppliers/hooks/use-supplier';
import {
    SupplyChainNodeType,
    SupplyChainNodeTypeLabel,
} from 'src/app/_api_adb2c/workspace/shared/enum/supply-chain-node-type.enum';
import { DetailLabel } from 'src/app/components-v2/detail-label';
import { Title } from 'src/app/components-v2/typography';
import { useBreadcrumbStore } from 'src/app/stores/breadcrumb-store';
import DocImage from 'src/assets/document.png';
import { Badge } from 'src/components/ui/badge';
import { Tabs, TabsContent, TabsList } from 'src/components/ui/tabs';
import countries from 'src/infrastructure/config/data/countries.json';
import { cn } from 'src/lib/utils';
import { SupplierDocuments } from './supplier-documents';

export enum MenuOption {
    DOCS = 'docs',
    TEST = 'test',
}

export function SupplierDetails() {
    const { id } = useParams<{ id: string }>();
    const [searchParams, setSearchParams] = useSearchParams({});
    const delegateId = searchParams.get('delegateId') || '';
    const { setBreadcrumbs } = useBreadcrumbStore();
    const currentTab = searchParams.get('menu') as MenuOption;

    const { data } = useSupplier(id || '', delegateId);

    const menuOptions = [
        {
            title: MenuOption.DOCS,
            icon: DocImage,
            alt: 'docs-icon',
        },
    ];

    const getRenderedContent = (menu: MenuOption): React.ReactNode => {
        const contentMap: Record<MenuOption, React.ReactNode> = {
            [MenuOption.DOCS]: <SupplierDocuments supplier={data?.seller} />,
            [MenuOption.TEST]: <></>,
        };

        return contentMap[menu];
    };

    useEffect(() => {
        if (!searchParams.get('menu')) {
            setSearchParams((prev) => {
                prev.set('menu', MenuOption.DOCS);

                return prev;
            });
        }
    }, [searchParams, setSearchParams]);

    useEffect(() => {
        if (!data) return;

        setBreadcrumbs([
            {
                label: 'Suppliers',
                navigateTo: '/suppliers',
            },
            {
                label: data.seller.name,
            },
        ]);
    }, [data, setBreadcrumbs]);

    return (
        <div className='flex h-full flex-col gap-4'>
            <Title>Supplier Details</Title>

            <div className='grid grid-cols-2 gap-4 rounded border bg-white p-4 shadow'>
                <DetailLabel title='Supplier Name'>
                    {data?.seller?.name}
                </DetailLabel>

                <DetailLabel title='Registration Number'>
                    {data?.seller?.registrationNumber}
                </DetailLabel>

                <DetailLabel title='Created On'>
                    {data?.createdOn
                        ? DateTime.fromJSDate(
                              new Date(data.createdOn)
                          ).toFormat('yyyy-MM-dd')
                        : '--'}
                </DetailLabel>

                <DetailLabel title='Country'>
                    {
                        countries.find(
                            (x) => x['alpha-2'] === data?.seller?.country
                        )?.name
                    }
                </DetailLabel>

                <DetailLabel title='Processes' className='col-span-1 pr-10'>
                    <div className='flex flex-wrap gap-1'>
                        {!data?.seller?.processes ||
                        data?.seller?.processes.length === 0 ? (
                            <span className='text-gray-500'>
                                No processes found
                            </span>
                        ) : (
                            data?.seller?.processes.map((process) => (
                                <Badge key={process} className='mb-1 mr-1'>
                                    {
                                        SupplyChainNodeTypeLabel[
                                            process as SupplyChainNodeType
                                        ]
                                    }
                                </Badge>
                            ))
                        )}
                    </div>
                </DetailLabel>
            </div>

            <Tabs
                value={searchParams.get('menu') as MenuOption}
                className='flex h-full flex-col justify-start'
                onValueChange={(value) =>
                    setSearchParams((prev) => {
                        prev.set('menu', value);

                        return prev;
                    })
                }
            >
                <TabsList className='min-h-[60px] justify-start'>
                    {menuOptions.map((x, index) => {
                        return (
                            <TabsTrigger
                                value={x.title}
                                className={cn(
                                    currentTab === x.title
                                        ? 'rounded-lg bg-white shadow'
                                        : '',
                                    'w-24'
                                )}
                            >
                                <figure className='flex cursor-pointer flex-col items-center rounded capitalize'>
                                    <img
                                        src={x.icon}
                                        alt={x.alt}
                                        className='min-h-8 min-w-8 max-w-8 max-h-8 rounded-l p-1'
                                        key={index}
                                        id={`${x.alt}-${index.toString()}`}
                                    />
                                    <figcaption className='text-sm text-gray-500 dark:text-gray-400'>
                                        {x.title}
                                    </figcaption>
                                </figure>
                            </TabsTrigger>
                        );
                    })}
                </TabsList>

                {menuOptions.map((x) => {
                    return (
                        <TabsContent
                            className='h-full flex-1 bg-white p-4'
                            key={`menu-${x.title}`}
                            value={x.title}
                        >
                            {getRenderedContent(x.title)}
                        </TabsContent>
                    );
                })}
            </Tabs>
        </div>
    );
}
