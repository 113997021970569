import { FieldPath, FieldValues, useFormContext } from 'react-hook-form';
import {
    FormControl,
    FormDescription,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from 'src/components/ui/form';
import { Input, InputProps } from 'src/components/ui/input';
import { useState, useEffect } from 'react';
import { cn } from 'src/lib/utils';
import { InfoIcon } from 'lucide-react';
import { TooltipWrapper } from 'src/app/components-v2/tooltip';

const sizes = {
    xs: 'text-xs',
    sm: 'text-sm',
    md: 'text-base',
};

interface TextInputV2Props<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends Omit<InputProps, 'name' | 'size'> {
    name: TName;
    label?: string;
    description?: string;
    size?: keyof typeof sizes;
    placeholder?: string;
    tabIndex?: number;
    onValueChange?: (value: string) => void;
    tooltip?: string;
    required?: boolean;
}

export function TextInputV2<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
    name,
    label,
    description,
    size = 'xs',
    placeholder = '-',
    tabIndex,
    onValueChange,
    tooltip,
    ...props
}: TextInputV2Props<TFieldValues, TName>) {
    const form = useFormContext<TFieldValues>();
    const currentValue = form.watch(name);
    const [localValue, setLocalValue] = useState<string>(currentValue ?? '');

    useEffect(() => {
        setLocalValue(currentValue ?? '');
    }, [currentValue]);

    return (
        <FormField
            control={form.control}
            name={name}
            render={({ field, fieldState }) => (
                <FormItem>
                    {label && (
                        <FormLabel className={cn('', sizes[size])}>
                            <span className='flex items-center gap-1'>
                                {label}
                                {props.required && (
                                    <span className='text-destructive'>*</span>
                                )}{' '}
                                {tooltip && (
                                    <TooltipWrapper
                                        triggerClassName='w-fit'
                                        content={
                                            <InfoIcon className='h-3 w-3' />
                                        }
                                        tooltipContent={tooltip}
                                    />
                                )}
                            </span>
                        </FormLabel>
                    )}

                    <FormControl>
                        <TooltipWrapper
                            content={
                                <Input
                                    {...field}
                                    {...props}
                                    className={cn(
                                        'h-8',
                                        sizes[size],
                                        fieldState.error &&
                                            'border-destructive',
                                        props.className
                                    )}
                                    value={localValue}
                                    onChange={(e) => {
                                        setLocalValue(e.target.value);
                                    }}
                                    onBlur={(e) => {
                                        field.onChange(e.target.value);
                                        onValueChange?.(e.target.value);
                                        props.onBlur?.(e);
                                    }}
                                    placeholder={placeholder}
                                    onFocus={(e) => {
                                        e.stopPropagation();
                                        props.onFocus?.(e);
                                    }}
                                />
                            }
                            tooltipContent={
                                fieldState.error?.message && (
                                    <p className='text-xs'>
                                        {fieldState.error.message}
                                    </p>
                                )
                            }
                            mode={
                                fieldState.error?.message ? 'error' : 'default'
                            }
                            disabled={!fieldState.error?.message}
                        />
                    </FormControl>

                    {description && (
                        <FormDescription className={cn('text-xs')}>
                            {description}
                        </FormDescription>
                    )}

                    <FormMessage className={cn('text-xs')} />
                </FormItem>
            )}
        />
    );
}
