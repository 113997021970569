import { SelectInputV2 } from 'src/app/components/Form/SelectInputV2';
import { SwitchInput } from 'src/app/components/Form/SwitchInput';
import { TextInputV2 } from 'src/app/components/Form/TextInputV2';
import countries from '../../../infrastructure/config/data/countries.json';

export function SupplierRequiredFields() {
    return (
        <>
            <TextInputV2 name='companyName' label='Company Name' required />

            <TextInputV2
                name='registrationNumber'
                label='Registration Number'
                required
            />

            <SelectInputV2
                name='country'
                label='Country'
                required
                options={countries.map((x) => ({
                    label: x.name,
                    value: x['alpha-2'],
                }))}
                className='mb-2'
            />

            <br />
            <SwitchInput name='assignDelegate' label='Assign as Delegate' />
        </>
    );
}
