import { useCallback, useMemo, useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useProducts } from 'src/app/_api_adb2c/product/product/hooks/use-products';
import { useFileDownloader } from 'src/app/hooks/use-file-downloader';
import { getFileIdFromAssetName } from 'src/infrastructure/utils/extract-asset-id';
import BlankImage from 'src/assets/blank-image.png';
import { Loader2 } from 'lucide-react';

interface Props {
    materialId: string;
}

export function MaterialProduct({ materialId }: Props) {
    const [searchParams] = useSearchParams();
    const delegateId = searchParams.get('delegateId') || '';
    const [productImages, setProductImages] = useState<Record<string, string>>(
        {}
    );
    const [isLoadingImages, setIsLoadingImages] = useState(true);

    const { data: products } = useProducts(delegateId);
    const { getDownloadTokens } = useFileDownloader();

    const memoizedData = useMemo(() => {
        if (!products) return [];

        return products.data.filter((x) => {
            const latestVersion = x.versions?.[x.versions.length - 1];

            return latestVersion?.billOfMaterials?.find((y) => {
                return y.material?._id === materialId;
            });
        });
    }, [products, materialId]);

    const loadProductImages = useCallback(async () => {
        if (!memoizedData.length) return;

        const imagePromises = memoizedData.map(async (product) => {
            if (!product.pictures?.length) return [product._id, BlankImage];

            const firstPicture = product.pictures[0];
            const fileId =
                firstPicture instanceof Object
                    ? getFileIdFromAssetName(firstPicture.assetName)
                    : firstPicture;

            if (!fileId) return [product._id, BlankImage];

            try {
                const tokens = await getDownloadTokens([fileId]);
                const token = tokens[0];
                return [product._id, token?.token || BlankImage];
            } catch (error) {
                console.error('Error loading product image:', error);
                return [product._id, BlankImage];
            }
        });

        const results = await Promise.all(imagePromises);
        const newImages = Object.fromEntries(results);
        setProductImages(newImages);
        setIsLoadingImages(false);
    }, [memoizedData, getDownloadTokens]);

    useEffect(() => {
        setIsLoadingImages(true);
        loadProductImages();
    }, [loadProductImages]);

    if (memoizedData.length === 0) {
        return (
            <div className='flex h-full items-center justify-center rounded-lg bg-white p-8 text-gray-500 shadow'>
                <div className='text-center'>
                    <p className='mb-2 text-sm'>
                        No products found using this material
                    </p>
                    <p className='text-xs text-gray-400'>
                        Products using this material will appear here
                    </p>
                </div>
            </div>
        );
    }

    return (
        <div className='flex flex-wrap gap-4'>
            {memoizedData.map((product) => {
                return (
                    <div
                        key={product?._id}
                        className='grid w-[260px] max-w-[260px] grid-cols-3 gap-4 rounded-lg bg-white px-4 py-3 text-xs shadow'
                    >
                        <div className='relative aspect-square overflow-hidden rounded-lg'>
                            {isLoadingImages ? (
                                <div className='absolute inset-0 flex items-center justify-center bg-gray-50'>
                                    <Loader2 className='h-6 w-6 animate-spin text-gray-400' />
                                </div>
                            ) : (
                                <img
                                    src={
                                        productImages[product._id] || BlankImage
                                    }
                                    alt={product.name}
                                    className='h-full w-full object-cover'
                                />
                            )}
                        </div>

                        <div className='col-span-2 flex flex-col gap-1 text-xs'>
                            <span className='truncate text-xs font-bold'>
                                {product.name}
                            </span>

                            <span className='truncate font-light'>
                                {product?.category?.name ||
                                    product?.category?.code}
                            </span>

                            <span className='font-extralight'>
                                {product.description || '--'}
                            </span>
                        </div>
                    </div>
                );
            })}
        </div>
    );
}
