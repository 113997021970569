import { FileInput, FileUploader } from 'src/app/components-v2/file-dropzone';

interface FileUploadSectionProps {
    type: string;
    uploadedFiles: { [key: string]: { file: File; preview?: string }[] };
    onUpload: (files: FileList) => void;
}

export const FileUploadSection = ({
    type,
    uploadedFiles,
    onUpload,
}: FileUploadSectionProps) => (
    <FileUploader
        value={uploadedFiles[type]?.map((f) => f.file) || null}
        onValueChange={(files: File[] | null) => {
            if (files) {
                onUpload({
                    length: files.length,
                    item: (i) => files[i],
                    [Symbol.iterator]: function* () {
                        for (let i = 0; i < this.length; i++) {
                            yield this.item(i);
                        }
                    },
                } as FileList);
            }
        }}
        dropzoneOptions={{
            maxFiles: 5,
            maxSize: 10 * 1024 * 1024,
            accept: {
                'application/pdf': ['.pdf'],
                'image/*': ['.png', '.jpg', '.jpeg'],
                'application/msword': ['.doc'],
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                    ['.docx'],
            },
        }}
    >
        <FileInput className='text-red-500 hover:text-red-600 hover:underline'>
            Upload More
        </FileInput>
    </FileUploader>
);
