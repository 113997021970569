import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';
import { useCategoryService } from 'src/app/_api_adb2c/product/category/hooks/use-category-service';
import { useCreateProduct } from 'src/app/_api_adb2c/product/product/hooks/use-create-product';
import { useProductService } from 'src/app/_api_adb2c/product/product/hooks/use-product-service';
import { IndustryType } from 'src/app/_api_adb2c/product/product/models/product.model';
import { ProductUom } from 'src/app/_api_adb2c/purchase/purchase/enums/product-uom.enum';
import { useCreatePurchase } from 'src/app/_api_adb2c/purchase/purchase/hooks/use-create-purchase';
import { usePurchaseService } from 'src/app/_api_adb2c/purchase/purchase/hooks/use-purchase-service';
import { PurchaseModel } from 'src/app/_api_adb2c/purchase/purchase/models/purchase.model';
import { useSupplierService } from 'src/app/_api_adb2c/purchase/suppliers/hooks/use-supplier-service';
import { SupplierModel } from 'src/app/_api_adb2c/purchase/suppliers/models/supplier.model';
import { useSupplierConnect } from 'src/app/_api_adb2c/workspace/account/hooks/use-supplier-connect';
import { useSupplierInvite } from 'src/app/_api_adb2c/workspace/account/hooks/use-supplier-invite';
import { UserCreateDataObject } from 'src/app/_api_adb2c/workspace/users/user-create-data-object';
import { useWorkspaceService } from 'src/app/_api_adb2c/workspace/workspace/hooks/use-workspace-service';
import { WorkspaceModel } from 'src/app/_api_adb2c/workspace/workspace/workspace.model';
import { useContextStore } from 'src/app/stores/context-store';
import { read, utils } from 'xlsx';
import { useState } from 'react';
import { ProductCategoriesModel } from 'src/app/_api_adb2c/product/category/category.model';
import { useSearchService } from 'src/app/_api_adb2c/workspace/search/hooks/use-search-service';
import { useDelegationService } from 'src/app/_api_adb2c/workspace/delegations/hooks/use-delegation-service';
import countries from 'src/infrastructure/config/data/countries.json';

export interface ImportErrorMessage {
    rowNum: number;
    message: string;
}

export interface BaseImportHookResult {
    isLoading: boolean;
    findOrCreatePartner: (
        workspaceId: string,
        vendorName: string,
        data: any,
        workspace: WorkspaceModel,
        contactInfo?: {
            email: string;
            firstName: string;
            lastName: string;
        }
    ) => Promise<{
        workspaceId: string;
        supplierId: string;
    }>;
    findOrCreateCategory: (
        workspaceId: string,
        item: any,
        parentCode?: string,
        parentWorkspace?: string
    ) => Promise<ProductCategoriesModel>;
    findOrCreateProduct: (
        workspaceId: string,
        item: any,
        categoryId: string
    ) => Promise<any>;
    createPurchaseOrder: (
        workspaceId: string,
        supplierId: string,
        item: any,
        product: { id: string; cost: number },
        purchaseOrder: PurchaseModel,
        parentPurchase?: PurchaseModel
    ) => Promise<PurchaseModel>;
    processFile: <T>(
        file: File,
        sheetName: string,
        validateFn: (
            data: T[],
            errorMessages: ImportErrorMessage[]
        ) => Promise<void>,
        processFn: (
            data: T[],
            purchaseOrder: PurchaseModel,
            workspace: WorkspaceModel
        ) => Promise<void>,
        workspaceId: string,
        purchaseOrderId: string
    ) => Promise<T[]>;
    services: {
        workspaceService: ReturnType<typeof useWorkspaceService>['service'];
        purchaseService: ReturnType<typeof usePurchaseService>['service'];
        categoryService: ReturnType<typeof useCategoryService>['service'];
        productService: ReturnType<typeof useProductService>['service'];
        supplierService: ReturnType<typeof useSupplierService>['service'];
        searchService: ReturnType<typeof useSearchService>['service'];
        delegationService: ReturnType<typeof useDelegationService>['service'];
    };
    mutations: {
        createProduct: ReturnType<typeof useCreateProduct>['mutateAsync'];
        invite: ReturnType<typeof useSupplierInvite>['mutateAsync'];
        connect: ReturnType<typeof useSupplierConnect>['mutateAsync'];
        createPurchase: ReturnType<typeof useCreatePurchase>['mutateAsync'];
    };
    store: ReturnType<typeof useContextStore>;
    client: ReturnType<typeof useQueryClient>;
}

export function useImportBase(): BaseImportHookResult {
    const [isLoading, setIsLoading] = useState(false);

    const { service: workspaceService } = useWorkspaceService();
    const { service: purchaseService } = usePurchaseService();
    const { service: categoryService } = useCategoryService();
    const { service: productService } = useProductService();
    const { service: supplierService } = useSupplierService();
    const { service: searchService } = useSearchService();
    const { service: delegationService } = useDelegationService();

    const { mutateAsync: createProduct } = useCreateProduct();
    const { mutateAsync: invite } = useSupplierInvite();
    const { mutateAsync: connect } = useSupplierConnect();
    const { mutateAsync: createPurchase } = useCreatePurchase();

    const store = useContextStore();
    const client = useQueryClient();

    const delay = (duration: number) =>
        new Promise((resolve) => setTimeout(resolve, duration));

    const findPartner = async (
        workspaceId: string,
        name: string,
        registrationNumber: string
    ) => {
        const partners: SupplierModel[] = await supplierService.list(
            workspaceId,
            '1'
        );

        const existingPartner = partners.find((partner) => {
            return (
                partner.seller?.name?.toLocaleLowerCase() ===
                    name?.toLocaleLowerCase() ||
                partner.seller?.registrationNumber?.toLocaleLowerCase() ===
                    registrationNumber?.toLocaleLowerCase()
            );
        });

        return existingPartner
            ? {
                  ...existingPartner,
                  workspaceId: existingPartner.seller?._id || '',
                  supplierId: existingPartner._id || '',
              }
            : undefined;
    };

    const findOrCreatePartner = async (
        workspaceId: string,
        vendorName: string,
        data: any,
        parentWorkspace: WorkspaceModel,
        contactInfo?: {
            email: string;
            firstName: string;
            lastName: string;
        }
    ) => {
        const registrationNumber = data[
            'Factory License Number (Material Supplier)'
        ]
            ?.toString()
            ?.trim();

        const existingPartner = await findPartner(
            workspaceId,
            vendorName,
            registrationNumber
        );

        if (existingPartner) {
            return existingPartner;
        }

        // Create Partner
        const workspaces: WorkspaceModel[] =
            await workspaceService.searchByCompanyName(
                encodeURIComponent(vendorName)
            );

        let workspace = workspaces.find(
            (x) => x.company?.demographics.name === vendorName
        );

        if (!workspace) {
            const country = countries.find(
                (x) =>
                    x.name.toLocaleLowerCase() ===
                    data['Factory Country (Material Supplier)']
                        .toLocaleLowerCase()
                        ?.toString()
                        ?.trim()
            )?.['alpha-2'];

            workspace = await searchService.searchByRegistrationNumber(
                registrationNumber,
                country || ''
            );
        }

        if (workspace) {
            const delegation = await delegationService.list(workspaceId, '1');
            const isExist = delegation.find(
                (x) => x.delegatedTo._id === workspace?._id
            );

            if (!isExist) {
                await delegationService.create(
                    workspaceId,
                    {
                        delegatedTo: workspace._id,
                    },
                    '1'
                );
            }

            await connect({
                delegate: workspaceId,
                supplier: workspace._id,
                delegateId: workspaceId,
                diversion: '1',
            });
        } else {
            const contact: UserCreateDataObject[] = [];

            if (store.user) {
                contact.push({
                    contact: {
                        email: store.user.contact.email,
                        firstName: store.user.contact.firstName,
                        lastName: store.user.contact.lastName,
                        device: {},
                    },
                });
            }

            if (contactInfo?.email && contactInfo?.firstName) {
                contact.push({
                    contact: {
                        email: contactInfo.email,
                        firstName: contactInfo.firstName,
                        lastName: contactInfo.lastName,
                        device: {},
                    },
                });
            }

            const country = countries.find(
                (x) =>
                    x.name.toLocaleLowerCase() ===
                    data['Factory Country (Material Supplier)']
                        .toLocaleLowerCase()
                        ?.toString()
                        ?.trim()
            )?.['alpha-2'];

            await invite({
                body: {
                    company: data['Factory Name (Material Supplier)']
                        ?.toString()
                        ?.trim(),
                    registrationNumber,
                    country: country || '',
                    contact: contact.length > 0 ? contact : undefined,
                },
                delegateId: workspaceId,
                diversion: '1',
            });
        }

        await delay(2000);

        const partner = await findPartner(
            workspaceId,
            vendorName,
            registrationNumber
        );

        if (!partner) {
            throw new Error(`Failed to create partner: ${vendorName}`);
        }

        return partner;
    };

    const findOrCreateCategory = async (
        workspaceId: string,
        item: any,
        parentCode?: string,
        parentWorkspace?: string
    ) => {
        let parentCategory;
        let categoryName =
            item?.['Main Category [Optional]'] || 'Placeholder Category';

        if (parentCode && parentWorkspace) {
            const parentProduct = await productService.get(
                parentWorkspace,
                parentCode,
                '1'
            );

            if (parentProduct) {
                parentCategory = parentProduct?.category;
                categoryName = parentCategory?.name || categoryName;
            }
        }

        const existingCategories = await categoryService.search(
            workspaceId,
            encodeURIComponent(categoryName),
            '1'
        );

        let category: ProductCategoriesModel;

        if (existingCategories.length === 0) {
            category = await categoryService.create(
                workspaceId,
                {
                    code: categoryName,
                    defaultUnitOfMeasurement: ProductUom.PCS,
                    name: categoryName,
                    description: `Category created from template - ${categoryName}`,
                    industry: IndustryType.OTHERS,
                    workspace: workspaceId,
                },
                '1'
            );
        } else {
            category = existingCategories[0];
        }

        return category;
    };

    const findOrCreateProduct = async (
        workspaceId: string,
        item: any,
        categoryId: string
    ) => {
        const name = item['Product Name']?.toString()?.trim();
        let product = await productService.searchByName(workspaceId, name);

        if (!product) {
            const externalReferences = item['Item Number [Optional]']
                ? [
                      {
                          source: 'externalDataId',
                          value: item['Item Number [Optional]'],
                      },
                  ]
                : [];

            product = await createProduct({
                delegateId: workspaceId,
                body: {
                    category: categoryId,
                    externalReferences,
                    name,
                    description: '',
                    unitOfMeasurement: ProductUom.PCS,
                    loaderType: store.workspace?.supplyChainLoaderType || 0,
                    enabled: true,
                    tags: [],
                    workspace: workspaceId,
                    measureValue: 0,
                },
                diversion: '1',
            });

            await delay(2000);
        }

        return product;
    };

    const createPurchaseOrder = async (
        workspaceId: string,
        supplierId: string,
        item: any,
        product: { id: string; cost: number },
        purchaseOrder: PurchaseModel,
        parentPurchase?: PurchaseModel
    ) => {
        const purchase = await createPurchase({
            body: {
                supplier: supplierId,
                currency: parentPurchase?.currency || 'USD',
                items: [
                    {
                        product: product.id,
                        ppu: product.cost,
                        quantity: 1,
                        unit: ProductUom.PCS,
                    },
                ],
                rules: purchaseOrder.rules?.[0]
                    ? [purchaseOrder.rules?.[0]]
                    : [],
                shipToAddress: 'Filler Address',
                parent: parentPurchase?._id,
            },
            delegateId: workspaceId,
            diversion: '1',
        });

        return purchaseService.get(workspaceId, purchase.id, '1');
    };

    const processFile = async <T>(
        file: File,
        sheetName: string,
        validateFn: (
            data: T[],
            errorMessages: ImportErrorMessage[]
        ) => Promise<void>,
        processFn: (
            data: T[],
            purchaseOrder: PurchaseModel,
            workspace: WorkspaceModel
        ) => Promise<void>,
        workspaceId: string,
        purchaseOrderId: string
    ): Promise<T[]> => {
        return new Promise((resolve, reject) => {
            setIsLoading(true);
            const fileReader = new FileReader();

            fileReader.readAsArrayBuffer(file);

            fileReader.onload = async (e) => {
                try {
                    const buffer = e.target?.result as ArrayBuffer;
                    const wb = read(buffer, { type: 'buffer' });
                    const ws = wb.Sheets[sheetName];
                    const rawData: T[] = utils.sheet_to_json(ws);

                    // Filter out empty rows and add row numbers
                    const data = rawData
                        .filter((row: any) => {
                            // Check if row has any non-empty values
                            return Object.values(row).some(
                                (value) => value !== undefined && value !== ''
                            );
                        })
                        .map((row: any, index) => ({
                            ...row,
                            __rowNum__: index + 2, // Excel is 1-based and header row is 1
                        }));

                    const errorMessages: ImportErrorMessage[] = [];

                    const workspace = await workspaceService.get(
                        workspaceId,
                        '1'
                    );
                    const purchaseOrder = await purchaseService.get(
                        workspaceId,
                        purchaseOrderId,
                        '1'
                    );

                    await validateFn(data, errorMessages);

                    if (errorMessages.length > 0) {
                        reject(errorMessages);
                        return;
                    }

                    await processFn(data, purchaseOrder, workspace);

                    client.invalidateQueries({
                        queryKey: ['purchaseTrace'],
                    });

                    client.invalidateQueries({
                        queryKey: ['purchase'],
                    });

                    client.invalidateQueries({
                        queryKey: ['order'],
                    });

                    client.invalidateQueries({
                        queryKey: ['purchase-reverse'],
                    });

                    resolve(data);

                    toast.success(
                        'Successfully imported the template, please refresh the page'
                    );
                } catch (error) {
                    const err = error as Error;
                    toast.error(
                        `Failed to import the template: ${err.message}`
                    );
                    reject(err);
                } finally {
                    setIsLoading(false);
                }
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    return {
        isLoading,
        findOrCreatePartner,
        findOrCreateCategory,
        findOrCreateProduct,
        createPurchaseOrder,
        processFile,
        services: {
            workspaceService,
            purchaseService,
            categoryService,
            productService,
            supplierService,
            searchService,
            delegationService,
        },
        mutations: {
            createProduct,
            invite,
            connect,
            createPurchase,
        },
        store,
        client,
    };
}
