export function Legend() {
    return (
        <div className='absolute bottom-[14px] left-[14px] flex h-24 w-48 flex-col justify-around rounded-lg border bg-white px-4 py-2'>
            <span className='text-xs font-bold uppercase'>Legend</span>
            <div className='flex flex-col text-xs'>
                <div className='flex items-center gap-2'>
                    <div className='h-2 w-2 rounded-full bg-[#000]'></div>
                    <span>Component Orders</span>
                </div>
                <div className='flex items-center gap-2'>
                    <div className='h-2 w-2 rounded-full bg-[#007C77]'></div>
                    <span>Processing Orders</span>
                </div>
                <div className='flex items-center gap-2'>
                    <div className='h-2 w-2 rounded-full bg-[#FF9400]'></div>
                    <span>Outsourcing Orders</span>
                </div>
            </div>
        </div>
    );
}
