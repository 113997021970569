import { useState } from 'react';
import { toast } from 'sonner';
import { useMaterialService } from 'src/app/_api_adb2c/product/material/hooks/use-material-service';
import { useUpdateMaterial } from 'src/app/_api_adb2c/product/material/hooks/use-update-material';
import { MaterialModel } from 'src/app/_api_adb2c/product/material/models/material.model';
import {
    MaterialComposition,
    ProductMaterialDataObject,
} from 'src/app/_api_adb2c/product/material/request/product-material-data-object';
import { useSupplierService } from 'src/app/_api_adb2c/purchase/suppliers/hooks/use-supplier-service';
import { SupplierModel } from 'src/app/_api_adb2c/purchase/suppliers/models/supplier.model';
import { useSupplierInvite } from 'src/app/_api_adb2c/workspace/account/hooks/use-supplier-invite';
import { UserCreateDataObject } from 'src/app/_api_adb2c/workspace/users/user-create-data-object';
import { ImportErrorMessage } from 'src/app/pages/Purchases/Details/purchase-cascade-import-error';
import { useContextStore } from 'src/app/stores/context-store';
import { read, utils } from 'xlsx';
import countries from '../../../../../../infrastructure/config/data/countries.json';
import CompoundJson from 'src/infrastructure/config/data/compound.json';

interface SheetData {
    'Substance Name': string;
    'CAS Number': string;
    '% of Weight': number;
    'Is Man Made?': string;
    'Parent Substance Row': string;
    Name: string;
    Country: string;
    'Registration Number': string;
    __rowNum__: number;
    [key: string]: string | number;
}

const validateExcel = async (
    data: SheetData[],
    errorMessages: ImportErrorMessage[]
) => {
    const validationErrors: ImportErrorMessage[] = [];
    const requiredFields = ['% of Weight', 'Substance Name'];

    data.forEach((item) => {
        const isSubComposition = item['Parent Substance Row'];

        requiredFields.forEach((field) => {
            if (!item[field]) {
                validationErrors.push({
                    rowNum: item.__rowNum__,
                    message: `${field} is required`,
                });
            }
        });

        if (!isSubComposition && !item['Name']) {
            validationErrors.push({
                rowNum: item.__rowNum__,
                message: 'Supplier Name is required for substances.',
            });
        }

        if (!item['CAS Number'] && !item['Substance Name']) {
            validationErrors.push({
                rowNum: item.__rowNum__,
                message: 'Name / Cas Number is required for substances.',
            });
        }
    });

    if (validationErrors.length > 0) {
        errorMessages.push(...validationErrors);
    }
};

export function useImportSubstance() {
    const [isLoading, setIsLoading] = useState(false);

    const delay = (duration: number) =>
        new Promise((resolve) => setTimeout(resolve, duration));

    const { service: supplierService } = useSupplierService();
    const { service: materialService } = useMaterialService();

    const { mutateAsync: invite } = useSupplierInvite();
    const { mutateAsync: update } = useUpdateMaterial();

    const store = useContextStore();

    const findPartner = async (workspaceId: string, name: string) => {
        const partners: SupplierModel[] = await supplierService.list(
            workspaceId
        );

        const existingPartner = partners.find((partner) => {
            return (
                partner.seller.name.toLocaleLowerCase() ===
                name?.toLocaleLowerCase()
            );
        });

        const response = existingPartner
            ? {
                  ...existingPartner,
                  workspaceId: existingPartner?.seller?._id || '',
              }
            : undefined;

        return response;
    };

    const findOrCreateSupplier = async (
        workspaceId: string,
        item: SheetData
    ) => {
        let supplier = await findPartner(workspaceId, item.Name);

        if (!supplier) {
            // Run Invite Flow
            const contact: UserCreateDataObject[] = [];
            const countryCode = countries.find(
                (x) =>
                    x['alpha-2'].toLocaleLowerCase() ===
                    item.Country.toString()
                        .toLocaleLowerCase()
                        .toString()
                        .trim()
            )?.['alpha-2'];

            await invite({
                body: {
                    company: item.Name,
                    registrationNumber: item['Registration Number']?.toString(),
                    country: countryCode || '',
                    contact: contact.length > 0 ? contact : undefined,
                },
                delegateId: workspaceId,
            });

            await delay(1500);

            supplier = await findPartner(workspaceId, item.Name);
        }

        if (!supplier) {
            throw new Error(`Failed to create partner: ${item.Name}`);
        }

        return supplier;
    };

    const processExcel = async (
        data: SheetData[],
        material: MaterialModel,
        workspaceId: string
    ) => {
        const request: ProductMaterialDataObject = { ...material };
        const compositions: (MaterialComposition & { __rowNum__: number })[] =
            [];

        for (const item of data.filter((x) => !x['Parent Substance Row'])) {
            const supplier = await findOrCreateSupplier(workspaceId, item);
            const percentage = item['% of Weight'] * 100;
            const existingCompound = CompoundJson.find(
                (x) =>
                    x.name?.toLocaleLowerCase() ===
                        item?.['Substance Name']?.toLocaleLowerCase() ||
                    x.cas?.toLocaleLowerCase() ===
                        item?.['CAS Number']?.toLocaleLowerCase()
            );

            const composition: MaterialComposition & { __rowNum__: number } = {
                name: existingCompound
                    ? existingCompound.name
                    : item['Substance Name'],
                supplier: supplier.seller._id,
                substanceCode: existingCompound
                    ? existingCompound.cas
                    : item['CAS Number'],
                substanceName: existingCompound
                    ? existingCompound.name
                    : item['Substance Name'],
                isManMade: item['Is Man Made?'] === 'Yes',
                substanceRegCode: item['Registration Number'],
                sdsFileId: undefined,
                weight:
                    ((material.specifications?.weight || 0) * percentage) / 100,
                actualWeight:
                    ((material.specifications?.weight || 0) * percentage) / 100,
                subCompositions: [],
                __rowNum__: item.__rowNum__,
            };

            compositions.push(composition);
        }

        for (const item of data.filter((x) => x['Parent Substance Row'])) {
            const parentSubstance = compositions.find(
                (x) => x.__rowNum__ === Number(item['Parent Substance Row'])
            );

            const percentage = item['% of Weight'] * 100;
            const compound = CompoundJson.find(
                (x) =>
                    x.name?.toLocaleLowerCase() ===
                        item?.['Substance Name']?.toLocaleLowerCase() ||
                    x.cas?.toLocaleLowerCase() ===
                        item?.['CAS Number']?.toLocaleLowerCase()
            );

            if (!parentSubstance) {
                throw new Error(
                    `Parent substance not found for row ${item.__rowNum__}`
                );
            }

            parentSubstance.subCompositions?.push({
                name: compound ? compound.name : item['Substance Name'],
                substanceCode: compound ? compound.cas : item['CAS Number'],
                isManMade: item['Is Man Made?'] === 'Yes',
                weight: (parentSubstance.weight * percentage) / 100,
                actualWeight: (parentSubstance.actualWeight * percentage) / 100,
            });
        }

        request.compositions = compositions;

        await update({
            id: material._id,
            delegateId: workspaceId,
            body: request,
        });
    };

    const submit = async (
        materialId: string,
        file: File,
        workspaceId?: string
    ) => {
        const sheetName = 'BOS';

        return new Promise((resolve, reject) => {
            setIsLoading(true);
            const fileReader = new FileReader();

            fileReader.readAsArrayBuffer(file);

            fileReader.onload = async (e) => {
                try {
                    const buffer = e.target?.result as ArrayBuffer;
                    const wb = read(buffer, { type: 'buffer' });
                    const ws = wb.Sheets[sheetName];

                    const data: SheetData[] = utils.sheet_to_json(ws, {
                        range: 1,
                    });

                    const material: MaterialModel = await materialService.get(
                        workspaceId || store.workspace?._id || '',
                        materialId
                    );

                    const dataWithIndex = data.map((x, index) => ({
                        ...x,
                        __rowNum__: index + 3,
                    }));

                    const errorMessages: ImportErrorMessage[] = [];
                    await validateExcel(dataWithIndex, errorMessages);

                    if (errorMessages.length > 0) {
                        reject(errorMessages);

                        return;
                    }

                    await processExcel(
                        dataWithIndex,
                        material,
                        workspaceId || store.workspace?._id || ''
                    );

                    resolve([]);
                    toast.success(
                        'Successfully imported the template, please refresh the page'
                    );
                } catch (err) {
                    const error = err as Error;
                    toast.error(
                        `Failed to import the template: ${error.message}`
                    );

                    reject(err);
                } finally {
                    setIsLoading(false);
                }
            };

            fileReader.onerror = (error) => {
                setIsLoading(false);
                reject(error);
            };
        });
    };

    return { submit, isLoading };
}
