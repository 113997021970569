import { useImportBase } from './use-import-base';
import { PurchaseModel } from 'src/app/_api_adb2c/purchase/purchase/models/purchase.model';
import { WorkspaceModel } from 'src/app/_api_adb2c/workspace/workspace/workspace.model';
import { ImportErrorMessage } from './use-import-base';

export interface SheetData {
    'PO Factory Name': string;
    'Main Category [Optional]': string;
    'PO Number [Optional]': string;
    'Item Number [Optional]': string;
    'Product Name': string;
    'Company Role/Purchase Process for Factory (Material Supplier)': string;
    'Factory Name (Material Supplier)': string;
    'Factory License Number (Material Supplier)': string;
    'Factory Country (Material Supplier)': string;
    'Factory Address (Material Supplier)': string;
    'Factory Contact First Name (Material Supplier) [Optional]': string;
    'Factory Contact Last Name (Material Supplier) [Optional]': string;
    'Factory Contact Email (Material Supplier) [Optional]': string;
    'Upper Tier Row Number [Optional]': string;
    __rowNum__: string;
    children: SheetData[];
    [key: string]: string | SheetData[];
}

function flatMapChildren(item: SheetData) {
    const flatList: SheetData[] = [];

    function recurse(item: SheetData) {
        flatList.push(item);
        item.children.forEach((child) => recurse(child));
    }

    recurse(item);

    return flatList;
}

const SheetName = 'Supplier Template';

export const convertToNested = (
    data: SheetData[],
    errorMessages: ImportErrorMessage[]
) => {
    const result: SheetData[] = [];
    const lookup: { [key: string]: SheetData } = {};

    const dataWithoutUpperTier = data.filter(
        (x) => !x['Upper Tier Row Number [Optional]']
    );

    const upperTierData = data
        .filter((x) => x['Upper Tier Row Number [Optional]'])
        .sort((a, b) => {
            const aSum =
                Number(a['Upper Tier Row Number [Optional]']) +
                Number(a['__rowNum__']);
            const bSum =
                Number(b['Upper Tier Row Number [Optional]']) +
                Number(b['__rowNum__']);
            return aSum - bSum;
        });

    for (const item of dataWithoutUpperTier) {
        const exist =
            lookup[
                item['Factory Name (Material Supplier)']?.toString()?.trim()
            ];

        if (!exist) {
            lookup[
                item['Factory Name (Material Supplier)']?.toString()?.trim()
            ] = {
                ...item,
                children: [],
            };
        }

        if (!item['PO Factory Name']?.toString()?.trim()) {
            lookup[
                item['Factory Name (Material Supplier)']?.toString()?.trim()
            ] = {
                ...item,
                children: [],
            };
        }
    }

    for (const item of dataWithoutUpperTier) {
        if (!item['PO Factory Name']?.toString()?.trim()) {
            result.push(
                lookup[
                    item['Factory Name (Material Supplier)']?.toString()?.trim()
                ]
            );
            continue;
        }

        const parent = lookup[item['PO Factory Name']?.toString()?.trim()];
        if (parent) {
            const parentProcess =
                parent[
                    'Company Role/Purchase Process for Factory (Material Supplier)'
                ];
            const childProcess =
                item[
                    'Company Role/Purchase Process for Factory (Material Supplier)'
                ];

            if (
                parent['Factory Name (Material Supplier)'] ===
                    item['Factory Name (Material Supplier)'] &&
                parentProcess === childProcess
            ) {
                errorMessages.push({
                    rowNum: Number(item.__rowNum__) + 1,
                    message: `Circular reference detected: ${item['PO Factory Name']} -> ${item['Factory Name (Material Supplier)']}`,
                });
            }

            const existing = result.find(
                (x) =>
                    x[
                        'Factory Name (Material Supplier)'?.toString()?.trim()
                    ] === item['PO Factory Name'?.toString()?.trim()]
            );

            if (existing) {
                existing.children.push({
                    ...item,
                    __rowNum__: item.__rowNum__,
                    children: [],
                });
            } else {
                const children = result.flatMap((x) => flatMapChildren(x));

                const existingChild = children.find(
                    (x) =>
                        x['Factory Name (Material Supplier)']
                            ?.toString()
                            ?.trim() ===
                        item['PO Factory Name']?.toString()?.trim()
                );

                if (existingChild) {
                    existingChild.children.push({
                        ...item,
                        __rowNum__: item.__rowNum__,
                        children: [],
                    });
                }
            }
        }
    }

    const flattenedResults = result.flatMap((res) => flatMapChildren(res));

    for (const item of upperTierData) {
        const existing = flattenedResults.find(
            (x) => x.__rowNum__ === item['Upper Tier Row Number [Optional]']
        );

        if (existing) {
            existing.children.push({
                ...item,
                __rowNum__: item.__rowNum__,
                children: [],
            });
        } else {
            result.forEach((x) => {
                findAndUpdateUpperTierRow(x, item);
            });
        }
    }

    return result;
};

const findAndUpdateUpperTierRow = (data: SheetData, item: SheetData) => {
    data.children.forEach((x) => {
        if (x.__rowNum__ === item['Upper Tier Row Number [Optional]']) {
            x.children.push({
                ...item,
                children: [],
            });
        } else {
            findAndUpdateUpperTierRow(x, item);
        }
    });
};

export function useImportCascade() {
    const base = useImportBase();

    const validateExcel = async (
        data: SheetData[],
        errorMessages: ImportErrorMessage[]
    ) => {
        const validationErrors: ImportErrorMessage[] = [];

        data.forEach((item, index) => {
            const requiredFields = [
                'Factory Name (Material Supplier)',
                'Factory License Number (Material Supplier)',
                'Factory Country (Material Supplier)',
                'Factory Address (Material Supplier)',
                'Company Role/Purchase Process for Factory (Material Supplier)',
                'Product Name',
            ];

            requiredFields.forEach((field) => {
                if (!item[field]) {
                    validationErrors.push({
                        rowNum: index + 2,
                        message: `${field} is required`,
                    });
                }
            });
        });

        if (validationErrors.length > 0) {
            errorMessages.push(...validationErrors);
        }
    };

    const processExcel = async (
        data: SheetData[],
        purchaseOrder: PurchaseModel,
        workspace: WorkspaceModel
    ) => {
        const filteredData = data.filter(
            (x: SheetData) =>
                x['Factory Name (Material Supplier)'] || x['PO Factory Name']
        );

        const nestedData = convertToNested(filteredData, []);
        await processNestedData(nestedData, purchaseOrder, workspace);
    };

    const processNestedData = async (
        data: SheetData[],
        purchaseOrder: PurchaseModel,
        workspace: WorkspaceModel
    ) => {
        for (const item of data) {
            const factoryPartner = await base.findOrCreatePartner(
                purchaseOrder.supplier.seller._id,
                item['Factory Name (Material Supplier)']?.toString()?.trim(),
                item,
                workspace,
                {
                    email: item[
                        'Factory Contact Email (Material Supplier) [Optional]'
                    ],
                    firstName:
                        item[
                            'Factory Contact First Name (Material Supplier) [Optional]'
                        ],
                    lastName:
                        item[
                            'Factory Contact Last Name (Material Supplier) [Optional]'
                        ],
                }
            );

            const category = await base.findOrCreateCategory(
                purchaseOrder.supplier.seller._id,
                item
            );

            const product = await base.findOrCreateProduct(
                purchaseOrder.supplier.seller._id,
                item,
                category._id
            );

            const purchase = await base.createPurchaseOrder(
                purchaseOrder.supplier.seller._id,
                factoryPartner.workspaceId,
                item,
                {
                    id: product._id,
                    cost:
                        product.versions?.[product.versions.length - 1]
                            .costOfMaterials || 0,
                },
                purchaseOrder,
                purchaseOrder
            );

            if (item.children.length > 0) {
                await processNestedData(item.children, purchase, workspace);
            }
        }
    };

    const submit = (id: string, purchaseOrderId: string, file: File) => {
        return base.processFile<SheetData>(
            file,
            SheetName,
            validateExcel,
            processExcel,
            id,
            purchaseOrderId
        );
    };

    return { submit, isLoading: base.isLoading };
}
