import { ColumnDef } from '@tanstack/react-table';
import { useCallback, useMemo, useState } from 'react';
import { useUpdateRolePermissions } from 'src/app/_api_adb2c/workspace/roles/hooks/use-update-role-permissions';
import { PermissionGrantRequestObject } from 'src/app/_api_adb2c/workspace/roles/permission-grant-request-object';
import { WorkspaceRoleModel } from 'src/app/_api_adb2c/workspace/roles/roles.model';
import { BaseDialog } from 'src/app/components-v2/base-dialog';
import { ReusableTable } from 'src/app/pages/Purchases/reusable-table';
import {
    Accordion,
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
} from 'src/components/ui/accordion';
import { Checkbox } from 'src/components/ui/checkbox';
import DefinedRoles from './role.json';

const applicationNameMapping: Record<string, string> = {
    't4s-v2-workspace': 'Workspace',
    't4s-v2-sales': 'Sales',
    't4s-v2-purchases': 'Purchase',
    't4s-v2-products': 'Product',
    // Add more mappings here
};

interface Props {
    open: boolean;
    role: WorkspaceRoleModel;
    onClose: () => void;
}

export const EditRole = ({ open, role, onClose }: Props) => {
    const { mutateAsync: update, isLoading } = useUpdateRolePermissions();

    const [selectedPermissions, setSelectedPermissions] = useState<string[]>(
        () => {
            const initialPermissions = role?.permissions.map((p) => {
                if (p.feature === 'all' && p.scope === '*') {
                    return 'all:*';
                }

                const [application, objectCode] = p.feature.split('::');

                return `${application}:${objectCode}:${p.scope}`;
            });

            if (initialPermissions.includes('all:*')) {
                return DefinedRoles.map((p) => {
                    return `${p.application}:${p.objectCode}:${p.scope}`;
                });
            }

            return initialPermissions;
        }
    );

    const groupedPermissions = useMemo(() => {
        const grouped: Record<string, Record<string, string[]>> = {};
        DefinedRoles.forEach((permission) => {
            if (!grouped[permission.application]) {
                grouped[permission.application] = {};
            }
            if (!grouped[permission.application][permission.objectCode]) {
                grouped[permission.application][permission.objectCode] = [];
            }
            grouped[permission.application][permission.objectCode].push(
                permission.scope
            );
        });
        return grouped;
    }, []);

    const allScopes = useMemo(() => {
        const scopes = Array.from(
            new Set(
                Object.values(groupedPermissions)
                    .flatMap((objectCodes) => Object.values(objectCodes))
                    .flat()
            )
        );

        return ['All', ...scopes];
    }, [groupedPermissions]);

    const handlePermissionChange = (permissionKey: string) => {
        setSelectedPermissions((prev) =>
            prev.includes(permissionKey)
                ? prev.filter((p) => p !== permissionKey)
                : [...prev, permissionKey]
        );
    };

    const handleAllPermissionsChange = useCallback(
        (application: string, objectCode: string) => {
            const allPermissions = allScopes
                .slice(1)
                .map((scope) => `${application}:${objectCode}:${scope}`);

            setSelectedPermissions((prev) => {
                const allSelected = allPermissions.every((p) =>
                    prev.includes(p)
                );
                if (allSelected) {
                    return prev.filter((p) => !allPermissions.includes(p));
                } else {
                    return [...new Set([...prev, ...allPermissions])];
                }
            });
        },
        [allScopes, setSelectedPermissions]
    );

    const columns: ColumnDef<{ objectCode: string; scopes: string[] }>[] =
        useMemo(
            () => [
                {
                    accessorKey: 'objectCode',
                    header: 'Object',
                    cell: ({ row }) => (
                        <span className='font-medium capitalize'>
                            {row.original.objectCode}
                        </span>
                    ),
                },
                {
                    id: 'All',
                    header: 'All',
                    cell: ({ row }: any) => {
                        const allPermissions = allScopes
                            .slice(1)
                            .map(
                                (scope) =>
                                    `${row.original.application}:${row.original.objectCode}:${scope}`
                            );

                        const allSelected = allPermissions.every((p) =>
                            selectedPermissions.includes(p)
                        );

                        return (
                            <Checkbox
                                id={`${row.original.application}:${row.original.objectCode}:All`}
                                checked={allSelected}
                                onCheckedChange={() =>
                                    handleAllPermissionsChange(
                                        row.original.application,
                                        row.original.objectCode
                                    )
                                }
                            />
                        );
                    },
                },
                ...allScopes.slice(1).map((scope) => ({
                    id: scope,
                    header: scope,
                    cell: ({ row }: any) => {
                        return row.original.scopes.includes(scope) ? (
                            <Checkbox
                                id={`${row.original.application}:${row.original.objectCode}:${scope}`}
                                checked={selectedPermissions.includes(
                                    `${row.original.application}:${row.original.objectCode}:${scope}`
                                )}
                                onCheckedChange={() =>
                                    handlePermissionChange(
                                        `${row.original.application}:${row.original.objectCode}:${scope}`
                                    )
                                }
                            />
                        ) : null;
                    },
                })),
            ],
            [allScopes, selectedPermissions, handleAllPermissionsChange]
        );

    const reset = useCallback(() => {
        setSelectedPermissions([]);
        onClose();
    }, [onClose]);

    const handleSubmit = useCallback(async () => {
        const body: PermissionGrantRequestObject = {
            items: selectedPermissions.map((p) => {
                if (p === 'all:*') {
                    return {
                        application: 'all',
                        objectCode: 'all',
                        scope: '*',
                    };
                }

                const [application, objectCode, scope] = p.split(':');

                return {
                    application,
                    objectCode,
                    scope,
                };
            }),
        };

        await update({
            id: role._id,
            body,
        });

        reset();
    }, [selectedPermissions, reset, update, role._id]);

    return (
        <BaseDialog
            open={open}
            onClose={onClose}
            title='Edit Role Permissions'
            className='h-3/4 max-h-[75%] w-4/5 max-w-5xl overflow-y-auto'
            onConfirm={handleSubmit}
            isLoading={isLoading}
        >
            <Accordion
                type='single'
                className='w-full'
                defaultValue='t4s-v2-workspace'
            >
                {Object.entries(groupedPermissions).map(
                    ([application, objectCodes]) => (
                        <AccordionItem value={application} key={application}>
                            <AccordionTrigger>
                                {applicationNameMapping[application] ||
                                    application}
                            </AccordionTrigger>
                            <AccordionContent>
                                <ReusableTable
                                    columns={columns}
                                    headerClassName='bg-red-800 text-white capitalize'
                                    data={Object.entries(objectCodes).map(
                                        ([objectCode, scopes]) => {
                                            return {
                                                application,
                                                objectCode,
                                                scopes,
                                            };
                                        }
                                    )}
                                    className='w-full'
                                />
                            </AccordionContent>
                        </AccordionItem>
                    )
                )}
            </Accordion>
        </BaseDialog>
    );
};
