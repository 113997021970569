import { useFormContext } from 'react-hook-form';
import { Checkbox } from 'src/components/ui/checkbox';
import {
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from 'src/components/ui/form';
import { cn } from 'src/lib/utils';

interface CheckboxInputProps {
    label?: string;
    name: string;
    className?: string;
    onChange?: (value: boolean) => void;
}

export const CheckboxInput = ({
    label,
    name,
    className,
    onChange,
}: CheckboxInputProps) => {
    const { control } = useFormContext();

    return (
        <FormField
            name={name}
            control={control}
            render={({ field }) => {
                return (
                    <FormItem className={cn(className)}>
                        {label && <FormLabel>{label}</FormLabel>}
                        <FormControl>
                            <Checkbox
                                checked={field.value}
                                data-testid='checkbox-input'
                                onCheckedChange={(value) => {
                                    field.onChange(value);
                                    console.log(value);
                                    onChange?.(value as boolean);
                                }}
                            />
                        </FormControl>
                        <FormMessage />
                    </FormItem>
                );
            }}
        />
    );
};
