import { ColumnDef, Row } from '@tanstack/react-table';
import axios from 'axios';
import { CloudUpload } from 'lucide-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { FileCreateDataObject } from 'src/app/_api_adb2c/attachment/file-create-data-object';
import { useAttachmentUpload } from 'src/app/_api_adb2c/attachment/hooks/use-attachment-upload';
import { usePurchaseInformation } from 'src/app/_api_adb2c/purchase/purchase/hooks/use-purchase-information';
import { ManifestItemModel } from 'src/app/_api_adb2c/purchase/purchase/models/manifest-item.model';
import {
    PurchaseModel,
    PurchaseOrderTraceModel,
} from 'src/app/_api_adb2c/purchase/purchase/models/purchase.model';
import { useAttachSalesOrderFiles } from 'src/app/_api_adb2c/sales/sales/hooks/use-attach-sales-order-files';
import { SalesOrderModel } from 'src/app/_api_adb2c/sales/sales/models/sales-order.model';
import { SalesOrderAttachDataObject } from 'src/app/_api_adb2c/sales/sales/request/sales-order-attach-data-object';
import { SalesOrderDocumentDataObject } from 'src/app/_api_adb2c/sales/sales/request/sales-order-document-data-object';
import {
    SupportDocType,
    SupportDocTypeLabel,
} from 'src/app/_api_adb2c/shared/support-doc-type.enum';
import { FileModel } from 'src/app/_api_adb2c/workspace/file/file.model';
import { useFileService } from 'src/app/_api_adb2c/workspace/file/hooks/use-file-service';
import { WorkspaceModel } from 'src/app/_api_adb2c/workspace/workspace/workspace.model';
import { FileInput, FileUploader } from 'src/app/components-v2/file-dropzone';
import { TableV2 } from 'src/app/components-v2/table-v2';
import { useDownload } from 'src/app/pages/Materials/Details/Substance';
import { dropZoneConfig } from 'src/app/pages/Materials/Details/Substance/substance-sds-file-upload';
import { useContextStore } from 'src/app/stores/context-store';
import { cn } from 'src/app/utils/cn';
import { generateOrderTitle } from 'src/app/utils/generate-order-title';
import {
    Accordion,
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
} from 'src/components/ui/accordion';
import { getFileIdFromAssetName } from 'src/infrastructure/utils/extract-asset-id';
import {
    DocumentItem,
    DocumentViewer,
} from '../Documents/shared/document-viewer';

interface PurchaseDocumentsProps {
    purchaseId: string;
}

interface TieredPurchaseGroup {
    tier: number;
    purchases: PurchaseDocumentEntry[];
}

interface PurchaseDocumentEntry {
    tier: number;
    baseDocuments: DocumentRequirement[];
    additionalDocuments?: DocumentRequirement[];
    requiredDocuments: string[];
    purchase: PurchaseModel | PurchaseOrderTraceModel;
    salesOrder?: SalesOrderModel;
    workspace?: WorkspaceModel;
}

interface DocumentRequirement {
    document: string;
    processes: string[];
}

const isProcessRequired = (
    purchaseProcesses: string[],
    ruleProcesses: string[]
): boolean => {
    return purchaseProcesses.some((process) => ruleProcesses.includes(process));
};

const getRequiredDocuments = (
    processes: string[],
    rules: DocumentRequirement[]
): string[] => {
    return rules
        .filter((rule) => isProcessRequired(processes, rule.processes))
        .map((rule) => rule.document);
};

const isWorkspaceModel = (workspace: any): workspace is WorkspaceModel => {
    return workspace && typeof workspace === 'object' && 'company' in workspace;
};

const createPurchaseDocument = (
    purchase: PurchaseModel | PurchaseOrderTraceModel,
    tier: number,
    rules: DocumentRequirement[],
    salesOrders?: Record<string, SalesOrderModel>,
    workspaces?: Record<string, WorkspaceModel>
): PurchaseDocumentEntry => {
    const processes = purchase.processes;
    let workspace: WorkspaceModel | undefined;

    if (typeof purchase.workspace === 'string' && workspaces) {
        workspace = workspaces[purchase.workspace];
    } else if (isWorkspaceModel(purchase.workspace)) {
        workspace = purchase.workspace;
    }

    return {
        tier,
        baseDocuments: rules,
        requiredDocuments: getRequiredDocuments(processes, rules),
        purchase,
        salesOrder: salesOrders?.[purchase._id],
        workspace,
    };
};

const getTableColumns = (
    items: TieredPurchaseGroup
): ColumnDef<PurchaseDocumentEntry>[] => {
    const baseColumns: ColumnDef<PurchaseDocumentEntry>[] = [
        {
            id: 'purchaseId',
            header: 'Purchase Order',
            accessorFn: (row) => generateOrderTitle(row.purchase),
        },
        {
            id: 'buyer',
            header: 'Buyer',
            accessorFn: (row) => row.workspace?.company.demographics.name,
        },
        {
            id: 'supplier',
            header: 'Supplier',
            accessorFn: (row) => row.purchase.supplier.seller.name,
        },
        {
            id: 'company-documents',
            header: 'Company Documents',
            cell: ({ row }) => {
                if (!row.original.workspace) return null;
                return (
                    <PurchaseDocumentCompanyDocuments
                        workspace={row.original.workspace}
                    />
                );
            },
        },
        {
            id: 'product-documents',
            header: 'Product Documents',
            cell: ({ row }) => {
                return (
                    <PurchaseDocumentProductDocuments
                        purchase={row.original.purchase}
                    />
                );
            },
        },
    ];

    // Get all unique document types from both base and additional documents
    const allDocumentTypes = new Set<string>();
    items.purchases?.[0].baseDocuments.forEach((doc) =>
        allDocumentTypes.add(doc.document)
    );

    // Add document types from salesOrder documents that aren't in baseDocuments
    items.purchases?.forEach((purchase) => {
        purchase.salesOrder?.documents?.forEach((doc) => {
            if (
                doc.type &&
                !items.purchases[0].baseDocuments.some(
                    (base) => base.document === doc.type
                )
            ) {
                allDocumentTypes.add(doc.type);
            }
        });
    });

    const documentColumns = Array.from(allDocumentTypes).map(
        (documentType) => ({
            id: documentType,
            header: () =>
                SupportDocTypeLabel[documentType as SupportDocType] ||
                documentType,
            cell: ({ row }: { row: Row<PurchaseDocumentEntry> }) => {
                const isBaseDocument = row.original.baseDocuments.some(
                    (doc) => doc.document === documentType
                );
                return (
                    <PurchaseDocumentSupportDocuments
                        entry={row.original}
                        documentType={documentType}
                        isAdditional={!isBaseDocument}
                    />
                );
            },
        })
    );

    return [...baseColumns, ...documentColumns];
};

export function PurchaseDocumentsV2({ purchaseId }: PurchaseDocumentsProps) {
    const [searchParams] = useSearchParams();
    const delegateId = searchParams.get('delegateId') || '';

    const { data: purchaseInformation } = usePurchaseInformation(
        purchaseId,
        delegateId,
        !!purchaseId
    );

    const rules: DocumentRequirement[] = useMemo(() => {
        if (!purchaseInformation?.rulesets) return [];
        const rulesets = Object.values(purchaseInformation.rulesets);
        const rules = rulesets?.[0]?.rules;
        return (rules || [])?.map((rule) => ({
            document: rule.document,
            processes: rule.processes,
        }));
    }, [purchaseInformation]);

    const items: TieredPurchaseGroup[] = useMemo(() => {
        if (!purchaseInformation) return [];

        const { purchase, salesOrders, traces, workspaces } =
            purchaseInformation;
        const items: TieredPurchaseGroup[] = [];

        if (purchase) {
            items.push({
                tier: 1,
                purchases: [
                    createPurchaseDocument(
                        purchase,
                        0,
                        rules,
                        salesOrders,
                        workspaces
                    ),
                ],
            });
        }

        // Handle traces
        traces?.forEach((trace) => {
            const tierIndex = trace.depth + 2;
            const existingTier = items.find((item) => item.tier === tierIndex);

            const purchaseDoc = createPurchaseDocument(
                trace,
                tierIndex,
                rules,
                salesOrders,
                workspaces
            );

            if (existingTier) {
                existingTier.purchases.push(purchaseDoc);
            } else {
                items.push({
                    tier: tierIndex,
                    purchases: [purchaseDoc],
                });
            }
        });

        return items;
    }, [purchaseInformation, rules]);

    return (
        <div className='h-full w-full'>
            <Accordion type='multiple' className='flex h-full flex-col'>
                {items
                    .sort((a, b) => a.tier - b.tier)
                    .map((item, index) => (
                        <AccordionItem key={index} value={`tier-${index}`}>
                            <AccordionTrigger className='bg-gray-300 px-4 py-3'>
                                Tier {item.tier}
                            </AccordionTrigger>

                            <AccordionContent className='h-full bg-white px-4 py-2'>
                                <PurchaseDocumentTable items={item} />
                            </AccordionContent>
                        </AccordionItem>
                    ))}
            </Accordion>
        </div>
    );
}

export function PurchaseDocumentTable({
    items,
}: {
    items: TieredPurchaseGroup;
}) {
    const columns = useMemo(() => getTableColumns(items), [items]);

    return (
        <div>
            <TableV2
                columns={columns}
                data={items.purchases}
                fixedHeight='200px'
                tableHeaderClassName='bg-red-800 text-white whitespace-nowrap'
            />
        </div>
    );
}

export function PurchaseDocumentCompanyDocuments({
    workspace,
}: {
    workspace: WorkspaceModel;
}) {
    const { download } = useDownload();
    const documents = useMemo(() => {
        const docs: DocumentItem[] = [];

        workspace.library.extensions.forEach((extension, index) => {
            const document = workspace.library.documents?.[index];
            if (!document) return;

            docs.push({
                id: document.file,
                name: extension.name,
                type: 'company',
            });
        });

        return docs;
    }, [workspace]);

    return (
        <DocumentViewer
            emptyMessage='No documents uploaded for company.'
            sections={[
                {
                    title: 'Company Documents',
                    documents: documents,
                },
            ]}
            onDownload={(id: string, name: string) => download(id, name)}
        />
    );
}

interface PurchaseDocumentProductProps {
    purchase: PurchaseModel | PurchaseOrderTraceModel;
}

export function PurchaseDocumentProductDocuments({
    purchase,
}: PurchaseDocumentProductProps) {
    const [searchParams] = useSearchParams();
    const delegateId = searchParams.get('delegateId') || '';
    const context = useContextStore();
    const [documents, setDocuments] = useState<DocumentItem[]>([]);
    const { service: fileService } = useFileService();
    const { download } = useDownload();

    const fetchProductDocuments = useCallback(
        (items: ManifestItemModel): DocumentItem[] => {
            return (
                items.product?.documents?.map((doc) => {
                    const fileId = getFileIdFromAssetName(doc.file.assetName);
                    return {
                        id: fileId,
                        name: doc.file.originalName,
                        type: 'product',
                    };
                }) || []
            );
        },
        []
    );

    const fetchMaterialDocuments = useCallback(
        async (item: ManifestItemModel): Promise<DocumentItem[]> => {
            const docs: DocumentItem[] = [];
            const sdsFileIds: string[] = [];

            const version = item.version;
            const latestProduct = item.product?.versions?.find(
                (x) => x.version === version
            );

            latestProduct?.billOfMaterials?.forEach((bill) => {
                bill.material.compositions?.forEach((composition) => {
                    if (composition.sdsFileId) {
                        sdsFileIds.push(composition.sdsFileId);
                    }
                });
            });

            if (sdsFileIds.length > 0) {
                const sdsFiles: FileModel[] = await fileService.batchGet(
                    delegateId || context.workspace?._id || '',
                    sdsFileIds
                );

                latestProduct?.billOfMaterials?.forEach((bill) => {
                    // Regular material documents
                    bill.material.documents?.forEach((doc) => {
                        const fileId = getFileIdFromAssetName(
                            doc.file.assetName
                        );
                        docs.push({
                            id: fileId,
                            name: doc.file.originalName,
                            type: 'material',
                        });
                    });

                    // SDS documents
                    bill.material.compositions?.forEach((composition) => {
                        if (composition.sdsFileId) {
                            const existingFile = sdsFiles.find(
                                (file) => file._id === composition.sdsFileId
                            );
                            if (existingFile) {
                                docs.push({
                                    id: composition.sdsFileId,
                                    name:
                                        existingFile.name ||
                                        `SDS File for ${composition.name}`,
                                    type: 'material',
                                });
                            }
                        }
                    });
                });
            }

            return docs;
        },
        [fileService, delegateId, context.workspace?._id]
    );

    const getDocuments = useCallback(async () => {
        const latestVersion = purchase.versions[purchase.versions.length - 1];
        if (!latestVersion) {
            setDocuments([]);
            return;
        }

        const allDocs: DocumentItem[] = [];
        for (const manifest of latestVersion.manifest) {
            const productDocs = fetchProductDocuments(manifest);
            const materialDocs = await fetchMaterialDocuments(manifest);
            allDocs.push(...productDocs, ...materialDocs);
        }
        setDocuments(allDocs);
    }, [fetchProductDocuments, fetchMaterialDocuments, purchase]);

    useEffect(() => {
        getDocuments();
    }, [getDocuments]);

    const sections = useMemo(
        () => [
            {
                title: 'Product Documents',
                documents: documents.filter((doc) => doc.type === 'product'),
            },
            {
                title: 'Material Documents',
                documents: documents.filter((doc) => doc.type === 'material'),
            },
        ],
        [documents]
    );

    return (
        <DocumentViewer
            emptyMessage='No documents uploaded for products.'
            sections={sections}
            onDownload={(id: string, name: string) => download(id, name)}
        />
    );
}

interface DocumentUploadState {
    files: File[] | null;
    documentType: string;
}

export function PurchaseDocumentSupportDocuments({
    entry,
    documentType,
    isAdditional = false,
}: {
    entry: PurchaseDocumentEntry;
    documentType: string;
    isAdditional?: boolean;
}) {
    const context = useContextStore();
    const [uploadState, setUploadState] = useState<DocumentUploadState>({
        files: null,
        documentType: '',
    });

    const { mutateAsync: upload, isLoading: uploadLoading } =
        useAttachmentUpload();
    const { mutateAsync: attach, isLoading: attachLoading } =
        useAttachSalesOrderFiles();
    const { download } = useDownload();

    const documents = useMemo(() => {
        const docs: DocumentItem[] = [];

        // Add existing uploaded documents
        entry.salesOrder?.documents?.forEach((doc) => {
            if (doc.type === documentType) {
                docs.push({
                    id: getFileIdFromAssetName(doc.file.assetName),
                    name: doc.file.originalName,
                    type: doc.type,
                });
            }
        });

        // Add pending files
        uploadState.files?.forEach((file) => {
            docs.push({
                id: `pending-${file.name}`,
                name: file.name,
                type: documentType,
                isPending: true,
            });
        });

        return docs;
    }, [entry.salesOrder, documentType, uploadState.files]);

    const remove = useCallback(
        async (docId: string) => {
            // If it's a pending file
            if (docId.startsWith('pending-')) {
                const fileName = docId.replace('pending-', '');
                const newFiles =
                    uploadState.files?.filter((f) => f.name !== fileName) ||
                    null;
                setUploadState((prev) => ({ ...prev, files: newFiles }));
                return;
            }

            // If it's an uploaded file
            if (!entry.salesOrder?._id) return;

            const filteredDocuments =
                entry.salesOrder.documents?.filter(
                    (doc) =>
                        getFileIdFromAssetName(doc.file.assetName) !== docId
                ) || [];

            const request: SalesOrderAttachDataObject = {
                documents: filteredDocuments,
            };

            await attach({
                id: entry.salesOrder._id,
                body: request,
                delegateId: entry.salesOrder.workspace,
                diversion: '1',
            });
        },
        [entry.salesOrder, attach, uploadState.files]
    );

    const submit = useCallback(async () => {
        if (!uploadState.files || !entry.salesOrder?._id) return;

        const documents: SalesOrderDocumentDataObject[] =
            entry.salesOrder.documents || [];
        const workspaceId = entry.workspace?._id || context.workspace?._id;
        const delegateId = entry.salesOrder?.workspace || workspaceId;

        if (!workspaceId || !delegateId) return;

        await Promise.all(
            uploadState.files.map(async (file) => {
                const request: FileCreateDataObject = {
                    contentType: file.type,
                    size: file.size,
                    name: file.name,
                    genre: 'temporary',
                    uploadedBy: context.user?._id || '',
                    workspace: workspaceId,
                };

                const token = await upload({
                    file: request,
                    assetType: 'salesOrder',
                    diversion: '1',
                    delegateId,
                });

                documents.push({
                    file: {
                        assetName: token.attachment.assetName,
                        autoResign: token.attachment.autoResign,
                        container: token.attachment.container,
                        contentType: token.attachment.contentType,
                        extension: file.name.split('.').pop() || '',
                        originalName: token.attachment.originalName,
                        readReleaseTime: token.attachment.readReleaseTime,
                        size: file.size,
                    },
                    type: documentType as SupportDocType,
                });

                await axios.put(token.token, file, {
                    headers: {
                        'Content-Type': file.type,
                        'x-ms-blob-type': 'BlockBlob',
                    },
                });
            })
        );

        await attach({
            id: entry.salesOrder._id,
            body: { documents },
            delegateId,
            diversion: '1',
        });

        setUploadState({ files: null, documentType: '' });
    }, [uploadState, entry, upload, attach, context, documentType]);

    const isRequired =
        !isAdditional && entry.requiredDocuments.includes(documentType);
    const docType = documentType as SupportDocType;
    const label = SupportDocTypeLabel[docType] || documentType;
    const hasDocuments = documents.length > 0;

    return (
        <DocumentViewer
            emptyMessage={`No ${label} uploaded. (Max 4MB)`}
            trigger={
                <span
                    className={cn(
                        'cursor-pointer text-xs hover:underline hover:underline-offset-2',
                        {
                            'text-green-600': hasDocuments,
                            'text-red-600': !hasDocuments && isRequired,
                            'text-gray-400': !hasDocuments && !isRequired,
                        }
                    )}
                >
                    Upload
                </span>
            }
            sections={[
                {
                    title: label,
                    documents: documents,
                    isRequired,
                    actions: (
                        <div className='flex items-center justify-between border-t pt-2'>
                            <FileUploader
                                value={uploadState.files}
                                onValueChange={(files) =>
                                    setUploadState({ files, documentType })
                                }
                                dropzoneOptions={dropZoneConfig}
                            >
                                <FileInput>
                                    <div className='flex cursor-pointer items-center gap-1 text-blue-400 hover:underline hover:underline-offset-2'>
                                        <CloudUpload size={16} />
                                        <span>Upload</span>
                                    </div>
                                </FileInput>
                            </FileUploader>
                            {uploadState.files && (
                                <button
                                    onClick={submit}
                                    disabled={uploadLoading || attachLoading}
                                    className='flex items-center gap-2 text-blue-400 hover:underline'
                                >
                                    {uploadLoading || attachLoading
                                        ? 'Submitting...'
                                        : 'Submit'}
                                </button>
                            )}
                        </div>
                    ),
                },
            ]}
            onDownload={(id: string, name: string) =>
                !id.startsWith('pending-') && download(id, name)
            }
            onDelete={remove}
        />
    );
}
