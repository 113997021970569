import { useAttachmentDownload } from '../_api_adb2c/attachment/hooks/use-attachment-download';
import JSZip from 'jszip';
import { FileGetDataObject } from '../_api_adb2c/attachment/file-get-data-object';
import axios from 'axios';
import saveAs from 'file-saver';
import { DateTime } from 'luxon';
import { toast } from 'sonner';
import { useCallback } from 'react';

interface DownloadOptions {
    delegateId?: string;
    diversion?: string;
    fileName?: string;
}

export const useFileDownloader = () => {
    const { mutateAsync: downloadAttachment } = useAttachmentDownload();

    const getTimestampedFileName = (fileName: string) => {
        const timestamp = DateTime.now().toFormat('yyyy-MM-dd-HHmmss');
        const extension = fileName.endsWith('.zip') ? '' : '.zip';
        // Remove .zip if it exists in the original filename to avoid double extensions
        const baseName = fileName.replace(/\.zip$/, '');
        return `${baseName}_${timestamp}${extension}`;
    };

    const getDownloadTokens = useCallback(
        async (
            fileIds: string[],
            options?: Omit<DownloadOptions, 'fileName'>
        ) => {
            try {
                if (!fileIds.length) {
                    return [];
                }

                if (fileIds.length === 1) {
                    const response = await downloadAttachment({
                        delegateId: options?.delegateId,
                        diversion: options?.diversion || '1',
                        body: {
                            id: fileIds[0],
                        } as FileGetDataObject,
                    });
                    return [response];
                }

                const responses = await Promise.all(
                    fileIds.map(async (fileId) => {
                        try {
                            return await downloadAttachment({
                                delegateId: options?.delegateId,
                                diversion: options?.diversion || '1',
                                body: {
                                    id: fileId,
                                } as FileGetDataObject,
                            });
                        } catch (error) {
                            console.error(
                                `Error getting token for file ${fileId}:`,
                                error
                            );
                            return null;
                        }
                    })
                );

                return responses.filter(Boolean);
            } catch (error) {
                console.error('Error getting download tokens:', error);
                toast.error('An error occurred while getting file tokens');
                return [];
            }
        },
        [downloadAttachment]
    );

    const downloadFiles = useCallback(
        async (fileIds: string[], options?: DownloadOptions) => {
            try {
                if (!fileIds.length) {
                    toast.warning('No files selected for download');
                    return;
                }

                // If single file, download directly
                if (fileIds.length === 1) {
                    try {
                        const response = await downloadAttachment({
                            delegateId: options?.delegateId,
                            diversion: options?.diversion || '1',
                            body: {
                                id: fileIds[0],
                            } as FileGetDataObject,
                        });

                        const blob = await axios.get(response.token, {
                            responseType: 'blob',
                        });

                        saveAs(
                            blob.data,
                            options?.fileName ||
                                response.attachment.originalName ||
                                'download'
                        );
                    } catch (error) {
                        toast.error(
                            'Unable to download file. It may have been deleted or you may not have permission to access it.'
                        );
                        console.error('Single file download error:', error);
                        return;
                    }

                    return;
                }

                // For multiple files, create a zip
                const zip = new JSZip();
                let successCount = 0;
                let failureCount = 0;

                // Download all files
                const downloads = await Promise.allSettled(
                    fileIds.map(async (fileId) => {
                        try {
                            const token = await downloadAttachment({
                                delegateId: options?.delegateId,
                                diversion: options?.diversion || '1',
                                body: {
                                    id: fileId,
                                } as FileGetDataObject,
                            });

                            const response = await axios.get(token.token, {
                                responseType: 'blob',
                            });

                            successCount++;
                            return {
                                data: response.data,
                                name: token.attachment.originalName,
                            };
                        } catch (error) {
                            failureCount++;
                            console.error(
                                `Error downloading file ${fileId}:`,
                                error
                            );
                            return null;
                        }
                    })
                );

                // Add successfully downloaded files to zip
                downloads.forEach((result, index) => {
                    if (result.status === 'fulfilled' && result.value) {
                        zip.file(
                            result.value.name || `file-${index + 1}`,
                            result.value.data
                        );
                    }
                });

                // Only create zip if we have at least one successful download
                if (successCount > 0) {
                    const zipBlob = await zip.generateAsync({ type: 'blob' });
                    const zipFileName = getTimestampedFileName(
                        options?.fileName || 'downloads.zip'
                    );
                    saveAs(zipBlob, zipFileName);

                    // Show status message
                    if (failureCount > 0) {
                        toast.warning(
                            `Downloaded ${successCount} files. ${failureCount} files could not be downloaded.`
                        );
                    } else {
                        toast.success(
                            `Successfully downloaded ${successCount} files`
                        );
                    }
                } else {
                    toast.error(
                        'None of the selected files could be downloaded'
                    );
                }
            } catch (error) {
                toast.error('An error occurred while downloading files');
                console.error('File download error:', error);
            }
        },
        [downloadAttachment]
    );

    return {
        downloadFiles,
        getDownloadTokens,
    };
};
