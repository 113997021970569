import { Trash } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import ReactFlow, {
    Background,
    ControlButton,
    Controls,
    useEdgesState,
    useNodesState,
} from 'reactflow';
import { useProduct } from 'src/app/_api_adb2c/product/product/hooks/use-product';
import { usePurchaseTrace } from 'src/app/_api_adb2c/purchase/purchase/hooks/use-purchase-trace';
import {
    PurchaseModel,
    PurchaseOrderTraceModel,
} from 'src/app/_api_adb2c/purchase/purchase/models/purchase.model';
import { useVirtualRoot } from 'src/app/_api_adb2c/purchase/virtual-purchase/hooks/use-virtual-root';
import { TooltipWrapper } from 'src/app/components/TooltipWrapper';
import {
    AddPurchase,
    CascadeNodeProps,
} from 'src/app/pages/Purchases/add-purchase';
import { useContextStore } from 'src/app/stores/context-store';
import { useEdgeBuilder } from '../../../Purchases/DetailsV2/CascadeV2/hooks/useEdgeBuilder';
import { useNodeBuilder } from '../../../Purchases/DetailsV2/CascadeV2/hooks/useNodeBuilder';
import { PurchaseClearCascade } from '../../../Purchases/DetailsV2/CascadeV2/modals/purchase-clear-cascade';
import { nodeTypes } from '../../../Purchases/DetailsV2/CascadeV2/types';
import { ImportProductCascade } from './import-product-cascade';

interface Props {
    productId: string;
}

export const getExternalDataId = (
    order: PurchaseModel | PurchaseOrderTraceModel
) => {
    return (
        order?.reference?.find((ref) => ref.source === 'externalDataId')
            ?.value || ''
    );
};

export function ProductTraceMap({ productId }: Props) {
    const [searchParams] = useSearchParams();
    const delegateId = searchParams.get('delegateId') || '';
    const context = useContextStore();

    const isApparelMode =
        (context.workspace?.features.supplyChainLoaderType || 0) === 0;
    const [mode] = useState(false);
    const [cascadeInfo, setCascadeInfo] = useState<CascadeNodeProps>();
    const [action, setAction] = useState<'create' | 'initialise' | 'import'>();
    const [isDelete, setIsDelete] = useState(false);

    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);

    const { data: product } = useProduct(productId, delegateId);
    const { data: order } = useVirtualRoot(productId, delegateId);
    const { data: traces } = usePurchaseTrace(order?._id || '', delegateId);

    useEffect(() => {
        if (cascadeInfo) {
            setAction('initialise');
        }
    }, [cascadeInfo]);

    const { buildNodes } = useNodeBuilder({
        isApparelMode,
        supplierMode: mode,
        purchaseCottonWeight: undefined,
        allowCreate: false,
    });

    const { buildEdges } = useEdgeBuilder();

    useEffect(() => {
        if (order && traces) {
            const newNodes = buildNodes(order, traces);
            setNodes(newNodes);
        }
    }, [order, traces, buildNodes, setNodes]);

    useEffect(() => {
        if (order && traces) {
            const newEdges = buildEdges(order, traces);
            setEdges(newEdges);
        }
    }, [order, traces, buildEdges, setEdges]);

    return (
        <>
            <ReactFlow
                proOptions={{ hideAttribution: true }}
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                nodeTypes={nodeTypes}
                defaultViewport={{ zoom: 0.8, x: 0, y: 0 }}
                minZoom={0.2}
                maxZoom={1.5}
                className='bg-gray-50'
            >
                <Background />
                <Controls showInteractive={false}>
                    <ControlButton
                        onClick={() => {
                            setIsDelete(true);
                        }}
                    >
                        <TooltipWrapper label='Clear Cascade'>
                            <Trash />
                        </TooltipWrapper>
                    </ControlButton>
                </Controls>
            </ReactFlow>

            {action === 'initialise' && (
                <AddPurchase
                    product={product}
                    open={action === 'initialise'}
                    onClose={() => {
                        setAction(undefined);
                        setCascadeInfo(undefined);
                    }}
                    cascade={cascadeInfo}
                />
            )}

            {action === 'import' && (
                <ImportProductCascade
                    open={action === 'import'}
                    order={order}
                    onClose={() => {
                        setAction(undefined);
                    }}
                />
            )}

            {isDelete && (
                <PurchaseClearCascade
                    open={isDelete}
                    onClose={() => setIsDelete(false)}
                    purchase={order?._id || ''}
                />
            )}
        </>
    );
}
