import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { usePurchase } from 'src/app/_api_adb2c/purchase/purchase/hooks/use-purchase';
import { useUpdatePurchase } from 'src/app/_api_adb2c/purchase/purchase/hooks/use-update-purchase';
import { PurchaseOrderUpdateDataObject } from 'src/app/_api_adb2c/purchase/purchase/requests/purchase-order-update-data-object';
import { DialogV2 } from 'src/app/components-v2/dialog-v2';
import { z } from 'zod';
import { AddPurchaseDocumentRequirements } from '../add-purchase-document-requirements';
import { AddPurchaseItemFields } from '../add-purchase-item-fields';
import { EditPurchaseRequiredFields } from './edit-purchase-required-fields';

interface Props {
    purchaseId: string;
    open: boolean;
    onClose: () => void;
}

export const purchaseOrderItemDataObjectSchema = z.object({
    product: z.string(),
    ppu: z.coerce.number(),
    quantity: z.coerce.number(),
    unit: z.string(),
    sequence: z.coerce.number().optional(),
});

const schema = z.object({
    currency: z.string(),
    externalDataId: z.string().optional(),
    items: z.array(purchaseOrderItemDataObjectSchema),
    rules: z.string().optional(),
    shippedOn: z.array(z.date()).optional(),
    shipToAddress: z.string().optional(),
    purchaseProcesses: z.array(z.string()).optional(),
    orderNature: z.string().optional(),
    delegateId: z.string().optional(),
});

export function EditPurchase({ purchaseId, open, onClose }: Props) {
    const [searchParams] = useSearchParams();
    const delegateId = searchParams.get('delegateId') || '';

    const { data: purchase } = usePurchase(purchaseId, delegateId);

    const { mutateAsync: update, isLoading } = useUpdatePurchase();

    const initialItems = useMemo(
        () =>
            purchase?.versions?.[purchase?.versions?.length - 1]?.manifest.map(
                (x) => ({
                    product: x.product._id,
                    ppu: x.ppu,
                    quantity: x.quantity,
                    unit: x.product.unitOfMeasurement,
                })
            ) || [],
        [purchase]
    );

    const form = useForm<z.infer<typeof schema>>({
        mode: 'onChange',
        resolver: zodResolver(schema),
        defaultValues: {
            currency: purchase?.currency,
            orderNature: purchase?.nature ? purchase.nature : undefined,
            rules: purchase?.rules?.[0],
            purchaseProcesses: purchase?.processes || [],
            externalDataId: purchase?.reference.find(
                (x) => x.source === 'externalDataId'
            )?.value,
            items: initialItems,
            shipToAddress: purchase?.shipToAddress,
        },
    });

    useEffect(() => {
        if (!open && purchase) {
            form.reset(
                {
                    currency: purchase.currency,
                    orderNature: purchase.nature ? purchase.nature : undefined,
                    rules: purchase.rules?.[0],
                    purchaseProcesses: purchase.processes || [],
                    items: initialItems,
                },
                {
                    keepDefaultValues: true,
                }
            );
        }
    }, [open, purchase, form, initialItems]);

    const onSubmit = async (data: z.infer<typeof schema>) => {
        const request: PurchaseOrderUpdateDataObject = {
            currency: data.currency,
            nature: data.orderNature ? data.orderNature : undefined,
            rules: data.rules ? [data.rules] : [],
            processes: data.purchaseProcesses,
            items: data.items,
        };

        await update({
            id: purchaseId,
            body: request,
            delegateId,
        });

        onClose();
    };

    const handleClose = () => {
        onClose();
    };

    if (!purchase) return <></>;

    return (
        <DialogV2
            open={open}
            onClose={handleClose}
            form={form}
            onSubmit={onSubmit}
            title='Edit Purchase Order'
            isLoading={isLoading}
            isStepDialog
            size='lg'
            steps={[
                {
                    title: 'Basic Information',
                    description:
                        'Enter the basic information for this purchase order.',
                    content: <EditPurchaseRequiredFields />,
                },
                {
                    title: 'Items',
                    description: 'Add the items for this purchase order.',
                    content: <AddPurchaseItemFields />,
                },
                {
                    title: 'Purchase Ruleset',
                    description:
                        'Add the purchase rules for this purchase order.',
                    content: <AddPurchaseDocumentRequirements />,
                },
            ]}
        ></DialogV2>
    );
}
