import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import LfCollapsedLogo from 'src/assets/lf-logo-collapsed.jpg';
import Logo from 'src/assets/lf-logo.png';
import { ScrollArea } from 'src/components/ui/scroll-area';
import { cn } from 'src/lib/utils';
import { useUserWorkspaces } from '../../_api_adb2c/workspace/users/hooks/use-user-workspaces';
import { useContextStore } from '../../stores/context-store';
import { useSidebarStore } from '../../stores/sidebar-store';
import { Select } from '../select';
import { SidebarToggle } from './siderbar-toggle';
import { ExpandedMenu } from './expanded-menu';
import { CollapsedMenu } from './collapsed-menu';

export function SiderV2() {
    const navigate = useNavigate();
    // To be updated if we want to use the collapsed sidebar
    const { open, setOpen } = useSidebarStore();

    const [currentWorkspace, setCurrentWorkspace] = useState('');
    const { workspace, setWorkspace } = useContextStore();
    const prevWorkspaceRef = useRef(workspace?._id);

    const { data } = useUserWorkspaces();

    useEffect(() => {
        const existingWorkspace = data?.find((x) => x._id === currentWorkspace);
        if (existingWorkspace) {
            setWorkspace(existingWorkspace);
            // Only navigate if workspace actually changed
            if (prevWorkspaceRef.current !== existingWorkspace._id) {
                navigate('/');
                prevWorkspaceRef.current = existingWorkspace._id;
            }
        }
    }, [currentWorkspace, data, setWorkspace, navigate]);

    useEffect(() => {
        setCurrentWorkspace(workspace?._id || '');
    }, [workspace]);

    return (
        <aside
            className={cn(
                'fixed left-0 top-0 z-20 h-screen bg-white transition-[width] duration-300 ease-in-out',
                open === false ? 'w-[90px]' : 'w-60'
            )}
        >
            <SidebarToggle isOpen={open} setIsOpen={() => setOpen(!open)} />

            <div className='relative flex h-full flex-col overflow-y-auto px-3 py-4 shadow-md dark:shadow-zinc-800'>
                <div className='flex flex-col'>
                    <div
                        className={cn(
                            'transition-all duration-300 ease-in-out',
                            open ? 'opacity-100' : 'h-0 scale-0 opacity-0'
                        )}
                    >
                        <img
                            className={cn('w-4/5')}
                            alt='Application Logo'
                            src={Logo}
                        />
                        <Select
                            value={currentWorkspace}
                            setValue={(value) => {
                                setCurrentWorkspace(value);
                            }}
                            label='Workspace'
                            options={(data || []).map((x) => ({
                                label: x.company.demographics.name,
                                value: x._id,
                            }))}
                        />
                    </div>

                    <div
                        className={cn(
                            'transition-all duration-300 ease-in-out',
                            !open ? 'opacity-100' : 'h-0 scale-0 opacity-0'
                        )}
                    >
                        <img
                            className={cn('m-auto h-8 w-8 items-center')}
                            alt='Application Logo'
                            src={LfCollapsedLogo}
                        />
                    </div>
                </div>

                <ScrollArea className='mt-8 flex h-full w-full flex-col justify-between'>
                    {open ? <ExpandedMenu /> : <CollapsedMenu />}
                </ScrollArea>
            </div>
        </aside>
    );
}
