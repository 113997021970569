import { useCallback } from 'react';
import { Node } from 'reactflow';
import { PurchaseOrderNature } from 'src/app/_api_adb2c/purchase/purchase/enums/purchase-order-nature.enum';
import {
    PurchaseModel,
    PurchaseOrderTraceModel,
} from 'src/app/_api_adb2c/purchase/purchase/models/purchase.model';
import { getSupplyChainNodeTypeLabel } from 'src/app/_api_adb2c/workspace/shared/enum/supply-chain-node-type.enum';
import ApparelGroup from '../apparel-grouping.json';
import { CascadeNodeType, CustomOrderNatureLabel } from '../types';

interface NodeBuilderProps {
    isApparelMode: boolean;
    supplierMode: boolean;
    purchaseCottonWeight?: any;
    allowCreate?: boolean;
}

const DEFAULT_GROUP_HEIGHT = 200;
const DEFAULT_NODE_HEIGHT = 140;
const DEFAULT_APPAREL_GROUP_HEIGHT = 210;

const DEFAULT_X_SPACING = 350;
const DEFAULT_Y_OFFSET = 50;
const DEFAULT_X_OFFSET = 10;

const DEFAULT_NODE_X_OFFSET = 10;
const DEFAULT_NODE_Y_OFFSET = 50;
const DEFAULT_NODE_Y_SPACING = 60;

const DEFAULT_PROCESS_Y_SPACING = 80;

export function findMatchingValues(
    arrayA: string[],
    arrayB: string[]
): string[] {
    return arrayA.filter((valueA) => arrayB.includes(valueA));
}

export function findFirstMatch(
    arrayA: string[],
    arrayB: string[]
): string | undefined {
    return arrayA.find((valueA) => arrayB.includes(valueA));
}

export function useNodeBuilder({
    isApparelMode,
    supplierMode,
    purchaseCottonWeight,
    allowCreate = true,
}: NodeBuilderProps) {
    const getApparelGroup = useCallback((processes: string[]) => {
        if (!processes?.length) return undefined;
        const formattedProcesses = processes.map((x) => x.toUpperCase().trim());
        return ApparelGroup.find((x) =>
            x.processes.some((y) => formattedProcesses.includes(y))
        );
    }, []);

    const getMatchingProcess = useCallback(
        (processes: string[]) => {
            if (!processes?.length) return undefined;
            const apparelGroup = getApparelGroup(processes);
            const parsedProcesses = processes.map((x) => x.toUpperCase());

            return apparelGroup?.processes.find((process) =>
                parsedProcesses.includes(process)
            );
        },
        [getApparelGroup]
    );

    const createParentNode = useCallback(
        (purchase: PurchaseModel) => {
            const apparelGroup = getApparelGroup(purchase.processes);
            const matchingGroup = getMatchingProcess(purchase.processes);
            const nodes: Node[] = [];

            if (isApparelMode) {
                nodes.push({
                    id: `apparel-group-${purchase._id}`,
                    type: CascadeNodeType.APPAREL_GROUP,
                    position: { x: DEFAULT_X_OFFSET, y: DEFAULT_Y_OFFSET },
                    data: {
                        height: DEFAULT_APPAREL_GROUP_HEIGHT + 50,
                        label: apparelGroup ? apparelGroup.name : 'Others',
                    },
                });
            }

            const groupProcess = matchingGroup || purchase.processes?.[0];
            const label = getSupplyChainNodeTypeLabel(
                groupProcess?.trim() || 'No Process'
            );

            nodes.push({
                id: `group-${purchase._id}`,
                type: CascadeNodeType.NODE_GROUP,
                position: { x: DEFAULT_X_OFFSET, y: DEFAULT_Y_OFFSET },
                parentNode: isApparelMode
                    ? `apparel-group-${purchase._id}`
                    : undefined,
                data: {
                    height: DEFAULT_GROUP_HEIGHT,
                    label: label,
                    className: 'bg-white',
                    isApparelMode,
                },
            });

            nodes.push({
                id: `node-${purchase._id}`,
                type: CascadeNodeType.NODE,
                position: {
                    x: DEFAULT_NODE_X_OFFSET,
                    y: DEFAULT_NODE_Y_OFFSET,
                },
                parentNode: `group-${purchase._id}`,
                data: {
                    purchase: purchase,
                    supplierMode,
                    supplierName: purchase?.supplier?.seller?.name,
                    height: DEFAULT_NODE_HEIGHT,
                    allowCreate,
                },
            });

            return nodes;
        },
        [
            getApparelGroup,
            getMatchingProcess,
            isApparelMode,
            supplierMode,
            allowCreate,
        ]
    );

    const createApparelTraceNodes = useCallback(
        (traces: PurchaseOrderTraceModel[], purchaseIds: string[]) => {
            const tier: {
                [key: number]: {
                    [key: string]: {
                        isApparelGroup: boolean;
                        traces: PurchaseOrderTraceModel[];
                        apparelGroup?: (typeof ApparelGroup)[0];
                    };
                };
            } = {};

            traces.forEach((trace) => {
                if (!tier[trace.depth]) {
                    tier[trace.depth] = {};
                }
            });

            traces.forEach((trace) => {
                const processes = trace.processes.map((x) => x.toUpperCase());
                const currentTier = tier[trace.depth];
                const apparelGroup = getApparelGroup(processes);

                if (apparelGroup) {
                    const key = apparelGroup.id;
                    if (!currentTier[key]) {
                        currentTier[key] = {
                            isApparelGroup: true,
                            traces: [],
                            apparelGroup,
                        };
                    }
                    currentTier[key].traces.push(trace);
                } else {
                    if (!currentTier['other']) {
                        currentTier['other'] = {
                            isApparelGroup: false,
                            traces: [],
                        };
                    }
                    currentTier['other'].traces.push(trace);
                }
            });

            const nodes: Node[] = [];
            const joinedPurchaseOrders = [...purchaseIds];

            Object.entries(tier).forEach(([tierKey, tierValue], index) => {
                let previousGroupHeight = 0;
                const currentTier = tierKey;

                Object.entries(tierValue).forEach(
                    ([processKey, processValue], processIndex) => {
                        const apparelGroup: Node = {
                            id: `apparel-group-${processKey}-${currentTier}`,
                            type: CascadeNodeType.APPAREL_GROUP,
                            position: {
                                x:
                                    DEFAULT_X_OFFSET +
                                    DEFAULT_X_SPACING * (index + 1),
                                y:
                                    DEFAULT_Y_OFFSET +
                                    DEFAULT_PROCESS_Y_SPACING * processIndex +
                                    previousGroupHeight,
                            },
                            data: {
                                height:
                                    50 +
                                    DEFAULT_APPAREL_GROUP_HEIGHT *
                                        processValue.traces.length,
                                label:
                                    processValue.apparelGroup?.name || 'Others',
                            },
                        };

                        nodes.push(apparelGroup);

                        processValue.traces.forEach((trace, traceIndex) => {
                            joinedPurchaseOrders.push(trace._id);
                            const process = getMatchingProcess(trace.processes);
                            const groupProcess =
                                process || trace.processes?.[0];
                            const label = getSupplyChainNodeTypeLabel(
                                groupProcess?.trim() || 'No Process'
                            );

                            const parent = findFirstMatch(
                                joinedPurchaseOrders,
                                trace.parent || []
                            );

                            const group: Node = {
                                id: `group-${trace._id}`,
                                type: CascadeNodeType.NODE_GROUP,
                                position: {
                                    x: DEFAULT_X_OFFSET,
                                    y: DEFAULT_Y_OFFSET + 210 * traceIndex,
                                },
                                data: {
                                    height: DEFAULT_GROUP_HEIGHT,
                                    label: label,
                                    isApparelMode,
                                },
                                draggable: false,
                                parentNode: `apparel-group-${processKey}-${currentTier}`,
                            };

                            previousGroupHeight += group.data.height;

                            const upperTierWeight =
                                trace.depth === 0
                                    ? purchaseCottonWeight ?? 0
                                    : trace.cottonWeight ?? 0;

                            const node: Node = {
                                id: `node-${trace._id}`,
                                type: CascadeNodeType.NODE,
                                position: {
                                    x: DEFAULT_NODE_X_OFFSET,
                                    y: DEFAULT_NODE_Y_OFFSET,
                                },
                                parentNode: `group-${trace._id}`,
                                draggable: false,
                                data: {
                                    supplierMode,
                                    purchase: trace,
                                    supplierName: trace.supplier.seller.name,
                                    upperTierWeight,
                                    height: DEFAULT_NODE_HEIGHT,
                                    allowCreate,
                                    parentId: parent,
                                },
                            };

                            nodes.push(group);
                            nodes.push(node);
                        });
                    }
                );
            });

            return nodes;
        },
        [
            getApparelGroup,
            getMatchingProcess,
            supplierMode,
            purchaseCottonWeight,
            isApparelMode,
            allowCreate,
        ]
    );

    const createHardgoodsTraceNodes = useCallback(
        (traces: PurchaseOrderTraceModel[], purchaseIds: string[]) => {
            const nodes: Node[] = [];

            const item: {
                [key: string]: {
                    [key: string]: PurchaseOrderTraceModel[];
                };
            } = {};

            traces
                .sort((t1, t2) => {
                    const t1CreatedDate = new Date(t1.createdOn).getTime();
                    const t2CreatedDate = new Date(t2.createdOn).getTime();
                    return t1CreatedDate - t2CreatedDate;
                })
                .forEach((trace) => {
                    const tier = trace.depth?.toString();
                    const orderNature = trace.nature || 'unknown';

                    if (!tier) return;

                    if (!item[tier]) {
                        item[tier] = {};
                    }

                    if (!item[tier][orderNature]) {
                        item[tier][orderNature] = [];
                    }

                    item[tier][orderNature].push(trace);
                });

            const joinedPurchaseOrders = [...purchaseIds];

            Object.entries(item).forEach(([tier, processes], tierIndex) => {
                let previousGroupHeight = 0;

                Object.entries(processes).forEach(
                    ([process, purchases], processIndex) => {
                        purchases.forEach((purchase) => {
                            joinedPurchaseOrders.push(purchase._id);

                            const groupId = `group-${tierIndex}-${process}`;
                            const isExist = nodes.find((x) => x.id === groupId);

                            if (isExist) {
                                isExist.data.height += DEFAULT_GROUP_HEIGHT;
                                return;
                            } else {
                                const group = {
                                    id: groupId,
                                    type: CascadeNodeType.NODE_GROUP,
                                    position: {
                                        x:
                                            DEFAULT_X_OFFSET +
                                            DEFAULT_X_SPACING * (tierIndex + 1),
                                        y:
                                            DEFAULT_Y_OFFSET +
                                            DEFAULT_PROCESS_Y_SPACING *
                                                processIndex +
                                            previousGroupHeight,
                                    },
                                    data: {
                                        tier: parseInt(tier),
                                        height: DEFAULT_GROUP_HEIGHT,
                                        label:
                                            process && process !== 'null'
                                                ? CustomOrderNatureLabel[
                                                      process.toLocaleLowerCase() as PurchaseOrderNature
                                                  ] || 'PO Factory'
                                                : 'PO Factory',
                                        isApparelMode,
                                    },
                                };

                                previousGroupHeight += group.data.height;

                                nodes.push(group);
                            }
                        });

                        purchases.forEach((purchase, index) => {
                            const parent = findFirstMatch(
                                joinedPurchaseOrders,
                                purchase.parent || []
                            );

                            const groupId = `group-${tierIndex}-${process}`;

                            const node = {
                                id: `node-${purchase._id}`,
                                type: CascadeNodeType.NODE,
                                position: {
                                    x: DEFAULT_NODE_X_OFFSET,
                                    y:
                                        DEFAULT_NODE_Y_OFFSET +
                                        DEFAULT_NODE_HEIGHT * index +
                                        DEFAULT_NODE_Y_SPACING * index,
                                },
                                parentNode: groupId,
                                draggable: false,
                                data: {
                                    supplierMode,
                                    purchase: purchase,
                                    supplierName:
                                        purchase?.supplier?.seller?.name,
                                    height: DEFAULT_NODE_HEIGHT,
                                    allowCreate,
                                    parentId: parent,
                                },
                            };

                            nodes.push(node);
                        });
                    }
                );
            });

            return nodes;
        },
        [supplierMode, isApparelMode, allowCreate]
    );

    const buildNodes = useCallback(
        (purchase: PurchaseModel, traces: PurchaseOrderTraceModel[]) => {
            const consolidatedNodes: Node[] = [];

            const parentNodes = createParentNode(purchase);
            const purchaseIds = [purchase._id];
            consolidatedNodes.push(...parentNodes);

            const traceNodes = isApparelMode
                ? createApparelTraceNodes(
                      traces.sort((a, b) => a.depth - b.depth),
                      purchaseIds
                  )
                : createHardgoodsTraceNodes(
                      traces.sort((a, b) => a.depth - b.depth),
                      purchaseIds
                  );

            consolidatedNodes.push(...traceNodes);
            return consolidatedNodes;
        },
        [
            createParentNode,
            isApparelMode,
            createApparelTraceNodes,
            createHardgoodsTraceNodes,
        ]
    );

    return { buildNodes };
}
