import { Upload } from 'lucide-react';
import React, { useRef, useState } from 'react';
import { Button } from 'src/components/ui/button';
import { Card, CardContent } from 'src/components/ui/card';
import { toast } from 'sonner';

export interface AttachmentCreateParams {
    contentType: string;
    filename: string;
    file: File;
    id?: string;
}

// Define the props expected by the Dropzone component
// Create the Dropzone component receiving props
export function Dropzone({
    onChange,
    className,
    fileExtension,
    container,
    files,
    handleFileChange,
    disabled,
    disabledError,
    ...props
}: {
    onChange?: React.Dispatch<React.SetStateAction<AttachmentCreateParams[]>>;
    className?: string;
    container?: string;
    fileExtension?: string;
    files?: AttachmentCreateParams[];
    handleFileChange?: (file: AttachmentCreateParams) => void;
    disabledError?: string;
    disabled?: boolean;
}) {
    // Initialize state variables using the useState hook
    const fileInputRef = useRef<HTMLInputElement | null>(null); // Reference to file input element
    const [error, setError] = useState<string | null>(null); // Error message state

    // Function to handle drag over event
    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
    };

    // Function to handle drop event
    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        const { files } = e.dataTransfer;
        handleFiles(files);
    };

    // Function to handle file input change event
    const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { files } = e.target;
        if (files) {
            handleFiles(files);
        }
    };

    // Function to handle processing of uploaded files
    const handleFiles = (files: FileList) => {
        const uploadedFiles: AttachmentCreateParams[] = [];
        Object.values(files).forEach((file: File) => {
            if (fileExtension && !file.name.endsWith(`.${fileExtension}`)) {
                setError(`Invalid file type. Expected: .${fileExtension}`);
                return;
            }

            uploadedFiles.push({
                filename: file.name,
                contentType: file.type,
                file: file,
            });
        });

        if (onChange) {
            onChange((prev) => [...prev, ...uploadedFiles]);
        }

        if (handleFileChange) {
            handleFileChange({
                filename: files[0].name,
                contentType: files[0].type,
                file: files[0],
            });
        }
    };

    // Function to simulate a click on the file input element
    const handleButtonClick = () => {
        if (disabled) {
            toast.error(disabledError || 'This is currently disabled');
            return;
        }

        if (fileInputRef.current) {
            fileInputRef.current.click();
            fileInputRef.current.value = '';
        }
    };

    return (
        <div>
            <Card
                className={`border-2 border-dashed bg-muted hover:cursor-pointer hover:border-muted-foreground/50 ${className}`}
                {...props}
            >
                <CardContent
                    className='flex flex-col items-center justify-center space-y-2 px-2 py-4 text-xs'
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                >
                    <div className='flex flex-col items-center justify-center text-muted-foreground'>
                        {/* <span className='font-medium'>Drag Files to Upload or</span> */}
                        <Button
                            variant='ghost'
                            size='sm'
                            type='button'
                            className='ml-auto flex h-8 min-h-[100px] flex-col justify-end gap-4 space-x-2 px-0 pl-1 text-xs font-bold text-gray-700'
                            onClick={handleButtonClick}
                        >
                            <Upload className='h-10 w-10' />
                            <div className='grid grid-cols-1'>
                                <span>
                                    Click or drag file to this area to upload
                                </span>
                                <span className='truncate text-[8px]'>
                                    Support for a single or bulk upload.
                                    Strictly prohibited from uploading company
                                    data or other banned files.
                                </span>
                            </div>
                        </Button>

                        <input
                            ref={fileInputRef}
                            type='file'
                            accept={fileExtension ? `.${fileExtension}` : '*/*'} // Set accepted file type
                            onChange={(event) => handleFileInputChange(event)}
                            className='hidden'
                            // multiple
                        />
                    </div>
                    {error && <span className='text-red-500'>{error}</span>}
                </CardContent>
            </Card>

            {files && files.length > 0 && (
                <div className='mt-4 flex flex-col gap-2'>
                    {files.map((file, index) => (
                        <div
                            key={index}
                            onClick={() => {
                                if (onChange) {
                                    onChange((prev) =>
                                        prev.filter(
                                            (x) => x.filename !== file.filename
                                        )
                                    );
                                }
                            }}
                        >
                            <span className='text-xs font-bold'>File: </span>
                            <span className='text-xs'>{file.filename}</span>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}
