import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { useCreateLink } from 'src/app/_api_adb2c/purchase/link/hooks/use-create-link';
import { useLinkService } from 'src/app/_api_adb2c/purchase/link/hooks/use-link-service';
import { useCreatePurchase } from 'src/app/_api_adb2c/purchase/purchase/hooks/use-create-purchase';
import { ExternalReferenceModel } from 'src/app/_api_adb2c/purchase/purchase/models/external-reference.model';
import { PurchaseModel } from 'src/app/_api_adb2c/purchase/purchase/models/purchase.model';
import { DialogV2 } from 'src/app/components-v2/dialog-v2';
import { DelegationInput } from 'src/app/components/Form/DelegationInput';
import { AddSupplier } from 'src/app/pages/Suppliers/add-supplier';
import { delay } from 'src/app/utils/use-delay';
import { z } from 'zod';
import { AddPurchaseDocumentRequirements } from '../../../add-purchase-document-requirements';
import { AddPurchaseItemFields } from '../../../add-purchase-item-fields';
import { AddPurchaseRequiredFields } from '../../../add-purchase-required-fields';
import { BASE_PURCHASE_TRACE_QUERY_KEY } from 'src/app/_api_adb2c/purchase/purchase/hooks/purchase-query-keys';

export interface CascadeNodeProps {
    delegateId?: string;
    purchaseOrderId?: string;
    workspaceId?: string;
}

interface Props {
    open: boolean;
    onClose: () => void;
    cascade: CascadeNodeProps;
}

const purchaseOrderItemSchema = z.object({
    product: z.string(),
    ppu: z.coerce.number(),
    quantity: z.coerce.number(),
    unit: z.string(),
    sequence: z.coerce.number().optional(),
});

const formSchema = z.object({
    currency: z.string(),
    externalDataId: z.string().optional(),
    items: z.array(purchaseOrderItemSchema),
    parentOrderReference: z.string().optional(),
    rules: z.string().optional(),
    shippedOn: z.array(z.date()).optional(),
    shipToAddress: z.string().optional(),
    purchaseProcesses: z.array(z.string()).optional(),
    orderNature: z.string().optional(),
    supplier: z.string(),
    supplierProducer: z.string().optional(),
    delegateId: z.string().optional(),
});

export const AddCascadePurchase = ({ open, onClose, cascade }: Props) => {
    const [searchParams] = useSearchParams();
    const queryClient = useQueryClient();
    const delegateId = searchParams.get('delegateId') || '';

    const { mutateAsync: createPurchase } = useCreatePurchase();
    const { mutateAsync: createLink } = useCreateLink();
    const { service: linkService } = useLinkService();

    const [isLoading, setIsLoading] = useState(false);

    const defaultValues = useMemo(
        () => ({
            delegateId: cascade?.delegateId || delegateId,
            items: [],
        }),
        [cascade?.delegateId, delegateId]
    );

    const form = useForm<z.infer<typeof formSchema>>({
        mode: 'onChange',
        resolver: zodResolver(formSchema),
        defaultValues,
    });

    const isAddingNewSupplier = form.watch('supplier') === 'new';

    useEffect(() => {
        if (!open) {
            form.reset(defaultValues);
        }
    }, [open, form, defaultValues]);

    const handleSubmit = async (data: z.infer<typeof formSchema>) => {
        try {
            setIsLoading(true);
            const references: ExternalReferenceModel[] = [];
            if (data.externalDataId) {
                references.push({
                    value: data.externalDataId,
                    source: 'externalDataId',
                });
            }

            // Create purchase order
            const purchase = await createPurchase({
                body: {
                    currency: data.currency,
                    isFullyShipped: false,
                    nature: data.orderNature,
                    processes: data.purchaseProcesses?.length
                        ? data.purchaseProcesses
                        : undefined,
                    rules: data.rules ? [data.rules] : [],
                    shipToAddress: data.shipToAddress || '-',
                    supplier: data.supplier,
                    items: data.items.map((x) => ({
                        ...x,
                        shippedQuantity: x.quantity,
                    })),
                    reference: references,
                    parent: cascade?.purchaseOrderId,
                },
                delegateId: cascade?.delegateId || data.delegateId,
            });

            // Handle linking if needed
            if (cascade?.purchaseOrderId) {
                await delay(1000);
                // Get existing links
                const links: PurchaseModel[] = await linkService.getLinked(
                    cascade.delegateId || '',
                    cascade.purchaseOrderId
                );

                // Create link request with all purchase orders
                await createLink({
                    body: {
                        purchaseOrders: [
                            purchase._id,
                            ...links.map((link) => link._id),
                        ],
                    },
                    order: cascade.purchaseOrderId,
                    delegateId: cascade.delegateId,
                });

                // Invalidate queries to refresh data
                queryClient.invalidateQueries({
                    queryKey: [BASE_PURCHASE_TRACE_QUERY_KEY],
                });
            }

            setIsLoading(false);

            onClose();
        } catch (error) {
            console.error('Error creating cascade purchase:', error);
        }
    };

    return (
        <>
            <DialogV2
                open={open}
                onClose={onClose}
                form={form}
                onSubmit={handleSubmit}
                title='Add Purchase Order'
                isLoading={isLoading}
                isStepDialog
                size='lg'
                steps={[
                    {
                        title: 'Delegation Controls',
                        description:
                            'Select the delegate for this purchase order.',
                        content: (
                            <DelegationInput
                                name='delegateId'
                                label='On Behalf Of'
                                readOnly={!!cascade?.delegateId}
                                tooltip='This is the delegate that will be used to create the purchase order.'
                            />
                        ),
                    },
                    {
                        title: 'Basic Information',
                        description:
                            'Enter the basic information for this purchase order.',
                        content: <AddPurchaseRequiredFields />,
                    },
                    {
                        title: 'Items',
                        description: 'Add the items for this purchase order.',
                        content: <AddPurchaseItemFields />,
                    },
                    {
                        title: 'Purchase Ruleset',
                        description:
                            'Add the purchase rules for this purchase order.',
                        content: <AddPurchaseDocumentRequirements />,
                    },
                ]}
            />

            {isAddingNewSupplier && (
                <AddSupplier
                    open={isAddingNewSupplier}
                    onClose={() => form.setValue('supplier', '')}
                    predefinedDelegateId={cascade?.delegateId}
                />
            )}
        </>
    );
};
