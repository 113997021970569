import { zodResolver } from '@hookform/resolvers/zod';
import { ColumnDef } from '@tanstack/react-table';
import axios from 'axios';
import { Trash } from 'lucide-react';
import { useFieldArray, useForm } from 'react-hook-form';
import { toast } from 'sonner';
import { useAttachmentUpload } from 'src/app/_api_adb2c/attachment/hooks/use-attachment-upload';
import {
    SupportDocType,
    SupportDocTypeLabel,
} from 'src/app/_api_adb2c/shared/support-doc-type.enum';
import { useCreateDocument } from 'src/app/_api_adb2c/workspace/documents/hooks/use-create-document';
import { DialogV2 } from 'src/app/components-v2/dialog-v2';
import { DropzoneV2 } from 'src/app/components-v2/dropzone-v2';
import { TableV2 } from 'src/app/components-v2/table-v2';
import { SelectInput } from 'src/app/components/Form/SelectInput';
import { TextInput } from 'src/app/components/Form/TextInput';
import { useContextStore } from 'src/app/stores/context-store';
import { Button } from 'src/components/ui/button';
import { z } from 'zod';
import { CheckboxInput } from '../../../components/Form/CheckboxInput';

interface Props {
    open: boolean;
    onClose: () => void;
}

const documentSchema = z.object({
    comment: z.string().optional(),
    name: z.string(),
    nature: z.string(),
    contentType: z.string(),
    size: z.number(),
    isPrivate: z.boolean().default(false).optional(),
    file: z.instanceof(File),
});

const formSchema = z.object({
    documents: z.array(documentSchema),
});

export const CreateDocument = ({ open, onClose }: Props) => {
    const { workspace, user } = useContextStore();
    const { mutateAsync: upload, isLoading: uploadLoading } =
        useAttachmentUpload();
    const { mutateAsync: create, isLoading: createLoading } =
        useCreateDocument();

    const form = useForm<z.infer<typeof formSchema>>({
        mode: 'onChange',
        resolver: zodResolver(formSchema),
        defaultValues: {
            documents: [],
        },
    });

    const { fields, append, remove } = useFieldArray({
        control: form.control,
        name: 'documents',
    });

    const onDrop = (acceptedFiles: File[]) => {
        acceptedFiles.forEach((file) => {
            append({
                name: file.name,
                nature: '',
                comment: '',
                isPrivate: false,
                size: file.size,
                contentType: file.type,
                file: file,
            });
        });
    };

    const reset = () => {
        form.reset();
        onClose();
    };

    const onSubmit = async (data: z.infer<typeof formSchema>) => {
        try {
            await Promise.all(
                data.documents.map(async (doc) => {
                    const token = await upload({
                        assetType: 'document',
                        file: {
                            contentType: doc.contentType,
                            genre: 'document',
                            name: doc.name,
                            size: doc.size,
                            uploadedBy: user?._id || '',
                            workspace: workspace?._id || '',
                        },
                    });

                    await axios.put(token.token, doc.file, {
                        headers: {
                            'x-ms-blob-type': 'BlockBlob',
                            'Content-Type': doc.contentType,
                        },
                    });

                    const splittedAssetName =
                        token.attachment.assetName.split('/');
                    const imageId =
                        splittedAssetName[splittedAssetName.length - 1].split(
                            '.'
                        )[0];

                    return await create({
                        body: {
                            documents: [
                                {
                                    description: doc.comment,
                                    file: imageId,
                                    isPrivate: doc.isPrivate || false,
                                    type: doc.nature,
                                },
                            ],
                            extensions: [
                                {
                                    isCertificate: false,
                                    name: doc.name,
                                    translatedName: [doc.name],
                                    translatedDescription: doc.comment
                                        ? [doc.comment]
                                        : [],
                                },
                            ],
                        },
                    });
                })
            );

            toast.success('Documents uploaded successfully');
            reset();
        } catch (error) {
            console.error('Error uploading documents:', error);
            toast.error('Failed to upload documents');
        }
    };

    const columns: ColumnDef<z.infer<typeof documentSchema>>[] = [
        {
            id: 'name',
            header: 'Name',
            cell: ({ row }) => {
                return <TextInput inputName={`documents.${row.index}.name`} />;
            },
        },
        {
            id: 'nature',
            header: 'Nature',
            cell: ({ row }) => {
                return (
                    <SelectInput
                        name={`documents.${row.index}.nature`}
                        options={Object.values(SupportDocType).map((value) => ({
                            value,
                            label: SupportDocTypeLabel[value],
                        }))}
                    />
                );
            },
        },
        {
            id: 'comment',
            header: 'Comment',
            cell: ({ row }) => {
                return (
                    <TextInput inputName={`documents.${row.index}.comment`} />
                );
            },
        },
        {
            id: 'isPrivate',
            header: 'Private',
            cell: ({ row }) => {
                return (
                    <CheckboxInput
                        name={`documents.${row.index}.isPrivate`}
                        onChange={(value) => {
                            form.setValue(
                                `documents.${row.index}.isPrivate`,
                                value
                            );
                        }}
                    />
                );
            },
        },
        {
            id: 'actions',
            header: 'Actions',
            size: 10,
            cell: ({ row }) => {
                return (
                    <Button onClick={() => remove(row.index)} variant='outline'>
                        <Trash size={16} />
                    </Button>
                );
            },
        },
    ];

    return (
        <DialogV2
            open={open}
            onClose={reset}
            title='Upload Document'
            isStepDialog
            form={form}
            onSubmit={form.handleSubmit(onSubmit)}
            isLoading={uploadLoading || createLoading}
            size='lg'
            steps={[
                {
                    title: 'Upload Documents',
                    description: 'Upload and configure documents',
                    content: (
                        <div className='flex flex-col gap-6'>
                            <DropzoneV2
                                onDrop={onDrop}
                                // Optionally override default options
                                options={{
                                    maxFiles: 5,
                                    maxSize: 10 * 1024 * 1024,
                                }}
                            />

                            <TableV2
                                columns={columns}
                                data={fields}
                                label='Document Details'
                                disableTooltips
                            />
                        </div>
                    ),
                },
            ]}
        />
    );
};
