import { useCallback } from 'react';
import { Edge } from 'reactflow';
import { PurchaseOrderNature } from 'src/app/_api_adb2c/purchase/purchase/enums/purchase-order-nature.enum';
import {
    PurchaseModel,
    PurchaseOrderTraceModel,
} from 'src/app/_api_adb2c/purchase/purchase/models/purchase.model';

export function findFirstMatch(
    arrayA: string[],
    arrayB: string[]
): string | undefined {
    return arrayA.find((valueA) => arrayB.includes(valueA));
}

export function useEdgeBuilder() {
    const getEdgeColor = useCallback((type: PurchaseOrderNature) => {
        switch (type) {
            case PurchaseOrderNature.OUTSOURCE:
                return '#FF9400';
            case PurchaseOrderNature.PROCESS:
                return '#007C77';
            case PurchaseOrderNature.COMPONENT:
                return '#000';
            default:
                return '#007C77';
        }
    }, []);

    const buildEdges = useCallback(
        (purchase: PurchaseModel, traces: PurchaseOrderTraceModel[]) => {
            const edges: Edge[] = [];

            const flattenedPurchaseIds = traces.flatMap(
                (trace) => trace._id || []
            );

            flattenedPurchaseIds.push(purchase._id);

            traces.forEach((trace) => {
                const parentArray = Array.isArray(trace.parent)
                    ? trace.parent
                    : [trace.parent];

                const firstMatch = findFirstMatch(
                    flattenedPurchaseIds,
                    parentArray || []
                );

                edges.push({
                    id: `${firstMatch} - ${trace._id}`,
                    source: `node-${firstMatch}`,
                    target: `node-${trace._id}`,
                    zIndex: 1,
                    style: {
                        stroke: getEdgeColor(trace.nature),
                    },
                });
            });

            return edges;
        },
        [getEdgeColor]
    );

    return { buildEdges };
}
