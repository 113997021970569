import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { toast } from 'sonner';
import { ProductCategoriesModel } from 'src/app/_api_adb2c/product/category/category.model';
import { useCategoryService } from 'src/app/_api_adb2c/product/category/hooks/use-category-service';
import { useCreateProduct } from 'src/app/_api_adb2c/product/product/hooks/use-create-product';
import { useProductService } from 'src/app/_api_adb2c/product/product/hooks/use-product-service';
import {
    IExternalReference,
    IndustryType,
    ProductModel,
} from 'src/app/_api_adb2c/product/product/models/product.model';
import { BASE_PURCHASE_ITEM_QUERY_KEY } from 'src/app/_api_adb2c/purchase/purchase-item/hooks/purchase-item-query-keys';
import { ProductUom } from 'src/app/_api_adb2c/purchase/purchase/enums/product-uom.enum';
import { PurchaseOrderNature } from 'src/app/_api_adb2c/purchase/purchase/enums/purchase-order-nature.enum';
import { BASE_PURCHASE_TRACE_QUERY_KEY } from 'src/app/_api_adb2c/purchase/purchase/hooks/purchase-query-keys';
import { useCreatePurchase } from 'src/app/_api_adb2c/purchase/purchase/hooks/use-create-purchase';
import { usePurchaseService } from 'src/app/_api_adb2c/purchase/purchase/hooks/use-purchase-service';
import { ExternalReferenceModel } from 'src/app/_api_adb2c/purchase/purchase/models/external-reference.model';
import { PurchaseModel } from 'src/app/_api_adb2c/purchase/purchase/models/purchase.model';
import { useSupplierService } from 'src/app/_api_adb2c/purchase/suppliers/hooks/use-supplier-service';
import { SupplierModel } from 'src/app/_api_adb2c/purchase/suppliers/models/supplier.model';
import { BASE_VIRTUAL_PURCHASE_KEY } from 'src/app/_api_adb2c/purchase/virtual-purchase/hooks/virtual-purchase-query-key';
import { useSupplierConnect } from 'src/app/_api_adb2c/workspace/account/hooks/use-supplier-connect';
import { useSupplierInvite } from 'src/app/_api_adb2c/workspace/account/hooks/use-supplier-invite';
import { UserCreateDataObject } from 'src/app/_api_adb2c/workspace/users/user-create-data-object';
import { useWorkspaceService } from 'src/app/_api_adb2c/workspace/workspace/hooks/use-workspace-service';
import { WorkspaceModel } from 'src/app/_api_adb2c/workspace/workspace/workspace.model';
import { ImportErrorMessage } from 'src/app/pages/Purchases/Details/purchase-cascade-import-error';
import { convertToNested } from 'src/app/pages/Purchases/DetailsV2/use-import-cascade';
import { useContextStore } from 'src/app/stores/context-store';
import { read, utils } from 'xlsx';
import countries from '../../../../../infrastructure/config/data/countries.json';

export interface SheetData {
    'PO Factory Name': string;
    'Main Category [Optional]': string;
    'PO Number [Optional]': string;
    'Item Number [Optional]': string;
    'Product Name': string;
    'Company Role/Purchase Process for Factory (Material Supplier)': string;
    'Factory Name (Material Supplier)': string;
    'Factory License Number (Material Supplier)': string;
    'Factory Country (Material Supplier)': string;
    'Factory Address (Material Supplier)': string;
    'Factory Contact First Name (Material Supplier) [Optional]': string;
    'Factory Contact Last Name (Material Supplier) [Optional]': string;
    'Factory Contact Email (Material Supplier) [Optional]': string;
    'Upper Tier Row Number [Optional]': string;
    __rowNum__: string;
    children: SheetData[];
    [key: string]: string | SheetData[];
}

export function useImportDefaultTemplate() {
    const SheetName = 'Supplier Template';
    const [loading, setLoading] = useState(false);

    const { service: workspaceService } = useWorkspaceService();
    const { service: purchaseService } = usePurchaseService();
    const { service: categoryService } = useCategoryService();
    const { service: productService } = useProductService();
    const { service: supplierService } = useSupplierService();

    const { mutateAsync: createProduct } = useCreateProduct();
    const { mutateAsync: invite } = useSupplierInvite();
    const { mutateAsync: connect } = useSupplierConnect();
    const { mutateAsync: createPurchase } = useCreatePurchase();

    const delay = (duration: number) =>
        new Promise((resolve) => setTimeout(resolve, duration));

    const store = useContextStore();
    const client = useQueryClient();

    const validateExcel = async (
        data: SheetData[],
        errorMessages: ImportErrorMessage[]
    ) => {
        const validationErrors: ImportErrorMessage[] = [];

        data.forEach((item, index) => {
            const requiredFields = [
                'Factory Name (Material Supplier)',
                'Factory License Number (Material Supplier)',
                'Factory Country (Material Supplier)',
                'Factory Address (Material Supplier)',
                'Company Role/Purchase Process for Factory (Material Supplier)',
                'Product Name',
            ];

            requiredFields.forEach((field) => {
                if (!item[field]) {
                    validationErrors.push({
                        rowNum: index + 2,
                        message: `${field} is required`,
                    });
                }
            });
        });

        if (validationErrors.length > 0) {
            errorMessages.push(...validationErrors);
        }
    };

    const submit = async (workspace: string, file: File, product: string) => {
        return new Promise((resolve, reject) => {
            setLoading(true);
            const fileReader = new FileReader();
            fileReader.readAsArrayBuffer(file);

            fileReader.onload = async (e) => {
                try {
                    const buffer = e.target?.result as ArrayBuffer;
                    const wb = read(buffer, { type: 'array', cellDates: true });

                    const ws = wb.Sheets[SheetName];
                    const data: SheetData[] = utils.sheet_to_json(ws);
                    const filteredData = data
                        .filter(
                            (x: SheetData) =>
                                x['Factory Name (Material Supplier)'] ||
                                x['PO Factory Name']
                        )
                        .map((x) => ({
                            ...x,
                            __rowNum__: x.__rowNum__ + 1,
                        }));

                    const errorMessages: ImportErrorMessage[] = [];

                    await validateExcel(filteredData, errorMessages);

                    const nestedData = convertToNested(
                        filteredData,
                        errorMessages
                    );

                    if (errorMessages.length > 0) {
                        reject(errorMessages);

                        return;
                    }

                    await processExcel(workspace, nestedData, product);

                    const invalidatedKeys: string[] = [
                        BASE_VIRTUAL_PURCHASE_KEY,
                        BASE_PURCHASE_TRACE_QUERY_KEY[0],
                        BASE_PURCHASE_ITEM_QUERY_KEY[0],
                    ];

                    invalidatedKeys.forEach((key) => {
                        client.invalidateQueries([key]);
                    });

                    resolve(nestedData);

                    toast.success(
                        'Successfully imported the template, please refresh the page'
                    );
                } catch (err) {
                    const error = err as Error;
                    toast.error(
                        `Failed to import the template: ${error.message}`
                    );

                    reject(err);
                } finally {
                    setLoading(false);
                }
            };

            fileReader.onerror = (error) => {
                setLoading(false);
                reject(error); // Reject the promise if there's an error
            };
        });
    };

    const processExcel = async (
        workspaceId: string,
        data: SheetData[],
        virtualRoot: string,
        parentPurchase?: PurchaseModel
    ) => {
        for (const item of data) {
            const partner = await findOrCreatePartner(
                parentPurchase
                    ? parentPurchase.supplier.seller._id
                    : workspaceId,
                item
            );
            const category = await findOrCreateCategory(
                parentPurchase
                    ? parentPurchase.supplier.seller._id
                    : workspaceId,
                item
            );
            const product = await findOrCreateProduct(
                parentPurchase
                    ? parentPurchase.supplier.seller._id
                    : workspaceId,
                item,
                category._id
            );

            const purchase = await createPurchaseOrder(
                parentPurchase
                    ? parentPurchase.supplier.seller._id
                    : workspaceId,
                partner.seller._id,
                product,
                item,
                parentPurchase ? undefined : virtualRoot,
                parentPurchase
            );

            if (item.children.length > 0) {
                await processExcel(
                    parentPurchase
                        ? parentPurchase.supplier.seller._id
                        : workspaceId,
                    item.children,
                    virtualRoot,
                    purchase
                );
            }
        }
    };

    const findPartner = async (workspaceId: string, name: string) => {
        const partners: SupplierModel[] = await supplierService.list(
            workspaceId,
            '1'
        );

        return partners.find((partner) => {
            return (
                partner.seller._id === name.toLocaleLowerCase() ||
                partner.seller.name.toLocaleLowerCase() ===
                    name.toLocaleLowerCase()
            );
        });
    };

    const findOrCreatePartner = async (
        workspaceId: string,
        item: SheetData,
        mode: 'poFactory' | 'factory' = 'factory',
        debug = true
    ) => {
        const name =
            mode === 'poFactory'
                ? item['PO Factory Name']
                : item['Factory Name (Material Supplier)'];
        const parsedName = name?.toString()?.trim();
        const registrationNumber =
            item['Factory License Number (Material Supplier)'];
        const parsedRegistrationNumber = registrationNumber?.toString()?.trim();

        const existingPartner = await findPartner(workspaceId, parsedName);
        if (existingPartner) return existingPartner;

        const workspaces: WorkspaceModel[] =
            await workspaceService.searchByCompanyName(parsedName);

        const workspace = workspaces.find(
            (x) =>
                x.company.demographics.name.toLocaleLowerCase() ===
                parsedName.toLocaleLowerCase()
        );

        if (workspace) {
            await connect({
                delegate: workspaceId,
                supplier: workspace._id,
                delegateId: workspaceId,
                diversion: '1',
            });
        } else {
            const contact: UserCreateDataObject[] = [];
            const contactInfo = {
                email: item[
                    'Factory Contact Email (Material Supplier) [Optional]'
                ],
                firstName:
                    item[
                        'Factory Contact First Name (Material Supplier) [Optional]'
                    ],
                lastName:
                    item[
                        'Factory Contact Last Name (Material Supplier) [Optional]'
                    ],
            };

            if (debug && store.user) {
                contact.push({
                    contact: {
                        email: store.user.contact.email,
                        firstName: store.user.contact.firstName,
                        lastName: store.user.contact.lastName,
                        device: {},
                    },
                });
            }

            if (contactInfo.email && contactInfo.firstName) {
                contact.push({
                    contact: {
                        email: contactInfo.email,
                        firstName: contactInfo.firstName,
                        lastName: contactInfo.lastName,
                        device: {},
                    },
                });
            }

            const country = countries.find(
                (x) =>
                    x['alpha-2'] ===
                        item[
                            'Factory Country (Material Supplier)'
                        ].toString() ||
                    x['name'].toLocaleLowerCase() ===
                        item['Factory Country (Material Supplier)']
                            .toString()
                            .toLocaleLowerCase()
            )?.['alpha-2'];

            await invite({
                body: {
                    company: parsedName,
                    registrationNumber: parsedRegistrationNumber,
                    country: country || '',
                    contact: contact.length > 0 ? contact : undefined,
                },
                delegateId: workspaceId,
                diversion: '1',
            });
        }

        await delay(2000);

        const partner = await findPartner(workspaceId, parsedName);

        if (!partner) {
            throw new Error(`Failed to create partner: ${parsedName}`);
        }

        return partner;
    };

    const findOrCreateCategory = async (
        workspaceId: string,
        item: SheetData
    ) => {
        const categoryName =
            item['Main Category [Optional]'] || 'Placeholder Category';
        const parsedCategoryName = categoryName?.toString()?.trim();

        const existingCategories: ProductCategoriesModel[] =
            await categoryService.search(
                workspaceId,
                encodeURIComponent(parsedCategoryName),
                '1'
            );

        let category: ProductCategoriesModel;

        if (existingCategories.length === 0) {
            category = await categoryService.create(
                workspaceId,
                {
                    code: parsedCategoryName,
                    defaultUnitOfMeasurement: ProductUom.PCS,
                    name: parsedCategoryName,
                    description: `Category created from template - ${parsedCategoryName}`,
                    industry: IndustryType.OTHERS,
                    workspace: workspaceId,
                },
                '1'
            );
        } else {
            category = existingCategories[0];
        }

        return category;
    };

    const findOrCreateProduct = async (
        workspaceId: string,
        item: SheetData,
        categoryId: string
    ) => {
        const orderNature =
            item[
                'Company Role/Purchase Process for Factory (Material Supplier)'
            ];
        const name: string =
            orderNature === PurchaseOrderNature.PROCESS
                ? `Service - ${item['Product Name']}`
                : item['Product Name'];

        const parsedName = name?.toString()?.trim();

        let product = await productService.search(
            workspaceId,
            encodeURIComponent(parsedName)
        );

        if (!product) {
            const externalReferences: IExternalReference[] = [];
            if (item['Item Number [Optional]']) {
                externalReferences.push({
                    source: 'externalDataId',
                    value: item['Item Number [Optional]'],
                });
            }

            product = await createProduct({
                delegateId: workspaceId,
                body: {
                    category: categoryId,
                    externalReferences,
                    name: parsedName,
                    description: '',
                    unitOfMeasurement: ProductUom.PCS,
                    loaderType: store.workspace?.supplyChainLoaderType || 0,
                    enabled: true,
                    tags: [],
                    workspace: workspaceId,
                    measureValue: 0,
                },
                diversion: '1',
            });

            await delay(1500);
        }

        return product;
    };

    const createPurchaseOrder = async (
        workspaceId: string,
        supplier: string,
        product: ProductModel,
        item: SheetData,
        virtualRoot?: string,
        parentPurchase?: PurchaseModel
    ) => {
        const latestVersion = product.versions?.[product.versions.length - 1];
        if (!latestVersion) return;

        const processes =
            item[
                'Company Role/Purchase Process for Factory (Material Supplier)'
            ]?.split(',');
        const reference: ExternalReferenceModel[] = [];

        if (item['PO Number [Optional]']) {
            reference.push({
                source: 'externalDataId',
                value: item['PO Number [Optional]'],
            });
        }

        const purchase = await createPurchase({
            delegateId: workspaceId,
            body: {
                supplier: supplier,
                currency: 'USD',
                items: [
                    {
                        product: product._id,
                        ppu: latestVersion.costOfMaterials || 0,
                        quantity: 1,
                        unit: ProductUom.PCS,
                    },
                ],
                rules: [],
                processes: processes || [],
                reference: reference,
                shipToAddress: '-',
                virtualRoot: virtualRoot,
                isVirtual: true,
                parent: parentPurchase?._id,
            },
            diversion: '1',
        });

        await delay(1500);

        const version: PurchaseModel = await purchaseService.get(
            workspaceId,
            purchase._id,
            '1'
        );

        return version;
    };

    return { submit, loading };
}
