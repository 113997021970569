import { zodResolver } from '@hookform/resolvers/zod';
import { ColumnDef } from '@tanstack/react-table';
import axios from 'axios';
import { CloudUpload, Trash } from 'lucide-react';
import { useEffect, useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { FileCreateDataObject } from 'src/app/_api_adb2c/attachment/file-create-data-object';
import { useAttachmentUpload } from 'src/app/_api_adb2c/attachment/hooks/use-attachment-upload';
import { useMaterial } from 'src/app/_api_adb2c/product/material/hooks/use-material';
import { useUpdateMaterial } from 'src/app/_api_adb2c/product/material/hooks/use-update-material';
import { ProductMaterialDataObject } from 'src/app/_api_adb2c/product/material/request/product-material-data-object';
import { ProductDocObject } from 'src/app/_api_adb2c/product/product/requests/product-update-data-object';
import {
    SupportDocType,
    SupportDocTypeLabel,
} from 'src/app/_api_adb2c/shared/support-doc-type.enum';
import { DialogV2 } from 'src/app/components-v2/dialog-v2';
import { FileInput, FileUploader } from 'src/app/components-v2/file-dropzone';
import { TableV2 } from 'src/app/components-v2/table-v2';
import { SelectInputV2 } from 'src/app/components/Form/SelectInputV2';
import { TextInputV2 } from 'src/app/components/Form/TextInputV2';
import { useContextStore } from 'src/app/stores/context-store';
import { getFileIdFromAssetName } from 'src/infrastructure/utils/extract-asset-id';
import { z } from 'zod';
import { dropZoneConfig } from '../Substance/substance-sds-file-upload';
import { AddMaterialCertificates } from '../add-material-certificates';

interface Props {
    open: boolean;
    materialId: string;
    onClose: () => void;
}

const itemsSchema = z.object({
    document: z.string(),
    type: z.string(),
    file: z.instanceof(File),
    comment: z.string().optional(),
});

const certificateSchema = z.object({
    certificationType: z.string(),
    certificateNumber: z.string(),
    expiryDate: z.date(),
    certificateFile: z.array(z.instanceof(File)),
    weight: z.coerce.number().optional(),
});

const schema = z.object({
    items: z.array(itemsSchema),
    certificates: z.array(certificateSchema),
});

export function AddMaterialDocument({ open, materialId, onClose }: Props) {
    const context = useContextStore();
    const [searchParams] = useSearchParams();
    const delegateId = searchParams.get('delegateId') || '';

    const { data: material } = useMaterial(materialId, delegateId);
    const [files, setFiles] = useState<File[] | null>(null);

    const { mutateAsync: update, isLoading: isUpdateMaterial } =
        useUpdateMaterial();
    const { mutateAsync: upload, isLoading: isUploadMaterial } =
        useAttachmentUpload();

    const form = useForm<z.infer<typeof schema>>({
        mode: 'onChange',
        resolver: zodResolver(schema),
    });

    const { fields, append, replace } = useFieldArray({
        control: form.control,
        name: 'items',
    });

    const {
        fields: certificateFields,
        append: appendCertificate,
        remove: removeCertificate,
    } = useFieldArray({
        control: form.control,
        name: 'certificates',
    });

    const onSubmit = async (params: z.infer<typeof schema>) => {
        if (!material) return;

        // if (params.items.length === 0) {
        //     toast.error('Please upload at least one document');
        //     return;
        // }

        // if (!params.items.some((x) => x.type)) {
        //     toast.error('Please select a document type for each document');
        //     return;
        // }

        const documents: ProductDocObject[] = [];

        console.log(params);

        if (params.certificates.length > 0) {
            const files = Array.from(
                params.certificates.map((file) => {
                    const uploadedFile = file.certificateFile[0];

                    const param: { data: FileCreateDataObject; info: any } = {
                        data: {
                            contentType: uploadedFile.type,
                            size: uploadedFile.size,
                            name: uploadedFile.name,
                            uploadedBy: context.user?._id || '',
                            workspace: context.workspace?._id || '',
                            genre: 'material',
                            file: uploadedFile,
                        },
                        info: {
                            certificateNumber: file.certificateNumber,
                            certificateType: file.certificationType,
                            weight: file.weight,
                        },
                    };

                    return param;
                })
            );

            await Promise.all(
                files.map(async (file) => {
                    const response = await upload({
                        file: file.data,
                        assetType: 'material',
                        delegateId: delegateId || '',
                    });

                    await axios.put(response.token, file.data.file, {
                        headers: {
                            'x-ms-blob-type': 'BlockBlob',
                            'Content-Type':
                                response.attachment.contentType || '',
                        },
                    });

                    documents.push({
                        file: response.attachment,
                        certificateNumber: file.info.certificateNumber,
                        type: file.info.certificateType,
                        uploader: context.user?._id || '',
                        comment: file.info.weight?.toString(),
                        _id: getFileIdFromAssetName(
                            response.attachment.assetName
                        ),
                    });
                })
            );
        }

        await Promise.all(
            params.items.map(async (item) => {
                const request: FileCreateDataObject = {
                    contentType: item.file.type,
                    size: item.file.size,
                    name: item.document,
                    uploadedBy: context.user?._id || '',
                    workspace: context.workspace?._id || '',
                    genre: 'temporary',
                    file: item.file,
                };

                const response = await upload({
                    file: request,
                    assetType: 'material',
                    delegateId: delegateId,
                });

                await axios.put(response.token, item.file, {
                    headers: {
                        'Content-Type': item.file.type,
                        'x-ms-blob-type': 'BlockBlob',
                    },
                });

                documents.push({
                    file: {
                        assetName: response.attachment.assetName,
                        autoResign: response.attachment.autoResign,
                        container: response.attachment.container,
                        contentType: response.attachment.contentType,
                        extension:
                            response.attachment.originalName.split('.').pop() ||
                            '',
                        originalName: response.attachment.originalName,
                        readReleaseTime: response.attachment.readReleaseTime,
                        size: response.attachment.size,
                    },
                    type: item.type,
                    uploader: context.user?._id || '',
                });
            })
        );

        const request: ProductMaterialDataObject = {
            name: material.name,
            unitOfMeasurement: material.unitOfMeasurement,
            unitCost: material.unitCost,
            unitCostCurrency: material.unitCostCurrency,
            workspace: material.workspace,
            documents: [...documents, ...(material.documents || [])],
        };

        await update({
            id: material._id,
            body: request,
        });

        reset();
    };

    const reset = () => {
        form.reset();
        onClose();
    };

    const columns: ColumnDef<z.infer<typeof itemsSchema>>[] = useMemo(() => {
        const columns: ColumnDef<z.infer<typeof itemsSchema>>[] = [
            {
                id: 'document',
                header: 'Document',
                cell: ({ row }) => {
                    return <TextInputV2 name={`items.${row.index}.document`} />;
                },
            },
            {
                id: 'type',
                header: 'Type',
                cell: ({ row }) => {
                    return (
                        <SelectInputV2
                            name={`items.${row.index}.type`}
                            options={Object.keys(SupportDocType).map((key) => ({
                                label: SupportDocTypeLabel[
                                    key as SupportDocType
                                ],
                                value: key,
                            }))}
                        />
                    );
                },
            },
            {
                id: 'actions',
                header: 'Actions',
                size: 10,
                cell: ({ row }) => {
                    return (
                        <div className='flex gap-2'>
                            <Trash
                                size={16}
                                className='text-red-500 hover:scale-125 hover:text-red-700'
                                onClick={() => {
                                    const newItems = fields.filter(
                                        (_, i) => i !== row.index
                                    );

                                    replace(newItems);
                                    setFiles(newItems.map((x) => x.file));
                                }}
                            />
                        </div>
                    );
                },
            },
        ];

        return columns;
    }, [replace, fields]);

    useEffect(() => {
        if (files && files.length > 0) {
            const filteredFiles = files.filter((file) => {
                return !fields.find((x) => x.file === file);
            });

            filteredFiles.forEach((file) => {
                append({
                    document: file.name,
                    type: '',
                    file: file,
                });
            });
        }
    }, [files, append, fields]);

    return (
        <DialogV2
            open={open}
            onClose={reset}
            title='Add Document'
            isStepDialog
            form={form}
            size='lg'
            isLoading={isUpdateMaterial || isUploadMaterial}
            onSubmit={onSubmit}
            steps={[
                {
                    title: 'Upload Documents',
                    description: 'Upload the documents for the material',
                    content: (
                        <div className='flex flex-col gap-4'>
                            <FileUploader
                                value={files}
                                onValueChange={setFiles}
                                dropzoneOptions={dropZoneConfig}
                                className='overflow-unset h-[150px] min-h-[150px] bg-gray-200'
                            >
                                <FileInput className='group h-full min-h-fit border border-dashed border-gray-400 hover:bg-gray-200'>
                                    <div className='flex h-full w-full flex-col items-center justify-center gap-4 pb-4 pt-3 text-center '>
                                        <CloudUpload size={30} />
                                        <span className='text-xs text-gray-500'>
                                            Click to SELECT FILE or DRAG & DROP
                                            FILES here
                                            <br />
                                            Only files in{' '}
                                            <span className='font-bold'>
                                                PDF, TIFF or JPEG
                                            </span>{' '}
                                            format will be accepted
                                            <br />
                                            Max File Size: 10MB
                                        </span>
                                    </div>
                                </FileInput>
                            </FileUploader>

                            <TableV2
                                label='Uploaded Files'
                                data={fields}
                                columns={columns}
                                fixedHeight='325px'
                                disableTooltips
                            />
                        </div>
                    ),
                },
                {
                    title: 'Add Certificates',
                    description: 'Add the certificates for the material',
                    content: (
                        <AddMaterialCertificates
                            certificates={certificateFields}
                            append={appendCertificate}
                            remove={removeCertificate}
                        />
                    ),
                },
            ]}
        />
    );
}
