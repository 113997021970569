import { memo } from 'react';
import { cn } from 'src/lib/utils';

interface Props {
    data: {
        label: string;
        height: number;
        className?: string;
        isApparelMode?: boolean;
    };
}

export const PurchaseCascadeGroupNode = memo(({ data }: Props) => {
    const { label, height, className, isApparelMode } = data;

    return (
        <div
            className={cn(
                'w-[240px] rounded-md border-2 border-gray-300 bg-white',
                className
            )}
            style={{ height: `${height}px` }}
        >
            <div
                className={cn(
                    'w-full border-b-2 border-gray-300 px-3 py-1.5',
                    isApparelMode ? 'bg-gray-200' : 'bg-red-800'
                )}
            >
                <span
                    className={cn(
                        'block truncate text-sm font-semibold',
                        isApparelMode ? 'text-gray-700' : 'text-white'
                    )}
                >
                    {label}
                </span>
            </div>
        </div>
    );
});

PurchaseCascadeGroupNode.displayName = 'PurchaseCascadeGroupNode';
