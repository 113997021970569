interface Props {
    materialId: string;
}

export function MaterialOrder({ materialId }: Props) {
    return (
        <div className='flex h-full items-center justify-center rounded-lg bg-white p-8 text-gray-500 shadow'>
            <div className='text-center'>
                <p className='mb-2 text-sm'>
                    No orders found for this material
                </p>
                <p className='text-xs text-gray-400'>
                    Orders containing this material will appear here
                </p>
            </div>
        </div>
    );
}
