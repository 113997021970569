import { Download, File } from 'lucide-react';
import { useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'sonner';
import { usePurchase } from 'src/app/_api_adb2c/purchase/purchase/hooks/use-purchase';
import { useDelegations } from 'src/app/_api_adb2c/workspace/delegations/hooks/use-delegations';
import { BaseDialog } from 'src/app/components-v2/base-dialog';
import { TypographySmall } from 'src/app/components-v2/typography';
import { TooltipWrapper } from 'src/app/components/TooltipWrapper';
import {
    AttachmentCreateParams,
    Dropzone,
} from 'src/app/components-v2/dropzone';
import { useContextStore } from 'src/app/stores/context-store';
import { Button } from 'src/components/ui/button';
import { useImportAction } from './use-import-action';
import { useImportCascade } from './use-import-cascade';

interface Props {
    open: boolean;
    onClose: () => void;
}

export function PurchaseCascadeImport({ open, onClose }: Props) {
    const { id } = useParams<{ id: string }>();
    const [files, setFiles] = useState<AttachmentCreateParams[]>([]);
    const [searchParams] = useSearchParams();
    const delegateId = searchParams.get('delegateId') || '';
    const { data: purchase } = usePurchase(id || '', delegateId);
    const { data: delegates } = useDelegations();
    const [loading, setLoading] = useState(false);

    const context = useContextStore();

    const { submit: importDefault } = useImportCascade();
    const { submit: importAction } = useImportAction();

    const supplyChainLoaderType = useMemo(() => {
        let feature = context.workspace?.features;

        if (delegateId) {
            const delegate = delegates?.find(
                (delegate) => delegate.delegatedTo._id === delegateId
            );

            if (delegate) {
                feature = delegate.delegatedTo.features;
            }
        }

        return feature?.supplyChainLoaderType
            ? feature.supplyChainLoaderType
            : context.workspace?.supplyChainLoaderType || 0;
    }, [
        context.workspace?.features,
        context.workspace?.supplyChainLoaderType,
        delegates,
        delegateId,
    ]);

    const Template =
        supplyChainLoaderType === 0
            ? require('./default_template.xlsx')
            : require('./action_template.xlsx');

    const importCascade = async () => {
        if (files.length < 1) return;
        if (!id) return;
        if (!purchase) return;

        try {
            setLoading(true);

            const file = files[0];

            if (supplyChainLoaderType === 0) {
                await importDefault(purchase.workspace, id, file.file);
            } else {
                await importAction(purchase.workspace, id, file.file);
            }
        } catch (err) {
            const error = err as Error;

            toast.error(`Failed to import purchase: ${error.message}`);
            console.error(error);
        } finally {
            setLoading(false);
        }

        onClose();
    };

    return (
        <BaseDialog
            title='Import Purchase'
            open={open}
            onClose={onClose}
            onConfirm={importCascade}
            isLoading={loading}
            extraControls={
                <TooltipWrapper label='Download Template'>
                    <Button size='sm' variant='outline'>
                        <a href={Template} download='purchase-cascade.xlsx'>
                            <Download size={16} />
                        </a>
                    </Button>
                </TooltipWrapper>
            }
        >
            <Dropzone
                fileExtension='xlsx'
                handleFileChange={(file) => {
                    setFiles([file]);
                }}
            />

            {files && files.length > 0 && (
                <TypographySmall className='flex gap-2'>
                    <File size={16} />
                    {files[0].filename}
                </TypographySmall>
            )}
        </BaseDialog>
    );
}
