import { ColumnDef } from '@tanstack/react-table';
import { Link, Sheet, Trash, Upload } from 'lucide-react';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { SalesOrderStatusLabel } from 'src/app/_api_adb2c/sales/sales/enums/sales-order-status.enum';
import {
    EnrichedSalesOrder,
    useSalesOrdersV2,
} from 'src/app/_api_adb2c/sales/sales/hooks/use-sales-orders-v2';
import { SalesOrderModel } from 'src/app/_api_adb2c/sales/sales/models/sales-order.model';
import { TableV2 } from 'src/app/components-v2/table-v2';
import { TooltipWrapper } from 'src/app/components-v2/tooltip';
import { generateOrderTitleForList } from 'src/app/utils/generate-order-title';
import { Progress } from 'src/components/ui/progress';
import { PurchaseClearCascade } from '../Purchases/DetailsV2/CascadeV2/modals/purchase-clear-cascade';
import { CascadePreview } from './components/CascadePreview';
import { SalesOrderCascadeUpload } from './components/SalesOrderCascadeUpload';
import { SalesOrderLink } from './components/SalesOrderLink';
import { SalesOrderUpload } from './components/SalesOrderUpload';
import { useDocumentCompletion } from './hooks/useDocumentCompletion';

interface DocumentCompletionCellProps {
    order: EnrichedSalesOrder | SalesOrderModel;
}

const DocumentCompletionCell = ({ order }: DocumentCompletionCellProps) => {
    const completion = useDocumentCompletion(order);

    return (
        <TooltipWrapper
            content={
                <div className='w-full'>
                    <Progress value={completion} />
                </div>
            }
            tooltipContent={`${Math.round(completion)}% complete`}
        />
    );
};

export function SalesOrders() {
    const [searchParams, setSearchParams] = useSearchParams();
    const delegateId = searchParams.get('delegateId') || '';

    const { data, isLoading } = useSalesOrdersV2(delegateId);

    const isLinkMode = searchParams.get('mode') === 'link';
    const isUploadMode = searchParams.get('mode') === 'upload';
    const isCascadeMode = searchParams.get('mode') === 'cascade';
    const isViewCascadeMode = searchParams.get('mode') === 'view-cascade';
    const isDeleteCascadeMode = searchParams.get('mode') === 'delete-cascade';

    const columns = useMemo<ColumnDef<EnrichedSalesOrder>[]>(
        () => [
            {
                id: 'poNumber',
                header: 'PO Number',
                accessorFn: (row) => generateOrderTitleForList(row),
            },
            {
                id: 'case',
                header: 'Case',
                accessorFn: (row) =>
                    row.reference?.find((r) => r.source === 'externalDataId')
                        ?.value,
            },
            {
                id: 'buyer',
                header: 'Buyer',
                accessorFn: (row) => {
                    return row.customer.buyer.name;
                },
            },
            {
                id: 'documentCompletion',
                header: 'Document Completion',
                cell: ({ row }) => (
                    <DocumentCompletionCell order={row.original} />
                ),
            },
            {
                id: 'linkedPurchaseOrders',
                header: 'PO Linked',
                cell: ({ row }) => {
                    return row.original.links?.length || 0;
                },
            },
            {
                id: 'documents',
                header: 'Documents',
                cell: ({ row }) => {
                    return row.original.documents?.length || 0;
                },
            },
            {
                id: 'cascades',
                header: 'Cascade',
                cell: ({ row }) => {
                    const traceCount = row.original.traces?.length || 0;
                    return traceCount > 0 ? (
                        <span
                            className='cursor-pointer text-green-500 hover:underline'
                            onClick={() => {
                                setSearchParams({
                                    mode: 'view-cascade',
                                    order: row.original._id,
                                });
                            }}
                        >
                            Uploaded
                        </span>
                    ) : (
                        <span className='text-red-500'>Not Uploaded</span>
                    );
                },
            },
            {
                id: 'shipments',
                header: 'Shipments',
                cell: ({ row }) => {
                    return row.original.shippedOn?.length || 0;
                },
            },
            {
                id: 'status',
                header: 'Status',
                accessorFn: (row) => SalesOrderStatusLabel[row.status],
            },
            {
                id: 'actions',
                header: 'Actions',
                enableColumnFilter: false,
                cell: ({ row }) => {
                    return (
                        <div className='flex items-center gap-4'>
                            <TooltipWrapper
                                content={
                                    <Link
                                        size={16}
                                        className='cursor-pointer hover:scale-125'
                                        onClick={() =>
                                            setSearchParams({
                                                order: row.original._id,
                                                mode: 'link',
                                            })
                                        }
                                    />
                                }
                                tooltipContent='Link POs'
                            />

                            <TooltipWrapper
                                content={
                                    <Upload
                                        size={16}
                                        className='cursor-pointer hover:scale-125'
                                        onClick={() =>
                                            setSearchParams({
                                                order: row.original._id,
                                                mode: 'upload',
                                            })
                                        }
                                    />
                                }
                                tooltipContent='Upload Documents'
                            />

                            {row.original.traces?.length === 0 ? (
                                <TooltipWrapper
                                    content={
                                        <Sheet
                                            size={16}
                                            className='cursor-pointer hover:scale-125'
                                            onClick={() =>
                                                setSearchParams({
                                                    order: row.original._id,
                                                    mode: 'cascade',
                                                })
                                            }
                                        />
                                    }
                                    tooltipContent='Upload Cascade'
                                />
                            ) : (
                                <TooltipWrapper
                                    content={
                                        <Trash
                                            size={16}
                                            onClick={() =>
                                                setSearchParams({
                                                    mode: 'delete-cascade',
                                                    order: row.original._id,
                                                })
                                            }
                                        />
                                    }
                                    tooltipContent='Delete Cascade'
                                />
                            )}
                        </div>
                    );
                },
            },
        ],
        [setSearchParams]
    );

    return (
        <div className='flex h-full flex-col'>
            <TableV2
                label='Received Orders'
                columns={columns}
                data={data || []}
                isLoading={isLoading}
                controls={{
                    enableDelegation: true,
                    enableSearch: true,
                }}
                disabledTooltipColumns={['actions', 'documentCompletion']}
            />

            {isLinkMode && (
                <SalesOrderLink
                    isOpen={isLinkMode}
                    onClose={() =>
                        setSearchParams((prev) => {
                            prev.delete('mode');
                            prev.delete('order');

                            return prev;
                        })
                    }
                />
            )}

            {isUploadMode && (
                <SalesOrderUpload
                    isOpen={isUploadMode}
                    onClose={() =>
                        setSearchParams((prev) => {
                            prev.delete('mode');
                            prev.delete('order');
                            return prev;
                        })
                    }
                />
            )}

            {isCascadeMode && (
                <SalesOrderCascadeUpload
                    isOpen={isCascadeMode}
                    onClose={() =>
                        setSearchParams((prev) => {
                            prev.delete('mode');
                            prev.delete('order');

                            return prev;
                        })
                    }
                />
            )}

            {isViewCascadeMode && (
                <CascadePreview
                    isOpen={isViewCascadeMode}
                    onClose={() =>
                        setSearchParams((prev) => {
                            prev.delete('mode');
                            prev.delete('order');

                            return prev;
                        })
                    }
                />
            )}

            {isDeleteCascadeMode && (
                <PurchaseClearCascade
                    open={isDeleteCascadeMode}
                    onClose={() =>
                        setSearchParams((prev) => {
                            prev.delete('mode');
                            prev.delete('order');

                            return prev;
                        })
                    }
                    purchase={
                        data?.find((x) => x._id === searchParams.get('order'))
                            ?.purchase?._id || ''
                    }
                    workspaceId={
                        data?.find((x) => x._id === searchParams.get('order'))
                            ?.purchase?.workspace
                    }
                    deleteParent={false}
                />
            )}
        </div>
    );
}
