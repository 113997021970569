import { AppstoreAddOutlined } from '@ant-design/icons';
import { MapContainer, TileLayer } from 'react-leaflet';
import { useNavigate } from 'react-router-dom';
import { useCategories } from 'src/app/_api_adb2c/product/category/hooks/use-categories';
import { useProducts } from 'src/app/_api_adb2c/product/product/hooks/use-products';
import { usePurchases } from 'src/app/_api_adb2c/purchase/purchase/hooks/use-purchases';
import { PurchaseModel } from 'src/app/_api_adb2c/purchase/purchase/models/purchase.model';
import { useSuppliers } from 'src/app/_api_adb2c/purchase/suppliers/hooks/use-suppliers';
import { useSalesOrdersV2 } from 'src/app/_api_adb2c/sales/sales/hooks/use-sales-orders-v2';
import { useRulesets } from 'src/app/_api_adb2c/workspace/ruleset/hooks/use-rulesets';
import { EventCard, EventType } from 'src/app/components-v2/event-card';
import { WidgetCard, WidgetSize } from 'src/app/components-v2/widget';

export const Dashboard = () => {
    const navigate = useNavigate();
    const { data: categories } = useCategories();
    const { data: products } = useProducts();
    const { data: purchaseOrders } = usePurchases({
        page: 0,
        limit: 0,
        draft: true,
    });
    const { data: salesOrders } = useSalesOrdersV2();
    const { data: suppliers } = useSuppliers();
    const { data: rulesets } = useRulesets();

    const getInternalOrderText = (order: PurchaseModel) => {
        return `Internal Order - ${order._id.slice(0, 6)}`;
    };

    const sortedPurchaseOrders = purchaseOrders?.data
        ?.sort(
            (a, b) =>
                new Date(b.createdOn).getTime() -
                new Date(a.createdOn).getTime()
        )
        .slice(0, 4);

    return (
        <>
            <div className='flex h-full flex-col gap-4'>
                <span className='p-1 text-xl font-bold'>Dashboard</span>

                <WidgetCard
                    size={WidgetSize.SMALL}
                    enableAdd={false}
                    widgets={[
                        {
                            name: 'Total Categories',
                            count: categories?.length || 0,
                        },
                        {
                            name: 'Total Products',
                            count: products?.data?.length || 0,
                        },
                        {
                            name: 'Total Purchases',
                            count: purchaseOrders?.pagination.total || 0,
                        },
                        {
                            name: 'Total Sales Orders',
                            count: salesOrders?.length || 0,
                        },
                        {
                            name: 'Total Suppliers',
                            count: suppliers?.length || 0,
                        },
                        {
                            name: 'Total Rulesets',
                            count: rulesets?.length || 0,
                        },
                    ]}
                />

                <span className='text-md font-normal'>Latest Orders</span>

                <EventCard
                    showTag={false}
                    events={(sortedPurchaseOrders || []).map((order) => ({
                        name: 'Purchase Order',
                        value: getInternalOrderText(order),
                        type: EventType.NEW,
                        icon: <AppstoreAddOutlined className='text-2xl ' />,
                        navigateTo: `/purchases/${order._id}`,
                    }))}
                />

                <span className='p-1 text-xl font-semibold'>
                    Supplier Distribution
                </span>

                <div className='flex h-full flex-1 flex-col gap-4'>
                    <div
                        className='relative h-full w-full'
                        onClick={() => navigate('/suppliers')}
                    >
                        <MapContainer
                            className='h-full w-full'
                            zoom={0}
                            center={[0, 0]}
                            attributionControl={false}
                            scrollWheelZoom={false}
                            boundsOptions={{ padding: [50, 50] }}
                            minZoom={2}
                        >
                            <TileLayer
                                attribution='&amp;copy <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors'
                                url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                            />
                        </MapContainer>
                    </div>
                </div>
            </div>
        </>
    );
};
