import { Upload } from 'lucide-react';
import { useMemo } from 'react';
import { FileUploader } from 'src/app/components-v2/file-dropzone';
import { Select } from 'src/app/components-v2/select';
import { TableV2 } from 'src/app/components-v2/table-v2';
import { ColumnDef } from '@tanstack/react-table';
import { Trash2 } from 'lucide-react';

interface OptionalFileWithType {
    file: File;
    preview?: string;
    documentType?: string;
}

interface OptionalDocumentsSectionProps {
    availableTypes: { value: string; label: string }[];
    uploadedFiles: OptionalFileWithType[];
    onUpload: (files: File[]) => void;
    onRemove: (index: number) => void;
    onTypeSelect: (index: number, type: string) => void;
}

const getOptionalDocumentsColumns = (
    onTypeSelect: (index: number, type: string) => void,
    onRemove: (index: number) => void,
    availableTypes: { value: string; label: string }[]
): ColumnDef<OptionalFileWithType>[] => [
    {
        id: 'fileName',
        header: 'File Name',
        cell: ({ row }) => (
            <div className='text-xs'>{row.original.file.name}</div>
        ),
    },
    {
        id: 'documentType',
        header: 'Document Type',
        cell: ({ row }) => (
            <Select
                value={row.original.documentType || ''}
                options={availableTypes}
                setValue={(value) => onTypeSelect(row.index, value)}
                placeholder='Select document type'
            />
        ),
    },
    {
        id: 'actions',
        header: 'Actions',
        cell: ({ row }) => (
            <button
                onClick={() => onRemove(row.index)}
                className='text-red-600 hover:text-red-800'
                title='Remove'
            >
                <Trash2 className='h-4 w-4' />
            </button>
        ),
    },
];

export const OptionalDocumentsSection: React.FC<
    OptionalDocumentsSectionProps
> = ({ availableTypes, uploadedFiles, onUpload, onRemove, onTypeSelect }) => {
    const columns = useMemo(
        () =>
            getOptionalDocumentsColumns(onTypeSelect, onRemove, availableTypes),
        [onTypeSelect, onRemove, availableTypes]
    );

    return (
        <div className='flex flex-1 flex-col gap-4'>
            <div className='rounded-lg border-2 border-dashed border-gray-300 bg-white'>
                <FileUploader
                    value={null}
                    onValueChange={(files: File[] | null) => {
                        if (files) {
                            onUpload(files);
                        }
                    }}
                    dropzoneOptions={{
                        maxFiles: 5,
                        maxSize: 10 * 1024 * 1024,
                        accept: {
                            'application/pdf': ['.pdf'],
                            'image/*': ['.png', '.jpg', '.jpeg'],
                            'application/msword': ['.doc'],
                            'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                                ['.docx'],
                        },
                    }}
                >
                    <div className='flex flex-col items-center justify-center gap-4 p-12'>
                        <div className='rounded-full bg-gray-50 p-4'>
                            <Upload className='h-8 w-8 text-gray-400' />
                        </div>
                        <div className='text-center text-xs'>
                            <p className='text-xs font-medium text-gray-700'>
                                Drop files here or click to upload
                            </p>
                            <p className='text-xs text-gray-500'>
                                Supports PDF, PNG, JPG, DOC, DOCX files up to
                                10MB
                            </p>
                        </div>
                    </div>
                </FileUploader>
            </div>

            {uploadedFiles.length > 0 && (
                <TableV2
                    columns={columns}
                    data={uploadedFiles || []}
                    disableTooltips
                    fixedHeight='300px'
                />
            )}
        </div>
    );
};
