import { CloudUpload, Paperclip } from 'lucide-react';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useMaterial } from 'src/app/_api_adb2c/product/material/hooks/use-material';
import { BaseDialog } from 'src/app/components-v2/base-dialog';
import {
    FileInput,
    FileUploader,
    FileUploaderContent,
    FileUploaderItem,
} from 'src/app/components-v2/file-dropzone';
import { ImportErrorMessage } from 'src/app/pages/Purchases/Details/purchase-cascade-import-error';
import { dropZoneConfig } from '../substance-sds-file-upload';
import { useImportSubstance } from './use-substance-import';

interface Props {
    open: boolean;
    materialId: string;
    onClose: () => void;
}

export function SubstanceImport({ open, materialId, onClose }: Props) {
    const [searchParams] = useSearchParams();
    const delegateId = searchParams.get('delegateId') || '';

    const [files, setFiles] = useState<File[] | null>(null);
    const [errorMessages, setErrorMessages] = useState<ImportErrorMessage[]>(
        []
    );

    const { data } = useMaterial(materialId, delegateId);
    const { submit, isLoading } = useImportSubstance();

    const triggerSubmit = async () => {
        if (!files || files.length < 1) return;

        try {
            await submit(materialId, files[0], delegateId);
        } catch (err) {
            const error = err as ImportErrorMessage[];

            setErrorMessages(error);
        }
    };

    if (!data) return <></>;

    return (
        <>
            <BaseDialog
                open={open}
                onClose={onClose}
                title='Import Substance'
                onConfirm={triggerSubmit}
                isLoading={isLoading}
            >
                <FileUploader
                    value={files}
                    onValueChange={setFiles}
                    dropzoneOptions={dropZoneConfig}
                    reSelect
                >
                    <FileInput className='group h-full min-h-fit border border-dashed border-gray-400 hover:bg-gray-200'>
                        <div className='flex h-full w-full flex-col items-center justify-center gap-4 pb-4 pt-3 text-center '>
                            <CloudUpload size={30} />
                            <span className='text-xs text-gray-500'>
                                Click to SELECT FILE or DRAG & DROP FILES here
                                <br />
                                Only files in{' '}
                                <span className='font-bold'>
                                    PDF, TIFF or JPEG
                                </span>{' '}
                                format will be accepted
                                <br />
                                Max File Size: 10MB
                            </span>
                        </div>
                    </FileInput>

                    <FileUploaderContent>
                        {files &&
                            files.length > 0 &&
                            files.map((file, i) => (
                                <FileUploaderItem key={i} index={i}>
                                    <Paperclip className='h-4 w-4 stroke-current' />
                                    <span>{file.name}</span>
                                </FileUploaderItem>
                            ))}
                    </FileUploaderContent>
                </FileUploader>
            </BaseDialog>

            {errorMessages.length > 0 && (
                <BaseDialog
                    open={open}
                    onClose={() => setErrorMessages([])}
                    title='Import Substance'
                    showConfirmButton={false}
                    isLoading={isLoading}
                >
                    <div className='flex flex-col gap-4'>
                        {errorMessages.map((error, index) => (
                            <div key={index} className='flex gap-2'>
                                <span className='text-xs font-bold'>
                                    {error.rowNum}
                                </span>
                                <span className='text-xs'>{error.message}</span>
                            </div>
                        ))}
                    </div>
                </BaseDialog>
            )}
        </>
    );
}
