import axios from 'axios';
import JSZip from 'jszip';
import { Download, Edit, Loader2, Sheet, Trash } from 'lucide-react';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import {
    ProductUom,
    ProductUomLabels,
} from 'src/app/_api_adb2c/purchase/purchase/enums/product-uom.enum';
import { PurchaseOrderNatureLabels } from 'src/app/_api_adb2c/purchase/purchase/enums/purchase-order-nature.enum';
import {
    PurchaseOrderStatus,
    PurchaseOrderStatusLabels,
} from 'src/app/_api_adb2c/purchase/purchase/enums/purchase-order-status.enum';
import { usePurchase } from 'src/app/_api_adb2c/purchase/purchase/hooks/use-purchase';
import { usePurchaseInformation } from 'src/app/_api_adb2c/purchase/purchase/hooks/use-purchase-information';
import { usePurchaseTrace } from 'src/app/_api_adb2c/purchase/purchase/hooks/use-purchase-trace';
import {
    PurchaseModel,
    PurchaseOrderTraceModel,
} from 'src/app/_api_adb2c/purchase/purchase/models/purchase.model';
import { useSalesOrderService } from 'src/app/_api_adb2c/sales/sales/hooks/use-sales-order-service';
import { SalesOrderModel } from 'src/app/_api_adb2c/sales/sales/models/sales-order.model';
import { DocumentModel } from 'src/app/_api_adb2c/workspace/documents/document.model';
import { useBatchRulesets } from 'src/app/_api_adb2c/workspace/ruleset/hooks/use-batch-rulesets';
import { useRulesetService } from 'src/app/_api_adb2c/workspace/ruleset/hooks/use-ruleset-service';
import {
    SupplyChainNodeType,
    SupplyChainNodeTypeLabel,
} from 'src/app/_api_adb2c/workspace/shared/enum/supply-chain-node-type.enum';
import { WorkspaceModel } from 'src/app/_api_adb2c/workspace/workspace/workspace.model';
import { DetailLabel } from 'src/app/components-v2/detail-label';
import { TooltipWrapper } from 'src/app/components/TooltipWrapper';
import { useDownload } from 'src/app/pages/Materials/Details/Substance';
import { useBreadcrumbStore } from 'src/app/stores/breadcrumb-store';
import { useContextStore } from 'src/app/stores/context-store';
import { generateOrderTitle } from 'src/app/utils/generate-order-title';
import CascadeImage from 'src/assets/cascade.png';
import DocumentImage from 'src/assets/document.png';
import ProductImage from 'src/assets/products.png';
import SubstanceImage from 'src/assets/substance.png';
import TrackingImage from 'src/assets/tracking.png';
import { Button } from 'src/components/ui/button';
import { Progress } from 'src/components/ui/progress';
import {
    Tabs,
    TabsContent,
    TabsList,
    TabsTrigger,
} from 'src/components/ui/tabs';
import { getFileIdFromAssetName } from 'src/infrastructure/utils/extract-asset-id';
import { cn } from 'src/lib/utils';
import { PurchaseTracking } from '../Details/Tracking/purchase-tracking';
import { PurchaseCascadeV2 } from './CascadeV2';
import { DeletePurchase } from './delete-purchase';
import { PurchaseDocumentsV2 } from './DocumentsV2';
import { EditPurchase } from './edit-purchase';
import { PurchaseManifest } from './Manifest';
import { PurchaseCascadeImport } from './purchase-cascade-import';
import { PurchaseSubstanceV2 } from './Substance/purchase-substance-v2';

enum MenuOptions {
    MANIFEST = 'manifest',
    DOCUMENTS = 'documents',
    CASCADE = 'cascade',
    TRACKING = 'tracking',
    SUBSTANCE = 'substance',
}

export function PurchaseDetailV2() {
    const { id } = useParams<{ id: string }>();
    const [searchParams, setSearchParams] = useSearchParams();
    const [percentage, setPercentage] = useState(0);
    const [maxHeight, setMaxHeight] = useState(0);
    const tabRef = useRef<HTMLDivElement>(null);
    const [action, setAction] = useState<'edit' | 'delete' | 'import'>();
    const [isDownloading, setIsDownloading] = useState(false);

    const delegateId = searchParams.get('delegateId') || '';
    const context = useContextStore();
    const { setBreadcrumbs } = useBreadcrumbStore();

    const { data: purchase } = usePurchase(id || '', delegateId);
    const { data: traces } = usePurchaseTrace(id || '', delegateId || '');

    const rulesetId = useMemo(() => {
        if (!purchase) return '';

        return purchase.rules?.[0];
    }, [purchase]);

    const { data: rulesets } = useBatchRulesets(rulesetId ? [rulesetId] : []);

    const ruleset = useMemo(() => {
        if (!rulesets) return undefined;

        return rulesets.find((x) => x._id === rulesetId);
    }, [rulesetId, rulesets]);

    const { service: rulesetService } = useRulesetService();
    const { service: salesService } = useSalesOrderService();

    const isDeletable = useMemo(() => {
        if (!purchase) return false;

        if (
            purchase.relatedPurchaseOrders &&
            purchase.relatedPurchaseOrders.length > 0
        )
            return false;

        return true;
    }, [purchase]);

    const itemUnitOfMeasure = useMemo(() => {
        if (!purchase) return 'PCS';

        const version = purchase?.versions?.[purchase.versions.length - 1];
        const item = version?.manifest?.[0]?.product;

        if (!item) return 'PCS';

        return ProductUomLabels[item.unitOfMeasurement as ProductUom];
    }, [purchase]);

    const orderInformation = useMemo(() => {
        const version = purchase?.versions?.[purchase.versions.length - 1];

        if (!version)
            return {
                totalCost: 0,
                totalItems: 0,
            };

        const totalCost = version.manifest.reduce(
            (acc, item) => acc + item.ppu * item.quantity,
            0
        );

        const totalItems = version.manifest.reduce(
            (acc, item) => acc + item.quantity,
            0
        );

        return {
            totalCost: totalCost.toFixed(2),
            totalItems: totalItems,
        };
    }, [purchase]);

    const tierInformation = useMemo(() => {
        if (!traces) return 1;

        // Fetch the deepest depth of traces, adding 2 to account for the first tier and how depth is returned
        return Math.max(1, ...traces.map((x) => x.depth + 2));
    }, [traces]);

    const calculateDocReadiness = useCallback(async () => {
        if (!purchase) return;

        // Get the ruleset for the purchase order
        const rulesets = await rulesetService.batch({
            targets: purchase.rules,
        });

        // Find the ruleset for the purchase order
        const ruleset = rulesets?.find((x) => x._id === purchase.rules?.[0]);

        if (!ruleset) return;

        // Get the sales orders for the purchase orders
        const salesOrders: SalesOrderModel[] = await salesService.batch(
            delegateId || context.workspace?._id || '',
            { targets: [purchase._id, ...(traces || []).map((x) => x._id)] },
            '1'
        );

        const consolidatedPurchases: {
            purchase?: PurchaseModel | PurchaseOrderTraceModel;
            order?: SalesOrderModel;
            _id: string;
        }[] = [];

        // Consolidating steps
        const parentSalesOrder = salesOrders?.find(
            (x) => x.purchaseOrderId === purchase._id
        );
        consolidatedPurchases.push({
            purchase: purchase,
            order: parentSalesOrder,
            _id: purchase._id,
        });

        traces?.forEach((trace) => {
            const salesOrder = salesOrders?.find(
                (x) => x.purchaseOrderId === trace._id
            );
            consolidatedPurchases.push({
                purchase: trace,
                order: salesOrder,
                _id: trace._id,
            });
        });

        let totalDocuments = 0;
        let uploadedDocuments = 0;

        consolidatedPurchases.forEach((item) => {
            const operations = item.purchase?.processes || [];

            const requiredTypes = ruleset.rules.filter((rule) => {
                const required = operations.filter((operation) => {
                    return rule.processes.includes(operation);
                });

                return required.length > 0;
            });

            totalDocuments += requiredTypes.length;

            requiredTypes.forEach((rule) => {
                const file = item.order?.documents?.some((doc) => {
                    return doc.type === rule.document;
                });

                if (file) {
                    uploadedDocuments += 1;
                }
            });
        });

        if (!totalDocuments && !uploadedDocuments) {
            setPercentage(0);
            return;
        }

        const percentage = Math.round(
            (uploadedDocuments / totalDocuments) * 100
        );

        setPercentage(percentage);
    }, [purchase, rulesetService, traces, salesService, context, delegateId]);

    useEffect(() => {
        calculateDocReadiness();
    }, [calculateDocReadiness]);

    useEffect(() => {
        const updateTableHeight = () => {
            if (tabRef.current) {
                const parentElement = tabRef.current.parentElement;
                const viewportHeight = window.innerHeight;
                const parentTopOffset =
                    parentElement?.getBoundingClientRect().top || 0;
                const availableHeight = viewportHeight - parentTopOffset - 70;

                if (availableHeight !== maxHeight) {
                    setMaxHeight(availableHeight);
                }
            }
        };

        updateTableHeight();

        const resizeObserver = new ResizeObserver(updateTableHeight);
        if (tabRef.current && tabRef.current.parentElement) {
            resizeObserver.observe(tabRef.current.parentElement);
        }

        return () => {
            resizeObserver.disconnect();
        };
    }, [maxHeight]);

    const menuOptions = [
        {
            title: MenuOptions.MANIFEST,
            icon: ProductImage,
            alt: 'Manifest',
            content: <PurchaseManifest purchaseId={id || ''} />,
        },
        {
            title: MenuOptions.DOCUMENTS,
            icon: DocumentImage,
            alt: 'Documents',
            content: <PurchaseDocumentsV2 purchaseId={id || ''} />,
        },
        {
            title: MenuOptions.CASCADE,
            icon: CascadeImage,
            alt: 'Cascade',
            content: <PurchaseCascadeV2 purchaseId={id || ''} />,
        },
        {
            title: MenuOptions.TRACKING,
            icon: TrackingImage,
            alt: 'Tracking',
            // content: <PurchaseTracking purchaseId={id || ''} />,
            content: (
                <>
                    {purchase && traces && (
                        <PurchaseTracking data={purchase} traces={traces} />
                    )}
                </>
            ),
        },
        {
            title: MenuOptions.SUBSTANCE,
            icon: SubstanceImage,
            alt: 'Substance',
            content: <PurchaseSubstanceV2 purchaseId={id || ''} />,
        },
    ];

    useEffect(() => {
        if (!purchase) return;

        setBreadcrumbs([
            { label: 'Purchases', navigateTo: '/purchases' },
            { label: generateOrderTitle(purchase) },
        ]);
    }, [purchase, setBreadcrumbs]);

    const switchMenu = (value: string) => {
        setSearchParams((prev) => {
            prev.set('menu', value);
            return prev;
        });
    };

    useEffect(() => {
        if (!searchParams.get('menu')) {
            setSearchParams((prev) => {
                prev.set('menu', MenuOptions.MANIFEST);
                return prev;
            });
        }
    }, [searchParams, setSearchParams]);

    const { download } = useDownload();

    const { data: purchaseInformation } = usePurchaseInformation(
        id || '',
        delegateId,
        !!id
    );

    const downloadAllDocuments = useCallback(async () => {
        if (!purchase || !traces || isDownloading) return;

        setIsDownloading(true);
        try {
            const zip = new JSZip();
            const allDocuments: { id: string; name: string; tier: number }[] =
                [];

            // Get company documents - These will go in Tier 1
            if (typeof purchase.workspace !== 'string') {
                const workspace = purchase.workspace as WorkspaceModel;
                workspace?.library?.documents?.forEach((doc: DocumentModel) => {
                    if (doc.file) {
                        allDocuments.push({
                            id: doc.file,
                            name: doc.description || 'company-document',
                            tier: 1,
                        });
                    }
                });
            }

            // Get product and material documents from purchase (Tier 1) and traces (subsequent tiers)
            const allPurchases = [purchase, ...(traces || [])];
            allPurchases.forEach((p, index) => {
                // If index is 0, it's the parent purchase (Tier 1)
                // For traces, find the matching trace and get its depth + 2, defaulting to 1 if not found
                const matchingTrace =
                    index === 0
                        ? null
                        : (traces || []).find((t) => t._id === p._id);
                const tier = index === 0 ? 1 : (matchingTrace?.depth ?? -1) + 2;
                const version = p.versions[p.versions.length - 1];
                if (!version) return;

                version.manifest.forEach((item) => {
                    // Product documents
                    item.product?.documents?.forEach((doc) => {
                        if (doc.file?.assetName) {
                            const fileId = getFileIdFromAssetName(
                                doc.file.assetName
                            );
                            allDocuments.push({
                                id: fileId,
                                name: doc.file.originalName,
                                tier,
                            });
                        }
                    });

                    // Material documents from bill of materials
                    const latestProduct = item.product?.versions?.find(
                        (x) => x.version === item.version
                    );
                    latestProduct?.billOfMaterials?.forEach((bill) => {
                        bill.material.documents?.forEach((doc) => {
                            if (doc.file?.assetName) {
                                const fileId = getFileIdFromAssetName(
                                    doc.file.assetName
                                );
                                allDocuments.push({
                                    id: fileId,
                                    name: doc.file.originalName,
                                    tier,
                                });
                            }
                        });
                    });
                });
            });

            // Get support documents
            allPurchases.forEach((p, index) => {
                const matchingTrace =
                    index === 0
                        ? null
                        : (traces || []).find((t) => t._id === p._id);
                const tier = index === 0 ? 1 : (matchingTrace?.depth ?? -1) + 2;
                const salesOrder = purchaseInformation?.salesOrders?.[p._id];
                salesOrder?.documents?.forEach((doc) => {
                    if (doc.file?.assetName) {
                        const fileId = getFileIdFromAssetName(
                            doc.file.assetName
                        );
                        allDocuments.push({
                            id: fileId,
                            name: doc.file.originalName,
                            tier,
                        });
                    }
                });
            });

            for (const doc of allDocuments) {
                try {
                    const response = await download(doc.id, doc.name, false);

                    const blob = await axios.get(response.token, {
                        responseType: 'blob',
                    });

                    // Create a folder for each tier and place documents inside
                    const folderPath = `Tier ${doc.tier}/${doc.name}`;
                    zip.file(folderPath, blob.data);
                } catch (error) {
                    console.error(`Failed to download ${doc.name}:`, error);
                }
            }

            // Generate the ZIP file
            const content = await zip.generateAsync({ type: 'blob' });

            // Create a formatted timestamp
            const timestamp = DateTime.now().toFormat('yyyyMMdd_HHmmss');
            const orderName = generateOrderTitle(purchase).replace(
                /[^a-zA-Z0-9]/g,
                '_'
            );
            const zipFileName = `${orderName}_${timestamp}_documents.zip`;

            // Create a download link and trigger download
            const url = window.URL.createObjectURL(content);
            const link = document.createElement('a');
            link.href = url;
            link.download = zipFileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Failed to create ZIP file:', error);
        } finally {
            setIsDownloading(false);
        }
    }, [purchase, traces, purchaseInformation, download, isDownloading]);

    if (!id) return <></>;

    return (
        <>
            <div className='flex h-full flex-col gap-8'>
                <div className='grid grid-cols-12 gap-4'>
                    <div className='col-span-12'>
                        <span className='text-md font-bold'>
                            {purchase
                                ? `${generateOrderTitle(purchase)} (${
                                      PurchaseOrderStatusLabels[
                                          purchase?.status as PurchaseOrderStatus
                                      ]
                                  })`
                                : '--'}
                        </span>
                    </div>

                    <div className='col-span-8 grid grid-cols-1 gap-4 rounded-lg border border-gray-200 bg-white px-4 py-4'>
                        <div className='flex w-full items-center justify-between'>
                            <span className='text-sm font-bold underline decoration-red-800 decoration-2 underline-offset-8'>
                                General Information
                            </span>

                            <div className='flex gap-4'>
                                <TooltipWrapper label='Import Cascade'>
                                    <Sheet
                                        size={16}
                                        className='cursor-pointer hover:scale-125'
                                        onClick={() => setAction('import')}
                                    />
                                </TooltipWrapper>

                                <TooltipWrapper label='Edit Purchase'>
                                    <Edit
                                        size={16}
                                        className='cursor-pointer hover:scale-125'
                                        onClick={() => setAction('edit')}
                                    />
                                </TooltipWrapper>

                                <TooltipWrapper label='Delete Purchase'>
                                    <Trash
                                        size={16}
                                        className={cn(
                                            'cursor-pointer hover:scale-125',
                                            !isDeletable &&
                                                'cursor-not-allowed hover:scale-125'
                                        )}
                                        onClick={() => {
                                            if (!isDeletable) return;
                                            setAction('delete');
                                        }}
                                    />
                                </TooltipWrapper>
                            </div>
                        </div>

                        <div className='grid grid-cols-3 gap-4'>
                            <DetailLabel title='Supplier'>
                                {purchase?.supplier.seller.name}
                            </DetailLabel>

                            <DetailLabel title='Issued On'>
                                {purchase?.createdOn
                                    ? DateTime.fromJSDate(
                                          new Date(purchase.createdOn)
                                      ).toFormat('yyyy-MM-dd')
                                    : '--'}
                            </DetailLabel>

                            <DetailLabel title='Ruleset Applied'>
                                {ruleset?.name || '--'}
                            </DetailLabel>

                            <DetailLabel title='Type of Order'>
                                {purchase?.nature
                                    ? PurchaseOrderNatureLabels[purchase.nature]
                                    : '--'}
                            </DetailLabel>

                            <DetailLabel title='1st Delivery (Expected)'>
                                {purchase?.shippedOn &&
                                purchase.shippedOn.length > 0
                                    ? DateTime.fromJSDate(
                                          new Date(purchase.shippedOn[0])
                                      ).toFormat('yyyy-MM-dd')
                                    : '--'}
                            </DetailLabel>

                            <DetailLabel title='Address'>
                                {purchase?.shipToAddress || '--'}
                            </DetailLabel>

                            <DetailLabel title='Currency'>
                                {purchase?.currency}
                            </DetailLabel>

                            <DetailLabel title='Related Processes'>
                                <div className='flex max-h-[48px] flex-wrap gap-2 overflow-auto'>
                                    {purchase?.processes.map((process) => (
                                        <span className='inline-flex items-center rounded-md bg-gray-100 px-1.5 py-0.5 text-xs font-medium text-gray-600'>
                                            {
                                                SupplyChainNodeTypeLabel[
                                                    process as SupplyChainNodeType
                                                ]
                                            }
                                        </span>
                                    ))}
                                </div>
                            </DetailLabel>

                            <DetailLabel title='Shipment Dates'>
                                {purchase?.shippedOn &&
                                purchase.shippedOn.length > 0
                                    ? DateTime.fromJSDate(
                                          new Date(purchase.shippedOn[0])
                                      ).toFormat('yyyy-MM-dd')
                                    : '--'}
                            </DetailLabel>
                        </div>
                    </div>

                    <div className='col-span-4 grid grid-cols-2 gap-4'>
                        <div className='flex w-full flex-col justify-between rounded-lg border border-gray-200 bg-white px-4 py-2'>
                            <span className='text-xs'>Order Total</span>

                            <span className='text-end text-xl font-bold'>
                                {orderInformation.totalCost}
                            </span>
                            <span className='text-end text-xs font-extralight'>
                                USD
                            </span>
                        </div>

                        <div className='flex w-full flex-col justify-between rounded-lg border border-gray-200 bg-white px-4 py-2'>
                            <span className='text-xs'>Order Quantities</span>

                            <span className='text-end text-xl font-bold'>
                                {orderInformation.totalItems}
                            </span>
                            <span className='text-end text-xs font-extralight'>
                                {itemUnitOfMeasure}
                            </span>
                        </div>

                        <div className='flex w-full flex-col justify-between rounded-lg border border-gray-200 bg-white px-4 py-2'>
                            <span className='text-xs'>Cascade Levels</span>

                            <span className='text-end text-xl font-bold'>
                                {tierInformation}
                            </span>
                            <span className='text-end text-xs font-extralight uppercase'>
                                Tiers
                            </span>
                        </div>

                        <div className='flex w-full flex-col justify-between rounded-lg border border-gray-200 bg-white px-4 py-2'>
                            <span className='text-xs'>Doc Readiness</span>

                            <span className='text-end text-xl font-bold'>
                                {percentage}%
                                <Progress value={percentage} />
                            </span>
                            <span className='text-end text-xs font-extralight uppercase'>
                                Completed
                            </span>
                        </div>
                    </div>
                </div>

                <div className='grid h-full flex-1 grid-cols-1'>
                    <Tabs
                        value={searchParams.get('menu') || MenuOptions.MANIFEST}
                        className='flex w-full flex-col gap-2'
                        onValueChange={switchMenu}
                        ref={tabRef}
                    >
                        <div className='flex w-full items-center justify-between'>
                            <TabsList className='w-full justify-start'>
                                {menuOptions.map((x, index) => {
                                    return (
                                        <TabsTrigger
                                            value={x.title}
                                            className='w-24'
                                        >
                                            <figure className='flex cursor-pointer flex-col items-center rounded capitalize'>
                                                <img
                                                    src={x.icon}
                                                    alt={x.alt}
                                                    className='max-w-6 max-h-6 rounded-l p-1'
                                                    key={index}
                                                    id={`${
                                                        x.alt
                                                    }-${index.toString()}`}
                                                />
                                                <figcaption className='text-sm text-gray-500 dark:text-gray-400'>
                                                    {x.title}
                                                </figcaption>
                                            </figure>
                                        </TabsTrigger>
                                    );
                                })}
                            </TabsList>

                            <div>
                                <TooltipWrapper label='Download All Documents'>
                                    <Button
                                        variant='outline'
                                        onClick={downloadAllDocuments}
                                        disabled={!purchase || isDownloading}
                                        className='min-w-[32px]'
                                    >
                                        {isDownloading ? (
                                            <Loader2
                                                size={16}
                                                className='animate-spin'
                                            />
                                        ) : (
                                            <Download size={16} />
                                        )}
                                    </Button>
                                </TooltipWrapper>
                            </div>
                        </div>

                        {menuOptions.map((x) => {
                            return (
                                <TabsContent
                                    key={`menu-${x.title}`}
                                    value={x.title}
                                    className='flex-1 overflow-auto rounded-lg'
                                    style={{
                                        maxHeight:
                                            maxHeight > 280
                                                ? `${maxHeight}px`
                                                : 'auto',
                                    }}
                                >
                                    {x.content}
                                </TabsContent>
                            );
                        })}
                    </Tabs>
                </div>
            </div>

            {action === 'edit' && (
                <EditPurchase
                    purchaseId={id || ''}
                    open={action === 'edit'}
                    onClose={() => setAction(undefined)}
                />
            )}

            {action === 'delete' && (
                <DeletePurchase
                    purchaseId={id || ''}
                    open={action === 'delete'}
                    onClose={() => setAction(undefined)}
                />
            )}

            {action === 'import' && (
                <PurchaseCascadeImport
                    open={action === 'import'}
                    onClose={() => setAction(undefined)}
                />
            )}
        </>
    );
}
