export enum SalesOrderStatus {
    CLOSED = 'closed',
    COLLECTED = 'collected',
    DELIVERED = 'delivered',
    DRAFT = 'draft',
    RELEASED = 'released',
}

export const SalesOrderStatusLabel = {
    [SalesOrderStatus.CLOSED]: 'Closed',
    [SalesOrderStatus.COLLECTED]: 'Collected',
    [SalesOrderStatus.DELIVERED]: 'Delivered',
    [SalesOrderStatus.DRAFT]: 'Draft',
    [SalesOrderStatus.RELEASED]: 'Released',
};
