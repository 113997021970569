import { Download, RotateCcw, Trash2 } from 'lucide-react';
import { SalesOrderDocModel } from 'src/app/_api_adb2c/sales/sales/models/sales-order-doc.model';
import { cn } from 'src/lib/utils';

interface FileListItemProps {
    file: SalesOrderDocModel;
    index: number;
    filesToDelete: string[];
    downloadingFile: string | null;
    onDownload: (file: SalesOrderDocModel) => void;
    onDelete: (assetName: string) => void;
    onUndoDelete: (assetName: string) => void;
}

export const FileListItem = ({
    file,
    index,
    filesToDelete,
    downloadingFile,
    onDownload,
    onDelete,
    onUndoDelete,
}: FileListItemProps) => {
    const isPending = file.file.assetName.startsWith('temp-');
    const isDeleting = filesToDelete.includes(file.file.assetName);
    const isDownloading = downloadingFile === file.file.assetName;

    return (
        <div
            className={cn(
                'flex flex-col gap-1 border-b border-gray-200 pb-2',
                isDeleting && 'opacity-50'
            )}
        >
            <div className='flex items-center justify-between gap-2'>
                <div className='flex items-center gap-2'>
                    <span className='font-medium'>
                        {file.file.originalName || `File ${index + 1}`}
                    </span>
                    {isPending && (
                        <span className='rounded-full bg-yellow-50 px-2 py-0.5 text-xs text-yellow-600'>
                            Pending Upload
                        </span>
                    )}
                </div>
                <div className='flex items-center gap-2'>
                    <button
                        onClick={() => onDownload(file)}
                        className='text-gray-600 hover:text-gray-800 disabled:cursor-not-allowed disabled:opacity-50'
                        title={
                            isPending
                                ? 'File is pending upload'
                                : 'Download file'
                        }
                        disabled={isDownloading || isPending}
                    >
                        {isDownloading ? (
                            <div className='h-4 w-4 animate-spin rounded-full border-2 border-gray-600 border-t-transparent' />
                        ) : (
                            <Download className='h-4 w-4' />
                        )}
                    </button>
                    {isDeleting ? (
                        <button
                            onClick={() => onUndoDelete(file.file.assetName)}
                            className='text-blue-600 hover:text-blue-800'
                            title='Undo delete'
                        >
                            <RotateCcw className='h-4 w-4' />
                        </button>
                    ) : (
                        <button
                            onClick={() => onDelete(file.file.assetName)}
                            className='text-red-600 hover:text-red-800'
                            title='Mark for deletion'
                        >
                            <Trash2 className='h-4 w-4' />
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

interface PendingFileItemProps {
    file: { file: File; preview?: string };
    onRemove: () => void;
}

export const PendingFileItem = ({ file, onRemove }: PendingFileItemProps) => (
    <div className='flex flex-col gap-1 border-b border-gray-200 pb-2'>
        <div className='flex items-center justify-between gap-2'>
            <span className='text-gray-600'>{file.file.name} (Pending)</span>
            <button
                onClick={onRemove}
                className='text-red-600 hover:text-red-800'
                title='Remove'
            >
                <Trash2 className='h-4 w-4' />
            </button>
        </div>
    </div>
);
