import { Upload } from 'lucide-react';
import { useDropzone, DropzoneOptions } from 'react-dropzone';
import { cn } from 'src/lib/utils';

interface DropzoneV2Props {
    onDrop: (files: File[]) => void;
    options?: Partial<DropzoneOptions>;
    className?: string;
}

export const DropzoneV2 = ({ onDrop, options, className }: DropzoneV2Props) => {
    const { getRootProps, getInputProps, isDragActive, isDragReject } =
        useDropzone({
            onDrop,
            accept: {
                'application/pdf': ['.pdf'],
                'image/*': ['.png', '.jpg', '.jpeg'],
                'application/msword': ['.doc'],
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                    ['.docx'],
            },
            maxFiles: 5,
            maxSize: 10 * 1024 * 1024, // 10MB
            ...options,
        });

    return (
        <div
            {...getRootProps()}
            className={cn(
                'flex cursor-pointer flex-col items-center justify-center gap-4 rounded-lg border-2 border-dashed p-12 transition-colors',
                isDragActive && 'border-blue-500 bg-blue-50',
                isDragReject && 'border-red-500 bg-red-50',
                !isDragActive &&
                    !isDragReject &&
                    'border-gray-300 hover:border-gray-400',
                className
            )}
        >
            <input {...getInputProps()} />
            <div className='rounded-full bg-gray-50 p-4'>
                <Upload className='h-8 w-8 text-gray-400' />
            </div>
            <div className='text-center text-xs'>
                <p className='text-xs font-medium text-gray-700'>
                    {isDragActive
                        ? 'Drop the files here...'
                        : 'Drop files here or click to upload'}
                </p>
                <p className='text-xs text-gray-500'>
                    Supports PDF, PNG, JPG, DOC, DOCX files up to 10MB
                </p>
            </div>
        </div>
    );
};
