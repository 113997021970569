import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useCreateProduct } from 'src/app/_api_adb2c/product/product/hooks/use-create-product';
import { IExternalReference } from 'src/app/_api_adb2c/product/product/models/product.model';
import { DialogV2 } from 'src/app/components-v2/dialog-v2';
import { DelegationInput } from 'src/app/components/Form/DelegationInput';
import { PhotoGrid } from 'src/app/components/PhotoGrid';
import { useContextStore } from 'src/app/stores/context-store';
import { getFileIdFromAssetName } from 'src/infrastructure/utils/extract-asset-id';
import { z } from 'zod';
import { AddProductOptionalFields } from './add-product-optional-fields';
import { AddProductRequiredFields } from './add-product-required-fields';

interface Props {
    open: boolean;
    onClose: () => void;
}

const formScheme = z.object({
    name: z.string(),
    delegateId: z.string().optional(),
    base: z.string(),
    category: z.string(),
    unit: z.string(),
    measureValue: z.coerce.number(),
    isActive: z.boolean().default(true),
    description: z.string().optional(),
    weight: z.coerce.number().optional(),
    color: z.string().optional(),
    externalDataId: z.string().optional(),
    hsCode: z.string().optional(),
    sku: z.string().optional(),
    upc: z.string().optional(),
    externalReferenceCode: z.string().optional(),
    collection: z.string().optional(),
    moqCode: z.string().optional(),
    photos: z
        .array(
            z.object({
                id: z.string(),
                url: z.string(),
                file: z.any().optional(),
                contentType: z.string().optional(),
                size: z.number().optional(),
                name: z.string().optional(),
            })
        )
        .default([]),
});

export const AddProduct = ({ open, onClose }: Props) => {
    const { workspace } = useContextStore();
    const { mutateAsync, isLoading } = useCreateProduct();

    const form = useForm<z.infer<typeof formScheme>>({
        mode: 'onChange',
        resolver: zodResolver(formScheme),
        defaultValues: {
            photos: [],
        },
    });

    const photos = form.watch('photos');
    const delegateId = form.watch('delegateId');

    const reset = () => {
        onClose();
        form.reset();
    };

    const onSubmit = async (data: z.infer<typeof formScheme>) => {
        const externalRefeneces: IExternalReference[] = [];

        if (data.externalDataId) {
            externalRefeneces.push({
                source: 'externalDataId',
                value: data.externalDataId,
            });
            externalRefeneces.push({
                source: 'xts',
                value: data.externalDataId,
            });
        }

        if (data.externalReferenceCode) {
            externalRefeneces.push({
                source: 'customer',
                value: data.externalReferenceCode,
            });
        }

        if (data.moqCode) {
            externalRefeneces.push({
                source: 'moqCode',
                value: data.moqCode,
            });
        }

        const pictures: string[] = data.photos.map((photo) => {
            return getFileIdFromAssetName(photo.id);
        });

        await mutateAsync({
            delegateId: data.delegateId || '',
            body: {
                category: data.category,
                collection: data.collection,
                color: data.color,
                description: data.description || '',
                enabled: data.isActive,
                externalReferences: externalRefeneces,
                hsCode: data.hsCode,
                name: data.name,
                pictures,
                sku: data.sku,
                tags: [],
                unitOfMeasurement: data.unit,
                workspace: workspace?._id || '',
                loaderType: workspace?.supplyChainLoaderType || 0,
                measureValue: data.measureValue,
                upc: data.upc,
                versions: [
                    {
                        costOfMaterials: 0,
                        billOfMaterials: [],
                        specifications: {
                            weight: data.weight,
                        },
                        version: 1,
                    },
                ],
            },
        });

        reset();
    };

    return (
        <DialogV2
            open={open}
            onClose={onClose}
            form={form}
            onSubmit={onSubmit}
            title='Add Product'
            isLoading={isLoading}
            isStepDialog
            steps={[
                {
                    title: 'Delegation Controls',
                    description: 'Select the delegate for this product.',
                    content: (
                        <DelegationInput
                            label='On Behalf Of.'
                            name='delegateId'
                        />
                    ),
                },
                {
                    title: 'Required Fields',
                    description: 'Enter the required fields for this product.',
                    content: <AddProductRequiredFields />,
                },
                {
                    title: 'Optional Fields',
                    description: 'Enter the optional fields for this product.',
                    content: <AddProductOptionalFields />,
                },
                {
                    title: 'Photos',
                    description: 'Upload photos for this product.',
                    content: (
                        <PhotoGrid
                            photos={photos}
                            onChange={(newPhotos) =>
                                form.setValue('photos', newPhotos)
                            }
                            delegateId={delegateId}
                            assetType='product'
                        />
                    ),
                },
            ]}
        />
    );
};
