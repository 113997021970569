import { PublicClientApplication } from '@azure/msal-browser';
import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
} from '@azure/msal-react';
import { Route, Routes } from 'react-router-dom';
import { LoginForm } from '../auth/login-form';
import { BlankLayout } from '../components-v2/blank-layout';
import { Layout } from '../components-v2/layout';
import { Categories } from '../pages/Categories';
import { CategoriesDetails } from '../pages/Categories/Details';
import { Dashboard } from '../pages/Dashboard';
import { Materials } from '../pages/Materials';
import { MaterialDetails } from '../pages/Materials/Details';
import { Products } from '../pages/Products';
import { ProductApproval } from '../pages/Products/Approval';
import { ProductDetails } from '../pages/Products/Details';
import { Purchases } from '../pages/Purchases';
import { PurchaseDetailV2 } from '../pages/Purchases/DetailsV2';
import { OrdersReport } from '../pages/Reports/Order';
import { ProductsReport } from '../pages/Reports/Products';
import { SubstanceReport } from '../pages/Reports/Substance';
import { SubstanceDetails } from '../pages/Reports/Substance/Details';
import { SuppliersReport } from '../pages/Reports/Supplier';
import { Settings } from '../pages/Settings';
import { Suppliers } from '../pages/Suppliers';
import { ProductApprovals } from '../pages/Products/Approvals';
import { ProductApprovalsDetails } from '../pages/Products/Approvals/Details';
import { SupplierDetails } from '../pages/Suppliers/details';
import { SalesOrders } from '../pages/SalesOrders';

export const AppRouterV2 = ({ pca }: { pca: PublicClientApplication }) => {
    return (
        <>
            <UnauthenticatedTemplate>
                <Routes>
                    <Route path='/' element={<LoginForm />} />
                </Routes>
            </UnauthenticatedTemplate>

            <AuthenticatedTemplate>
                <Routes>
                    <Route
                        path='/products/approval/:id'
                        element={
                            <BlankLayout pca={pca}>
                                <ProductApproval />
                            </BlankLayout>
                        }
                    />

                    <Route path='/' element={<Layout pca={pca} />}>
                        <Route path='/' element={<Dashboard />} />
                        <Route path='/settings' element={<Settings />} />
                        <Route path='/products' element={<Products />} />
                        <Route
                            path='/products/:id'
                            element={<ProductDetails />}
                        />
                        <Route path='/purchases' element={<Purchases />} />
                        <Route
                            path='/purchases/:id'
                            element={<PurchaseDetailV2 />}
                        />
                        <Route
                            path='/orders/:id'
                            element={<PurchaseDetailV2 />}
                        />
                        <Route path='/categories' element={<Categories />} />
                        <Route
                            path='/categories/:id'
                            element={<CategoriesDetails />}
                        />
                        <Route path='/components' element={<Materials />} />
                        <Route
                            path='/components/:id'
                            element={<MaterialDetails />}
                        />
                        <Route path='/suppliers' element={<Suppliers />} />
                        <Route
                            path='/suppliers/:id'
                            element={<SupplierDetails />}
                        />
                        <Route path='/orders' element={<SalesOrders />} />

                        <Route
                            path='/reports/substances'
                            element={<SubstanceReport />}
                        />

                        <Route
                            path='/report/substances/:name/:cas'
                            element={<SubstanceDetails />}
                        />

                        <Route
                            path='/reports/supplier'
                            element={<SuppliersReport />}
                        />
                        <Route
                            path='/reports/product'
                            element={<ProductsReport />}
                        />
                        <Route
                            path='/reports/order'
                            element={<OrdersReport />}
                        />

                        <Route
                            path='/approvals'
                            element={<ProductApprovals />}
                        />
                        <Route
                            path='/approvals/:workspace/:id'
                            element={<ProductApprovalsDetails />}
                        />
                    </Route>
                </Routes>
            </AuthenticatedTemplate>
        </>
    );
};
