import { Copy, Edit, GitPullRequest, Trash } from 'lucide-react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { FileResultObject } from 'src/app/_api_adb2c/attachment/file-result-object';
import { useAttachmentDownload } from 'src/app/_api_adb2c/attachment/hooks/use-attachment-download';
import { useBaseCategories } from 'src/app/_api_adb2c/product/category/hooks/use-base-categories';
import { useCategories } from 'src/app/_api_adb2c/product/category/hooks/use-categories';
import { useProduct } from 'src/app/_api_adb2c/product/product/hooks/use-product';
import {
    ProductUom,
    ProductUomLabels,
} from 'src/app/_api_adb2c/purchase/purchase/enums/product-uom.enum';
import { DetailLabel } from 'src/app/components-v2/detail-label';
import { useBreadcrumbStore } from 'src/app/stores/breadcrumb-store';
import { useContextStore } from 'src/app/stores/context-store';
import BlankImage from 'src/assets/blank-image.png';
import CascadeImage from 'src/assets/cascade.png';
import DocImage from 'src/assets/document.png';
import MaterialImage from 'src/assets/materials.png';
import OrderImage from 'src/assets/orders.png';
import SubstanceImage from 'src/assets/substance.png';
import {
    Tabs,
    TabsContent,
    TabsList,
    TabsTrigger,
} from 'src/components/ui/tabs';
import { cn } from 'src/lib/utils';
import { ProductDocuments } from './Documents/product-documents';
import { ProductBillOfMaterials } from './Materials';
import { ProductOrders } from './Orders/product-orders';
import { ProductSubstances } from './Substances';
import { ProductTraces } from './Traces/product-traces';
import { DeleteProduct } from './delete-product';
import { EditProduct } from '../edit-product';
import { CloneProduct } from './clone-product';
import { TooltipWrapper } from 'src/app/components/TooltipWrapper';
import { RequestApprovalProduct } from './request-approval-product';
import { useSalesOrders } from 'src/app/_api_adb2c/sales/sales/hooks/use-sales-orders';
import { getFileIdFromAssetName } from 'src/infrastructure/utils/extract-asset-id';

enum MenuOptions {
    BILL_OF_MATERIALS = 'B.O.M',
    DOCUMENTS = 'DOCS',
    ORDERS = 'Orders',
    TRACES = 'Traces',
    SUBSTANCES = 'B.O.S',
    // DOCS = 'Docs',
}

export const DisplayLabel: React.FC<{
    label: string | React.ReactNode;
    value?: string | number | React.ReactNode;
    className?: string;
}> = ({ label, value, className }) => {
    return (
        <div className={cn(`flex flex-1 flex-col gap-2 text-xs`, className)}>
            <label className='text-xs font-bold capitalize text-gray-400'>
                {label}
            </label>

            <span>{value || '-'}</span>
        </div>
    );
};

export const ProductDetails = () => {
    const { id } = useParams<{ id: string }>();
    const [searchParams, setSearchParams] = useSearchParams();
    const delegateId = searchParams.get('delegateId') || '';
    const { setBreadcrumbs } = useBreadcrumbStore();

    const [mode, setMode] = useState<'clone' | 'edit' | 'delete' | 'request'>();

    const [maxHeight, setMaxHeight] = useState(0);
    const tabRef = useRef<HTMLDivElement>(null);

    const switchMenu = (value: string) => {
        setSearchParams((prev) => {
            prev.set('menu', value);
            return prev;
        });
    };

    const { data } = useProduct(id || '', delegateId || '');

    // Just used to preload sales orders
    useSalesOrders(delegateId);

    const context = useContextStore();
    const { data: categories } = useCategories(delegateId || '');
    const { data: baseCategories } = useBaseCategories();

    const [images, setImages] = useState<FileResultObject[]>([]);
    const { mutateAsync: download } = useAttachmentDownload();

    useEffect(() => {
        if (!data) return;

        setBreadcrumbs([
            { label: 'Products', navigateTo: '/products' },
            { label: data.name },
        ]);
    }, [data, setBreadcrumbs]);

    const getProductCost = () => {
        if (!data) return '--';

        const version = data.versions?.[data.versions.length - 1];
        if (!version) return '--';

        return version?.billOfMaterials?.reduce(
            (acc, curr) =>
                acc + (curr.materialCost || 1) * (curr.consumption || 1),
            0
        );
    };

    const getProductCategory = () => {
        if (!data) return '--';

        let category = categories?.find(
            (x) => x._id === data.category?._id
        )?.name;

        if (!category) {
            category = baseCategories
                ?.find((x) => x._id === data.category?._id)
                ?.name.locales.find((x) => x.localeName === 'en')?.text;
        }

        return category;
    };

    const getProductWeight = () => {
        if (!data) return 0;
        if (!data.versions?.length) return 0;

        const latestVersion = data.versions[data.versions.length - 1];
        if (!latestVersion) return 0;

        return latestVersion?.billOfMaterials?.reduce(
            (acc, curr) => acc + (curr.weight || 0),
            0
        );
    };

    const downloadImages = useCallback(async () => {
        if (!data?.pictures?.length) return [];

        const images = await Promise.all(
            data.pictures.map(async (x) => {
                const imageId =
                    x instanceof Object
                        ? getFileIdFromAssetName(x.assetName)
                        : x;

                return await download({
                    delegateId: delegateId || '',
                    body: {
                        id: imageId.split('.')[0],
                        contentType: x.contentType,
                        genre: 'temporary',
                        name: x.originalName,
                        uploadedBy: context.user?._id || '',
                        workspace: context.workspace?._id || '',
                    },
                });
            })
        );

        setImages(images);
    }, [data, context.user, context.workspace, download, delegateId]);

    useEffect(() => {
        downloadImages();
    }, [downloadImages]);

    useEffect(() => {
        const updateTableHeight = () => {
            if (tabRef.current) {
                const parentElement = tabRef.current.parentElement;
                const viewportHeight = window.innerHeight;
                const parentTopOffset =
                    parentElement?.getBoundingClientRect().top || 0;
                const availableHeight = viewportHeight - parentTopOffset - 70;

                if (availableHeight !== maxHeight) {
                    setMaxHeight(availableHeight);
                }
            }
        };

        updateTableHeight();

        const resizeObserver = new ResizeObserver(updateTableHeight);
        if (tabRef.current && tabRef.current.parentElement) {
            resizeObserver.observe(tabRef.current.parentElement);
        }

        return () => {
            resizeObserver.disconnect();
        };
    }, [maxHeight]);

    const menuOptions = [
        {
            title: MenuOptions.BILL_OF_MATERIALS,
            icon: MaterialImage,
            alt: 'bom-icon',
            content: <ProductBillOfMaterials data={data} />,
        },
        {
            title: MenuOptions.DOCUMENTS,
            icon: DocImage,
            alt: 'doc-icon',
            content: <ProductDocuments product={id || ''} />,
        },
        {
            title: MenuOptions.ORDERS,
            icon: OrderImage,
            alt: 'order-icon',
            content: <ProductOrders data={data} />,
        },
        {
            title: MenuOptions.TRACES,
            icon: CascadeImage,
            alt: 'trace-icon',
            content: <ProductTraces product={data} />,
        },
        {
            title: MenuOptions.SUBSTANCES,
            icon: SubstanceImage,
            alt: 'substance-icon',
            content: <ProductSubstances productId={id || ''} />,
        },
    ];

    if (!data) return <></>;

    return (
        <>
            <div className='flex h-full flex-col gap-6'>
                <div className='grid grid-cols-12 gap-4'>
                    <div className='col-span-12'>
                        <span className='text-md font-bold'>
                            {data?.externalReferences?.find(
                                (x) => x.source === 'customer'
                            )?.value ||
                                data?.externalReferences?.find(
                                    (x) => x.source === 'externalDataId'
                                )?.value ||
                                Reflect.get(
                                    data?.externalReferences?.find(
                                        (x) => x.source === 'externalDataId'
                                    ) ?? {},
                                    'reference'
                                ) ||
                                data?.name ||
                                '--'}
                        </span>
                    </div>

                    <div className='col-span-12 grid grid-cols-12 gap-6 rounded-lg border border-gray-200 bg-white px-4 py-4'>
                        <div className='col-span-12 flex w-full items-center justify-between'>
                            <span className='text-xs font-bold underline decoration-red-800 decoration-2 underline-offset-8'>
                                General Information
                            </span>
                            <div className='flex gap-4'>
                                <TooltipWrapper label='Clone'>
                                    <Copy
                                        size={16}
                                        className='cursor-pointer hover:scale-125'
                                        onClick={() => {
                                            setMode('clone');
                                        }}
                                    />
                                </TooltipWrapper>

                                <TooltipWrapper label='Request Approval'>
                                    <GitPullRequest
                                        size={16}
                                        className='cursor-pointer hover:scale-125'
                                        onClick={() => {
                                            setMode('request');
                                        }}
                                    />
                                </TooltipWrapper>

                                <TooltipWrapper label='Edit'>
                                    <Edit
                                        size={16}
                                        className='cursor-pointer hover:scale-125'
                                        onClick={() => {
                                            setMode('edit');
                                        }}
                                    />
                                </TooltipWrapper>

                                <TooltipWrapper label='Delete'>
                                    <Trash
                                        size={16}
                                        className='cursor-pointer hover:scale-125'
                                        onClick={() => {
                                            setMode('delete');
                                        }}
                                    />
                                </TooltipWrapper>
                            </div>
                        </div>

                        <div className='col-span-8 grid grid-cols-3 gap-4'>
                            <DetailLabel title='Name'>{data?.name}</DetailLabel>
                            <DetailLabel title='Weight (KG/Unit)'>
                                {getProductWeight()}
                            </DetailLabel>
                            <DetailLabel title='Supplier'>
                                {data?.workspace.name}
                            </DetailLabel>
                            <DetailLabel title='Primary Category'>
                                {getProductCategory()}
                            </DetailLabel>
                            <DetailLabel title='PLM/OMS SKU'>
                                {data?.sku}
                            </DetailLabel>
                            <DetailLabel title='EXP. Lead Time'></DetailLabel>
                            <DetailLabel title='Variant'>
                                {data?.color}
                            </DetailLabel>
                            <DetailLabel title='MOQ'>
                                {data?.externalReferences?.find(
                                    (x) => x.source === 'moqCode'
                                )?.value ||
                                    Reflect.get(
                                        data?.externalReferences?.find(
                                            (x) => x.source === 'moqCode'
                                        ) ?? {},
                                        'reference'
                                    )}
                            </DetailLabel>
                            <DetailLabel title='Unit Of Measurement'>
                                {data?.unitOfMeasurement
                                    ? ProductUomLabels[
                                          data.unitOfMeasurement as ProductUom
                                      ]
                                    : '--'}
                            </DetailLabel>
                            <DetailLabel title='Unit Cost (USD)'>
                                {getProductCost()}
                            </DetailLabel>
                        </div>

                        <div className='col-span-4 flex justify-end gap-4'>
                            <img
                                className='max-h-[200px] min-h-[200px] min-w-[200px] max-w-[200px] rounded-l p-1'
                                src={images?.[0]?.token || BlankImage}
                                alt={images?.[0]?.attachment.originalName || ''}
                            />
                            <div>
                                {Array.from({ length: 4 }).map((_, index) => (
                                    <img
                                        className='max-h-[50px] min-h-[50px] min-w-[50px] max-w-[50px] rounded-l p-1'
                                        src={
                                            images?.[index + 1]?.token ||
                                            BlankImage
                                        }
                                        alt={
                                            images?.[index + 1]?.attachment
                                                .originalName || ''
                                        }
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>

                <div className='grid h-full flex-1 grid-cols-1'>
                    <Tabs
                        value={
                            searchParams.get('menu') ||
                            MenuOptions.BILL_OF_MATERIALS
                        }
                        className='flex w-full flex-col gap-2'
                        onValueChange={switchMenu}
                        ref={tabRef}
                    >
                        <TabsList className='w-full justify-start'>
                            {menuOptions.map((x, index) => {
                                return (
                                    <TabsTrigger
                                        value={x.title}
                                        className='w-24'
                                    >
                                        <figure className='flex cursor-pointer flex-col items-center rounded capitalize'>
                                            <img
                                                src={x.icon}
                                                alt={x.alt}
                                                className='max-w-6 max-h-6 rounded-l p-1'
                                                key={index}
                                                id={`${
                                                    x.alt
                                                }-${index.toString()}`}
                                            />
                                            <figcaption className='text-sm text-gray-500 dark:text-gray-400'>
                                                {x.title}
                                            </figcaption>
                                        </figure>
                                    </TabsTrigger>
                                );
                            })}
                        </TabsList>

                        {menuOptions.map((x) => {
                            return (
                                <TabsContent
                                    key={`menu-${x.title}`}
                                    value={x.title}
                                    className='flex-1 overflow-auto rounded-lg py-2'
                                >
                                    {x.content}
                                </TabsContent>
                            );
                        })}
                    </Tabs>
                </div>
            </div>

            {mode === 'clone' && (
                <CloneProduct
                    product={data._id}
                    open={mode === 'clone'}
                    onClose={() => setMode(undefined)}
                />
            )}

            {mode === 'request' && (
                <RequestApprovalProduct
                    productId={data._id}
                    open={mode === 'request'}
                    onClose={() => setMode(undefined)}
                />
            )}

            {mode === 'edit' && (
                <EditProduct
                    product={data._id}
                    open={mode === 'edit'}
                    onClose={() => setMode(undefined)}
                />
            )}

            {mode === 'delete' && (
                <DeleteProduct
                    product={data._id}
                    open={mode === 'delete'}
                    onClose={() => setMode(undefined)}
                />
            )}
        </>
    );
};
