import { useQuery } from '@tanstack/react-query';
import { toast } from 'sonner';
import { useLinkService } from 'src/app/_api_adb2c/purchase/link/hooks/use-link-service';
import { usePurchaseService } from 'src/app/_api_adb2c/purchase/purchase/hooks/use-purchase-service';
import { ProductModel } from 'src/app/_api_adb2c/purchase/purchase/models/manifest-item.model';
import {
    PurchaseModel,
    PurchaseOrderTraceModel,
} from 'src/app/_api_adb2c/purchase/purchase/models/purchase.model';
import { useRulesetService } from 'src/app/_api_adb2c/workspace/ruleset/hooks/use-ruleset-service';
import { WorkspaceRulesetModel } from 'src/app/_api_adb2c/workspace/ruleset/ruleset.model';
import { useWorkspaceService } from 'src/app/_api_adb2c/workspace/workspace/hooks/use-workspace-service';
import { WorkspaceModel } from 'src/app/_api_adb2c/workspace/workspace/workspace.model';
import { useContextStore } from 'src/app/stores/context-store';
import { SalesOrderModel } from '../models/sales-order.model';
import { SALES_ORDERS_QUERY_KEY } from './sales-order-query-keys';
import { useSalesOrderService } from './use-sales-order-service';

export type PurchaseOrderLink = PurchaseModel & {
    links: [
        PurchaseModel & {
            depth: number;
            cottonWeight?: number;
            cottonProducts?: ProductModel[];
        }
    ];
};

// Extending the base sales order with enriched data
export interface EnrichedSalesOrder extends Omit<SalesOrderModel, 'workspace'> {
    links: PurchaseOrderLink[]; // Replace with proper LinkModel when available
    purchase?: PurchaseModel;
    workspace: WorkspaceModel;
    rulesets: WorkspaceRulesetModel[];
    traces?: PurchaseOrderTraceModel[];
}

export function useSalesOrdersV2(delegateId?: string) {
    const { service } = useSalesOrderService();
    const { service: linkService } = useLinkService();
    const { service: rulesetService } = useRulesetService();
    const { service: purchaseService } = usePurchaseService();
    const { service: workspaceService } = useWorkspaceService();
    const context = useContextStore();

    const workspaceId = delegateId || context.workspace?._id || '';

    return useQuery<EnrichedSalesOrder[], Error>({
        queryKey: SALES_ORDERS_QUERY_KEY(workspaceId),
        queryFn: async () => {
            try {
                // 1. Fetch base sales orders
                const orders = await service.list(workspaceId);

                // 2. Enrich each order with related data
                const enrichedOrdersPromises = orders.map(
                    async (order: SalesOrderModel) => {
                        try {
                            const [links, purchase, workspace] =
                                await Promise.all([
                                    linkService.getLinked(
                                        workspaceId,
                                        order.purchaseOrderId
                                    ),
                                    purchaseService.get(
                                        order.customer.buyer._id,
                                        order.purchaseOrderId,
                                        '1'
                                    ),
                                    workspaceService.get(
                                        workspaceId,
                                        order.customer.buyer._id
                                    ),
                                ]);

                            const traces = await purchaseService.trace(
                                purchase.workspace,
                                purchase._id,
                                '1'
                            );

                            return {
                                ...order,
                                links,
                                purchase,
                                workspace,
                                rulesets: [], // Will be populated later
                                traces,
                            };
                        } catch (error) {
                            console.error(
                                `Failed to enrich order ${order._id}:`,
                                error
                            );
                            return null;
                        }
                    }
                );

                // 3. Wait for all enriched orders and filter out failed ones
                const enrichedOrders = (
                    await Promise.all(enrichedOrdersPromises)
                ).filter(
                    (order): order is EnrichedSalesOrder => order !== null
                );

                // 4. Collect and fetch rulesets
                const uniqueRulesetIds = new Set([
                    ...enrichedOrders.flatMap((order) => order.rules || []),
                    ...enrichedOrders.flatMap(
                        (order) => order.purchase?.rules || []
                    ),
                ]);

                const rulesets =
                    uniqueRulesetIds.size > 0
                        ? await rulesetService.batch({
                              targets: Array.from(uniqueRulesetIds),
                          })
                        : [];

                // 5. Create a map for quick ruleset lookup
                const rulesetMap = new Map(
                    rulesets.map((ruleset) => [ruleset._id, ruleset])
                );

                // 6. Final enrichment with rulesets
                return enrichedOrders.map((order) => ({
                    ...order,
                    rulesets: Array.from(
                        new Set([
                            ...(order.rules || []),
                            ...(order.purchase?.rules || []),
                        ])
                    )
                        .map((ruleId) => rulesetMap.get(ruleId))
                        .filter(
                            (ruleset): ruleset is WorkspaceRulesetModel =>
                                ruleset !== undefined
                        ),
                }));
            } catch (error) {
                console.error('Failed to fetch sales orders:', error);
                throw error;
            }
        },
        onError: (error: Error) => {
            toast.error(`Failed to fetch sales orders: ${error.message}`);
        },
    });
}
