import React from 'react';
import { X, Plus, GripHorizontal } from 'lucide-react';
import { cn } from 'src/lib/utils';
import { useAttachmentUpload } from 'src/app/_api_adb2c/attachment/hooks/use-attachment-upload';
import { FileCreateDataObject } from 'src/app/_api_adb2c/attachment/file-create-data-object';
import axios from 'axios';
import { useContextStore } from 'src/app/stores/context-store';

const MAX_PHOTOS = 5;

export interface Photo {
    id: string;
    url: string;
    isNew?: boolean;
    file?: File;
    contentType?: string;
    size?: number;
    name?: string;
}

interface PhotoGridProps {
    photos: Photo[];
    onChange: (photos: Photo[]) => void;
    delegateId?: string;
    assetType?: string;
    className?: string;
}

export const PhotoGrid: React.FC<PhotoGridProps> = ({
    photos = [],
    onChange,
    delegateId = '',
    assetType = 'product',
    className = '',
}) => {
    const { workspace, user } = useContextStore();
    const { mutateAsync: upload } = useAttachmentUpload();
    const fileInputRef = React.useRef<HTMLInputElement>(null);
    const [isDragging, setIsDragging] = React.useState(false);
    const [draggedPhoto, setDraggedPhoto] = React.useState<Photo | null>(null);
    const [dragOverId, setDragOverId] = React.useState<string | null>(null);
    const [isUploading, setIsUploading] = React.useState(false);

    const canAddMorePhotos = photos?.length < MAX_PHOTOS;

    const handleUploadClick = () => {
        if (!canAddMorePhotos) return;
        fileInputRef.current?.click();
    };

    const handleDelete = (id: string) => {
        const newPhotos = photos.filter((photo) => photo.id !== id);
        onChange(newPhotos);
    };

    const handleUpload = async (files: FileList) => {
        if (isUploading || !canAddMorePhotos) return;
        setIsUploading(true);

        try {
            const remainingSlots = MAX_PHOTOS - photos.length;
            const fileArray = Array.from(files).slice(0, remainingSlots);
            const uploadPromises = fileArray.map(async (file) => {
                const param: FileCreateDataObject = {
                    contentType: file.type,
                    size: file.size,
                    name: file.name,
                    uploadedBy: user?._id || '',
                    workspace: workspace?._id || '',
                    genre: 'temporary',
                    file: file,
                };

                const response = await upload({
                    file: param,
                    assetType,
                    delegateId,
                });

                await axios.put(response.token, file, {
                    headers: {
                        'x-ms-blob-type': 'BlockBlob',
                        'Content-Type': file.type,
                    },
                });

                return {
                    id: response.attachment.assetName,
                    url: URL.createObjectURL(file),
                    file,
                    contentType: file.type,
                    size: file.size,
                    name: file.name,
                };
            });

            const newPhotos = await Promise.all(uploadPromises);
            onChange([...photos, ...newPhotos]);
        } catch (error) {
            console.error('Error uploading photos:', error);
        } finally {
            setIsUploading(false);
        }
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files && files.length > 0) {
            handleUpload(files);
            event.target.value = '';
        }
    };

    const handleDragEnter = (e: React.DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
        if (canAddMorePhotos) {
            setIsDragging(true);
        }
    };

    const handleDragLeave = (e: React.DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
    };

    const handleDragOver = (e: React.DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDrop = (e: React.DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);

        if (!canAddMorePhotos) return;

        const files = e.dataTransfer.files;
        if (files && files.length > 0) {
            handleUpload(files);
        }
    };

    const handlePhotoDragStart = (photo: Photo) => {
        setDraggedPhoto(photo);
    };

    const handlePhotoDragEnd = () => {
        setDraggedPhoto(null);
        setDragOverId(null);
    };

    const handlePhotoDragOver = (e: React.DragEvent, photoId: string) => {
        e.preventDefault();
        if (draggedPhoto && draggedPhoto.id !== photoId) {
            setDragOverId(photoId);
        }
    };

    const handlePhotoDrop = (targetPhoto: Photo) => {
        if (!draggedPhoto) return;

        const newPhotos = [...photos];
        const draggedIndex = newPhotos.findIndex(
            (p) => p.id === draggedPhoto.id
        );
        const targetIndex = newPhotos.findIndex((p) => p.id === targetPhoto.id);

        if (draggedIndex !== -1 && targetIndex !== -1) {
            newPhotos.splice(draggedIndex, 1);
            newPhotos.splice(targetIndex, 0, draggedPhoto);
            onChange(newPhotos);
        }

        setDraggedPhoto(null);
        setDragOverId(null);
    };

    return (
        <div className={`space-y-4 ${className}`}>
            <div
                className='grid grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-4'
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
            >
                {photos?.map((photo) => (
                    <div
                        key={photo.id}
                        draggable
                        onDragStart={() => handlePhotoDragStart(photo)}
                        onDragEnd={handlePhotoDragEnd}
                        onDragOver={(e) => handlePhotoDragOver(e, photo.id)}
                        onDrop={() => handlePhotoDrop(photo)}
                        className={cn(
                            'group relative aspect-square overflow-hidden rounded-lg border-2 border-dashed',
                            !photo.isNew && 'p-1',
                            dragOverId === photo.id
                                ? 'border-blue-500'
                                : 'border-gray-300',
                            draggedPhoto?.id === photo.id && 'opacity-50'
                        )}
                    >
                        <div className='absolute inset-0 flex items-center justify-center p-1'>
                            <img
                                src={photo.url}
                                alt={photo.id}
                                className='h-full w-full rounded-lg object-contain'
                            />
                        </div>
                        <button
                            onClick={() => handleDelete(photo.id)}
                            className='absolute right-2 top-2 z-10 rounded-full bg-red-500 p-1 opacity-0 transition-opacity duration-200 group-hover:opacity-100'
                            aria-label='Delete photo'
                            type='button'
                        >
                            <X className='h-4 w-4 text-white' />
                        </button>
                        <div className='absolute left-2 top-2 z-10 rounded-full bg-gray-800/50 p-1 opacity-0 transition-opacity duration-200 group-hover:opacity-100'>
                            <GripHorizontal className='h-4 w-4 text-white' />
                        </div>
                    </div>
                ))}

                {canAddMorePhotos && (
                    <button
                        onClick={handleUploadClick}
                        className={cn(
                            'group relative aspect-square rounded-lg border-2 border-dashed p-4 transition-colors duration-200',
                            isDragging
                                ? 'border-blue-500 bg-blue-50'
                                : 'border-gray-300 hover:border-gray-400 hover:bg-gray-50',
                            isUploading && 'cursor-not-allowed opacity-50'
                        )}
                        type='button'
                        disabled={isUploading}
                    >
                        <div className='flex h-full w-full flex-col items-center justify-center gap-2 text-gray-500 group-hover:text-gray-600'>
                            <Plus className='h-8 w-8' />
                            <span className='text-sm'>
                                {isDragging
                                    ? 'Drop files here'
                                    : isUploading
                                    ? 'Uploading...'
                                    : 'Add Photo'}
                            </span>
                            <span className='text-xs text-gray-400'>
                                or drag and drop
                            </span>
                            <span className='text-xs text-gray-400'>
                                {photos.length} of {MAX_PHOTOS} photos
                            </span>
                        </div>
                    </button>
                )}
            </div>

            <input
                type='file'
                ref={fileInputRef}
                onChange={handleFileChange}
                className='hidden'
                accept='image/*'
                multiple
            />
        </div>
    );
};
