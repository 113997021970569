import { useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ProductModel } from 'src/app/_api_adb2c/product/product/models/product.model';
import { usePurchaseTrace } from 'src/app/_api_adb2c/purchase/purchase/hooks/use-purchase-trace';
import { useVirtualRoot } from 'src/app/_api_adb2c/purchase/virtual-purchase/hooks/use-virtual-root';
import { ProductTraceMap } from './product-trace-map';
import { ProductTraceMapInformation } from './product-trace-map-information';
import { ProductTraceUploader } from './product-trace-uploader';

interface Props {
    product?: ProductModel;
}

export function ProductTraces({ product }: Props) {
    const [files, setFiles] = useState<File[] | null>([]);

    const [searchParams] = useSearchParams();
    const delegateId = searchParams.get('delegateId') || '';

    const { data: order } = useVirtualRoot(product?._id || '', delegateId);
    const { data: traces } = usePurchaseTrace(order?._id || '', delegateId);

    const isEmptyTraces = useMemo(() => {
        if (!traces) return true;

        return traces.length === 0;
    }, [traces]);

    if (!product) return <></>;

    return (
        <>
            <div className='grid h-full grid-cols-12 gap-4 rounded-lg border bg-white'>
                <div className='col-span-3 flex flex-col gap-4 px-4 py-2'>
                    <div>
                        <span className='text-sm font-bold'>Plot Product</span>
                        <br />
                        <span className='text-sm font-bold'>
                            Supply Chain Map
                        </span>
                    </div>

                    {isEmptyTraces && !order && (
                        <ProductTraceUploader
                            productId={product._id}
                            files={files}
                            setFiles={setFiles}
                        />
                    )}

                    {/* {isVirtual && (
                        <div>
                            <span className='text-wrap text-xs font-medium'>
                                The trace map is mapped out based off existing
                                purchase orders so not upload is necessary.
                            </span>
                        </div>
                    )} */}

                    {!isEmptyTraces && (
                        <ProductTraceMapInformation productId={product._id} />
                    )}
                </div>

                <div className='relative col-span-9 border border-gray-200'>
                    {/* {!isVirtual ? ( */}
                    <ProductTraceMap productId={product._id} />
                    {/* ) : (
                        <ProductCascadeTraceMap />
                    )} */}
                    {/* <div className='absolute right-2 top-2 flex items-center gap-2 bg-gray-100 px-2 py-2'>
                        <Switch
                            id='toggle-mode'
                            checked={isVirtual}
                            onCheckedChange={setIsVirtual}
                        />
                        {isVirtual && (
                            <Label htmlFor='toggle-mode'>Physical</Label>
                        )}

                        {!isVirtual && (
                            <Label htmlFor='toggle-mode'>Virtual</Label>
                        )}
                    </div> */}
                </div>
            </div>
        </>
    );
}
