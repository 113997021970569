import { DownloadCloud, Loader2, Trash } from 'lucide-react';
import { ReactNode, useState } from 'react';
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from 'src/components/ui/popover';

export interface DocumentItem {
    id: string;
    name: string;
    type?: string;
    isPending?: boolean;
}

interface DocumentSection {
    title: string;
    documents: DocumentItem[];
    actions?: ReactNode;
    isRequired?: boolean;
}

interface DocumentViewerProps {
    sections: DocumentSection[];
    trigger?: ReactNode;
    emptyMessage?: string;
    onDownload?: (id: string, name: string) => void;
    onDelete?: (id: string) => Promise<void>;
}

export function DocumentViewer({
    sections,
    trigger,
    emptyMessage = 'No documents available.',
    onDownload,
    onDelete,
}: DocumentViewerProps) {
    const [downloadingIds, setDownloadingIds] = useState<Set<string>>(
        new Set()
    );

    const handleDownload = async (id: string, name: string) => {
        if (!onDownload || downloadingIds.has(id)) return;

        setDownloadingIds((prev) => new Set([...prev, id]));
        try {
            await onDownload(id, name);
        } finally {
            setDownloadingIds((prev) => {
                const newSet = new Set(prev);
                newSet.delete(id);
                return newSet;
            });
        }
    };

    return (
        <Popover>
            <PopoverTrigger asChild>
                {trigger || (
                    <span className='cursor-pointer text-xs text-blue-400 hover:underline hover:underline-offset-2'>
                        Documents
                    </span>
                )}
            </PopoverTrigger>

            <PopoverContent side='top' className='w-[300px]'>
                <div className='flex flex-col gap-2'>
                    <div className='flex max-h-[300px] flex-col gap-4 overflow-y-auto'>
                        {sections.map((section, index) => (
                            <div key={index} className='flex flex-col gap-2'>
                                <div className='flex items-center gap-1'>
                                    <span className='font-bold underline decoration-red-800 underline-offset-2'>
                                        {section.title}{' '}
                                    </span>
                                    <span className='text-xs text-red-500'>
                                        {section.isRequired && '*'}
                                    </span>
                                </div>

                                {section.documents.length === 0 && (
                                    <div className='text-xs text-gray-500'>
                                        {emptyMessage}
                                    </div>
                                )}

                                {section.documents.length > 0 && (
                                    <div className='flex flex-col gap-2'>
                                        {section.documents.map(
                                            (doc, docIndex) => (
                                                <div
                                                    key={docIndex}
                                                    className='group flex items-center justify-between gap-2'
                                                >
                                                    <div className='flex min-w-0 items-center gap-2'>
                                                        <span className='truncate text-xs'>
                                                            {doc.name}
                                                        </span>
                                                        {doc.isPending && (
                                                            <span className='flex-shrink-0 text-xs italic text-gray-500'>
                                                                (Pending Upload)
                                                            </span>
                                                        )}
                                                    </div>
                                                    <div className='flex flex-shrink-0 gap-2'>
                                                        {onDownload &&
                                                            !doc.isPending &&
                                                            (downloadingIds.has(
                                                                doc.id
                                                            ) ? (
                                                                <Loader2
                                                                    size={16}
                                                                    className='animate-spin'
                                                                />
                                                            ) : (
                                                                <DownloadCloud
                                                                    size={16}
                                                                    className='cursor-pointer hover:opacity-75'
                                                                    onClick={() =>
                                                                        handleDownload(
                                                                            doc.id,
                                                                            doc.name
                                                                        )
                                                                    }
                                                                />
                                                            ))}
                                                        {onDelete && (
                                                            <button
                                                                onClick={() =>
                                                                    onDelete(
                                                                        doc.id
                                                                    )
                                                                }
                                                                className='text-red-500'
                                                            >
                                                                <Trash
                                                                    size={16}
                                                                />
                                                            </button>
                                                        )}
                                                    </div>
                                                </div>
                                            )
                                        )}
                                    </div>
                                )}

                                {section.actions}
                            </div>
                        ))}
                    </div>
                </div>
            </PopoverContent>
        </Popover>
    );
}
