import { ClipboardPlus, Earth, ExternalLink, Link, Unlink } from 'lucide-react';
import { DateTime } from 'luxon';
import { memo, useMemo, useState } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { useSearchParams } from 'react-router-dom';
import { Handle, Position } from 'reactflow';
import { PurchaseOrderTraceModel } from 'src/app/_api_adb2c/purchase/purchase/models/purchase.model';
import { useCreateDelegation } from 'src/app/_api_adb2c/workspace/delegations/hooks/use-create-delegation';
import { useDelegations } from 'src/app/_api_adb2c/workspace/delegations/hooks/use-delegations';
import { TooltipWrapper } from 'src/app/components/TooltipWrapper';
import { generateOrderTitle } from 'src/app/utils/generate-order-title';
import { getCumulativeWeight } from 'src/app/utils/substance';
import SupplierShippedImage from 'src/assets/cascade-shipped.png';
import SupplierCascadeImage from 'src/assets/cascade-supplier.png';
import SupplierFactoryImage from 'src/assets/factory.png';
import { Card, CardContent } from 'src/components/ui/card';
import {
    Sheet,
    SheetContent,
    SheetDescription,
    SheetHeader,
    SheetTitle,
} from 'src/components/ui/sheet';
import countries from 'src/infrastructure/config/data/countries.json';
import { PurchaseManifest } from '../../../Details/purchase-manifest';
import { AddCascadePurchase } from '../modals/add-purchase-cascade';
import { UnlinkConfirmationModal } from '../modals/unlink-purchase-cascade';
import { PurchaseOverview } from '../../../Details/purchase-overview';
import { ProductUom } from 'src/app/_api_adb2c/purchase/purchase/enums/product-uom.enum';
import { ProductUomLabels } from 'src/app/_api_adb2c/purchase/purchase/enums/product-uom.enum';

interface Props {
    data: {
        purchase: PurchaseOrderTraceModel;
        supplierMode: boolean;
        supplierName: string;
        height: number;
        allowCreate?: boolean;
        parentId: string;
    };
}

export const PurchaseCascadeNode = memo(({ data }: Props) => {
    const {
        purchase,
        supplierMode,
        supplierName,
        height,
        allowCreate = true,
        parentId,
    } = data;
    const [searchParams] = useSearchParams();
    const delegateId = searchParams.get('delegateId') || '';
    const { data: delegations } = useDelegations(delegateId);
    const { mutateAsync: createDelegation } = useCreateDelegation();

    const [open, setOpen] = useState(false);
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [isUnlinkModalOpen, setIsUnlinkModalOpen] = useState(false);

    const orderInformation = useMemo(() => {
        const version = purchase?.versions?.[purchase.versions.length - 1];
        if (!version) return { cost: 0, count: 0 };

        const cost = version.manifest.reduce(
            (acc, item) => acc + item.ppu * item.quantity,
            0
        );
        const count = version.manifest.reduce(
            (acc, item) => acc + item.quantity,
            0
        );

        const firstProduct = version?.manifest[0]?.product;
        const unitOfMeasure =
            ProductUomLabels[firstProduct?.unitOfMeasurement as ProductUom];

        return { cost, count, unitOfMeasure };
    }, [purchase]);

    const isDelegate = useMemo(() => {
        if (!delegations) return false;

        return delegations.some(
            (x) => x.delegatedTo._id === purchase.supplier.seller._id
        );
    }, [delegations, purchase.supplier.seller._id]);

    const shippedDate = useMemo(() => {
        const shippedOn = purchase?.shippedOn?.[purchase.shippedOn.length - 1];
        if (!shippedOn) return '--';

        return DateTime.fromJSDate(new Date(shippedOn)).toFormat('yyyy-MM-dd');
    }, [purchase]);

    const getNodeTitle = () => {
        return supplierMode ? supplierName : generateOrderTitle(purchase);
    };

    const countryCode = useMemo(() => {
        return purchase.supplier?.seller?.country || 'US';
    }, [purchase]);

    const countryName = useMemo(() => {
        const country = countries.find((c) => c['alpha-2'] === countryCode);

        return country?.name || countryCode;
    }, [countryCode]);

    const substanceWeight = useMemo(() => {
        return getCumulativeWeight(purchase);
    }, [purchase]);

    const assignDelegate = async () => {
        await createDelegation({
            body: {
                delegatedTo: purchase.supplier.seller._id,
            },
        });
    };

    return (
        <>
            <div
                className='w-[220px] cursor-default rounded-md border-2 border-gray-300 bg-white'
                style={{ height: `${height}px` }}
            >
                <Handle type='target' position={Position.Left} />

                <Sheet open={open} onOpenChange={setOpen}>
                    <div className='flex w-full justify-between gap-4 border-b border-gray-300 bg-gray-200 p-1'>
                        <span className='truncate text-xs font-semibold text-gray-500'>
                            {getNodeTitle()}
                        </span>
                        <div className='flex cursor-pointer gap-2'>
                            <ExternalLink
                                onClick={() => setOpen(true)}
                                className='h-4 w-4 text-gray-700'
                            />

                            {allowCreate && (
                                <>
                                    {isDelegate ? (
                                        <ClipboardPlus
                                            size={16}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setIsAddModalOpen(true);
                                            }}
                                        />
                                    ) : (
                                        <TooltipWrapper label='Apply Delegation'>
                                            <Link
                                                size={16}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    assignDelegate();
                                                }}
                                                className='hover:scale-125'
                                            />
                                        </TooltipWrapper>
                                    )}

                                    <Unlink
                                        size={16}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setIsUnlinkModalOpen(true);
                                        }}
                                    />
                                </>
                            )}
                        </div>
                    </div>

                    <SheetContent className='z-[100000] min-w-[800px]'>
                        <SheetHeader>
                            <SheetTitle>
                                Purchase Order
                                <SheetDescription>
                                    {generateOrderTitle(purchase)}
                                </SheetDescription>
                            </SheetTitle>
                        </SheetHeader>

                        <div className='mt-8 flex flex-col gap-8'>
                            <div>
                                <span className='text-sm font-bold'>
                                    General Information
                                </span>
                                <Card className='px-2 py-4'>
                                    <CardContent className='flex gap-2 overflow-auto'>
                                        {/* Overview component here if needed */}
                                        <PurchaseOverview data={purchase} />
                                    </CardContent>
                                </Card>
                            </div>

                            <div>
                                <span className='text-sm font-bold'>
                                    Manifest Items
                                </span>
                                <PurchaseManifest
                                    data={purchase}
                                    allowNavigate={false}
                                />
                            </div>
                        </div>
                    </SheetContent>
                </Sheet>

                <div className='flex flex-col gap-2 p-2'>
                    {!supplierMode && (
                        <div className='flex gap-2'>
                            <img
                                src={SupplierFactoryImage}
                                alt=''
                                className='h-4 w-4'
                            />
                            <span className='w-4/5 truncate text-xs font-semibold text-gray-500'>
                                {supplierName}
                            </span>
                        </div>
                    )}

                    <div className='flex gap-2'>
                        <Earth className='h-4 w-4 text-gray-500' />
                        <div className='flex w-4/5 items-center gap-1'>
                            <span className='truncate text-xs font-semibold text-gray-500'>
                                {countryName}
                            </span>
                            <ReactCountryFlag
                                countryCode={countryCode}
                                title={countryCode}
                            />
                        </div>
                    </div>

                    <div className='flex gap-2'>
                        <img
                            src={SupplierCascadeImage}
                            alt=''
                            className='h-4 w-4'
                        />
                        <span className='w-4/5 truncate text-xs font-semibold text-gray-500'>
                            {substanceWeight}kg ({orderInformation.count}{' '}
                            {orderInformation.unitOfMeasure})
                        </span>
                    </div>

                    <div className='flex gap-2'>
                        <img
                            src={SupplierShippedImage}
                            alt=''
                            className='h-4 w-4'
                        />
                        <span className='w-4/5 truncate text-xs font-semibold text-gray-500'>
                            {shippedDate}
                        </span>
                    </div>
                </div>

                <Handle type='source' position={Position.Right} />
            </div>

            {isDelegate && allowCreate && (
                <AddCascadePurchase
                    open={isAddModalOpen}
                    onClose={() => setIsAddModalOpen(false)}
                    cascade={{
                        delegateId: purchase.supplier.seller._id,
                        purchaseOrderId: purchase._id,
                        workspaceId: purchase.workspace,
                    }}
                />
            )}

            {allowCreate && (
                <UnlinkConfirmationModal
                    open={isUnlinkModalOpen}
                    onClose={() => setIsUnlinkModalOpen(false)}
                    purchase={purchase}
                    parentPurchaseId={parentId}
                />
            )}
        </>
    );
});

PurchaseCascadeNode.displayName = 'PurchaseCascadeNode';
