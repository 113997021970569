import { ColumnDef } from '@tanstack/react-table';
import { CloudDownload, DownloadCloud, FileUp, Trash } from 'lucide-react';
import { useCallback, useMemo, useState } from 'react';
import { DefaultExtensionType, defaultStyles, FileIcon } from 'react-file-icon';
import { useSearchParams } from 'react-router-dom';
import { MaterialComposition } from 'src/app/_api_adb2c/product/material/request/product-material-data-object';
import { useProduct } from 'src/app/_api_adb2c/product/product/hooks/use-product';
import { useUpdateProduct } from 'src/app/_api_adb2c/product/product/hooks/use-update-product';
import {
    SupportDocType,
    SupportDocTypeLabel,
} from 'src/app/_api_adb2c/shared/support-doc-type.enum';
import { useBatchGetFiles } from 'src/app/_api_adb2c/workspace/file/hooks/use-batch-get-files';
import { TableV2 } from 'src/app/components-v2/table-v2';
import { useFileDownloader } from 'src/app/hooks/use-file-downloader';
import { getFileIdFromAssetName } from 'src/infrastructure/utils/extract-asset-id';
import { formatEnumValue } from 'src/infrastructure/utils/format-enum';
import { ProductDocumentUpload } from './product-document-upload';

interface Props {
    product: string;
}

interface VirtualDocument {
    _id: string;
    name: string;
    type: string;
    format: string;
    uploadedBy: string;
    fileType: 'Product' | 'Component' | 'Substance';
    subRows?: VirtualDocument[];
    isCustom?: boolean;
}

export function ProductDocuments({ product }: Props) {
    const [searchParams] = useSearchParams();
    const delegateId = searchParams.get('delegateId') || '';

    const [mode, setMode] = useState<'upload'>();

    const { data, isLoading } = useProduct(product, delegateId);
    const { downloadFiles } = useFileDownloader();

    const memoizedSdsFiles = useMemo(() => {
        const version = data?.versions?.[data?.versions.length - 1];

        if (!version) return [];

        const consolidatedComponentSdsFiles: {
            _id: string;
            component: MaterialComposition;
        }[] = [];

        version.billOfMaterials?.forEach((bill) => {
            bill.material?.compositions?.forEach((composition) => {
                if (composition.sdsFileId) {
                    consolidatedComponentSdsFiles.push({
                        _id: composition.sdsFileId,
                        component: composition,
                    });
                }
            });
        });

        return consolidatedComponentSdsFiles;
    }, [data]);

    const { data: documents } = useBatchGetFiles(
        memoizedSdsFiles.map((file) => file._id)
    );

    const { mutateAsync: update } = useUpdateProduct();

    const removeDocument = useCallback(
        async (id: string) => {
            const filteredDocuments = data?.documents?.filter(
                (doc) => doc._id !== id
            );

            await update({
                id: product,
                body: {
                    name: data?.name || '',
                    documents: filteredDocuments,
                },
            });
        },
        [data, product, update]
    );

    const consolidatedDocuments = useMemo(() => {
        const version = data?.versions?.[data?.versions.length - 1];
        if (!version) return [];

        const consolidatedDocuments: VirtualDocument[] = [];

        // Generate the product documents
        data?.documents.forEach((doc) => {
            const fileId = getFileIdFromAssetName(doc.file.assetName);
            consolidatedDocuments.push({
                _id: fileId,
                name: doc.file.originalName,
                type: doc.type,
                format: doc.file.originalName.split('.').pop() || '',
                uploadedBy: doc.uploader,
                fileType: 'Product',
            });
        });

        // Generate the component documents
        version.billOfMaterials?.forEach((bill) => {
            const consolidatedComponentDocuments: VirtualDocument[] = [];

            // Iterates through the material documents
            bill?.material?.documents?.forEach((doc) => {
                consolidatedComponentDocuments.push({
                    _id: getFileIdFromAssetName(doc.file.assetName),
                    name: doc.file.originalName,
                    type: doc.type,
                    format: doc.file.originalName.split('.').pop() || '',
                    uploadedBy: doc.uploader,
                    fileType: 'Component',
                });
            });

            // Iterates through the composition documents
            bill.material?.compositions?.forEach((comp) => {
                if (!comp.sdsFileId) return;
                const document = documents?.find(
                    (doc) => doc._id === comp.sdsFileId
                );

                if (document) {
                    consolidatedComponentDocuments.push({
                        _id: comp.sdsFileId,
                        name: document.name,
                        type: SupportDocType.SAFETY_DATA_SHEET,
                        format: document.name.split('.').pop() || '',
                        uploadedBy: document.uploadedBy,
                        fileType: 'Substance',
                    });
                }
            });

            if (consolidatedComponentDocuments.length > 0) {
                consolidatedDocuments.push({
                    _id: 'custom',
                    name: `Documents from ${bill.name}`,
                    type: '',
                    format: '',
                    uploadedBy: '',
                    fileType: 'Component',
                    subRows: consolidatedComponentDocuments,
                    isCustom: true,
                });
            }
        });

        return consolidatedDocuments;
    }, [documents, data]);

    const columns: ColumnDef<VirtualDocument>[] = useMemo(() => {
        const columns: ColumnDef<VirtualDocument>[] = [
            {
                id: 'document',
                header: 'Document',
                accessorFn: (row) => row.name,
                cell: ({ row }) => {
                    if (row.depth > 0) {
                        return row.original.name;
                    }

                    return (
                        <div
                            className='flex items-center gap-4'
                            onClick={() => row.toggleExpanded()}
                        >
                            <span className='truncate'>
                                {row.original.name}
                            </span>
                        </div>
                    );
                },
            },
            {
                id: 'type',
                header: 'Type',
                accessorFn: (row) => row.type,
                cell: ({ row }) => {
                    const type =
                        SupportDocTypeLabel[
                            row.original.type as SupportDocType
                        ];

                    if (!type) return formatEnumValue(row.original.type);

                    return type;
                },
            },
            {
                id: 'format',
                header: 'Format',
                size: 10,
                cell: ({ row }) => {
                    const extension = row.original.name.split('.').pop();

                    if (!extension) return '--';

                    const styles =
                        defaultStyles?.[extension as DefaultExtensionType];

                    return (
                        <div className='h-8 w-6'>
                            <FileIcon
                                {...styles}
                                radius={4}
                                extension={extension}
                            />
                        </div>
                    );
                },
            },
            {
                id: 'uploadedBy',
                header: 'Uploaded By',
                accessorFn: (row) => row.uploadedBy,
            },
            {
                id: 'actions',
                size: 10,
                header: 'Actions',
                cell: ({ row }) => {
                    return (
                        <div className='flex gap-4'>
                            <CloudDownload
                                size={16}
                                className='hover:scale-125'
                                onClick={() => {
                                    downloadFiles([row.original._id], {
                                        fileName: row.original.name,
                                    });
                                }}
                            />
                            <Trash
                                size={16}
                                className='hover:scale-125'
                                onClick={() => removeDocument(row.original._id)}
                            />
                        </div>
                    );
                },
            },
        ];

        return columns;
    }, [downloadFiles, removeDocument]);

    if (!data) return <></>;

    return (
        <>
            <div className='flex h-full flex-col'>
                <TableV2
                    columns={columns}
                    data={consolidatedDocuments}
                    isLoading={isLoading}
                    label='Product Documents'
                    actions={[
                        {
                            label: 'Upload',
                            icon: <FileUp size={16} />,
                            id: 'upload',
                            onClick: () => setMode('upload'),
                        },
                        {
                            label: 'Download',
                            icon: <DownloadCloud size={16} />,
                            id: 'download',
                            onClick: () =>
                                downloadFiles(
                                    consolidatedDocuments.map((doc) => doc._id)
                                ),
                        },
                    ]}
                    subRowsField='subRows'
                    disabledTooltipColumns={['actions']}
                />
            </div>

            {mode === 'upload' && (
                <ProductDocumentUpload
                    product={product}
                    open={mode === 'upload'}
                    onClose={() => setMode(undefined)}
                />
            )}
        </>
    );
}
