import { Paperclip, Upload } from 'lucide-react';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'sonner';
import { useDelegations } from 'src/app/_api_adb2c/workspace/delegations/hooks/use-delegations';
import { useSalesOrdersV2 } from 'src/app/_api_adb2c/sales/sales/hooks/use-sales-orders-v2';
import { DialogV2 } from 'src/app/components-v2/dialog-v2';
import {
    FileInput,
    FileUploader,
    FileUploaderContent,
    FileUploaderItem,
} from 'src/app/components-v2/file-dropzone';
import { useContextStore } from 'src/app/stores/context-store';
import { Button } from 'src/components/ui/button';
import { useImportAction } from '../../Purchases/DetailsV2/use-import-action';
import { useImportCascade } from '../../Purchases/DetailsV2/use-import-cascade';
import { useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import {
    BASE_BATCH_SALES_ORDERS_QUERY_KEY,
    SALES_ORDERS_QUERY_KEY,
} from 'src/app/_api_adb2c/sales/sales/hooks/sales-order-query-keys';
import { BASE_PURCHASE_TRACE_QUERY_KEY } from 'src/app/_api_adb2c/purchase/purchase/hooks/purchase-query-keys';

interface SalesOrderCascadeUploadProps {
    isOpen: boolean;
    onClose: () => void;
}

export function SalesOrderCascadeUpload({
    isOpen,
    onClose,
}: SalesOrderCascadeUploadProps) {
    const context = useContextStore();
    const [searchParams] = useSearchParams();

    const delegateId = searchParams.get('delegateId') || '';
    const orderId = searchParams.get('order') || '';

    const { submit: importDefault } = useImportCascade();
    const { submit: importAction } = useImportAction();
    const { data: delegates } = useDelegations();
    const [files, setFiles] = useState<File[] | null>(null);
    const client = useQueryClient();

    const { data } = useSalesOrdersV2(delegateId);
    const delay = (ms: number) =>
        new Promise((resolve) => setTimeout(resolve, ms));
    const order = data?.find((o) => o._id === orderId);

    const supplyChainLoaderType = useMemo(() => {
        let feature = context.workspace?.features;

        if (delegateId) {
            const delegate = delegates?.find(
                (delegate) => delegate.delegatedTo._id === delegateId
            );

            if (delegate) {
                feature = delegate.delegatedTo.features;
            }
        }

        return feature?.supplyChainLoaderType
            ? feature.supplyChainLoaderType
            : context.workspace?.supplyChainLoaderType || 0;
    }, [
        context.workspace?.features,
        context.workspace?.supplyChainLoaderType,
        delegates,
        delegateId,
    ]);

    const [isSubmitting, setIsSubmitting] = useState(false);
    const Template =
        supplyChainLoaderType === 0
            ? require('../default_template.xlsx')
            : require('../action_template.xlsx');

    const importCascade = async () => {
        try {
            setIsSubmitting(true);
            if (!files) return;
            if (files.length === 0) return;

            if (!order) return;
            if (!order.purchaseOrderId || !order.purchase?.workspace) return;

            const file = files[0];

            if (supplyChainLoaderType === 0) {
                console.debug(`Importing default cascade`);
                await importDefault(
                    order.purchase?.workspace || '',
                    order.purchaseOrderId,
                    file
                );
            } else {
                console.debug(`Importing action cascade`);
                await importAction(
                    order.purchase?.workspace || '',
                    order.purchaseOrderId,
                    file
                );
            }

            // Wait for the cascade to be imported
            await delay(1500);

            client.invalidateQueries({
                queryKey: SALES_ORDERS_QUERY_KEY(order.workspace._id),
            });

            client.invalidateQueries({
                queryKey: BASE_PURCHASE_TRACE_QUERY_KEY,
            });

            client.invalidateQueries({
                queryKey: [BASE_BATCH_SALES_ORDERS_QUERY_KEY],
            });

            onClose();
        } catch (e) {
            const error = e as Error;
            toast.error(`Failed to import purchase: ${error.message}`);

            console.error(error);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <DialogV2
            open={isOpen}
            onClose={onClose}
            title='Upload Documents'
            isStepDialog
            onFinish={importCascade}
            isLoading={isSubmitting}
            steps={[
                {
                    title: 'Upload Documents',
                    description: 'Upload the documents for the cascade',
                    content: (
                        <>
                            <FileUploader
                                value={files}
                                onValueChange={(files) => {
                                    setFiles(files);
                                }}
                                dropzoneOptions={{
                                    maxFiles: 1,
                                    maxSize: 10 * 1024 * 1024,
                                    accept: {
                                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                                            ['.xlsx'],
                                    },
                                }}
                                className='border border-dashed'
                            >
                                <FileInput>
                                    <div className='flex flex-col items-center justify-center gap-4 p-12'>
                                        <div className='rounded-full bg-gray-50 p-4'>
                                            <Upload className='h-8 w-8 text-gray-400' />
                                        </div>
                                        <div className='text-center text-xs'>
                                            <p className='text-xs font-medium text-gray-700'>
                                                Drop files here or click to
                                                upload
                                            </p>
                                            <p className='text-xs text-gray-500'>
                                                Supports PDF, PNG, JPG, DOC,
                                                DOCX files up to 10MB
                                            </p>
                                        </div>
                                    </div>
                                </FileInput>

                                <FileUploaderContent>
                                    {files &&
                                        files.length > 0 &&
                                        files.map((file, i) => (
                                            <FileUploaderItem key={i} index={i}>
                                                <Paperclip className='h-4 w-4 stroke-current' />
                                                <span>{file.name}</span>
                                            </FileUploaderItem>
                                        ))}
                                </FileUploaderContent>
                            </FileUploader>
                        </>
                    ),
                },
            ]}
            extraControls={
                <Button size='sm' variant='outline'>
                    <a href={Template} download='cascade-template.xlsx'>
                        Download Template
                    </a>
                </Button>
            }
            size='lg'
        />
    );
}
