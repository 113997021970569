import { saveAs } from 'file-saver';
import { CloudUpload, Paperclip } from 'lucide-react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'sonner';
import {
    FileInput,
    FileUploader,
    FileUploaderContent,
    FileUploaderItem,
} from 'src/app/components-v2/file-dropzone';
import { TooltipWrapper } from 'src/app/components/TooltipWrapper';
import { dropZoneConfig } from 'src/app/pages/Materials/Details/Substance/substance-sds-file-upload';
import { useContextStore } from 'src/app/stores/context-store';
import { Button } from 'src/components/ui/button';
import { useImportDefaultTemplate } from './import-default-template';

interface Props {
    productId: string;
    files: File[] | null;
    setFiles: (files: File[] | null) => void;
}

export function ProductTraceUploader({ productId, files, setFiles }: Props) {
    const context = useContextStore();
    const supplyChainLoaderType =
        context.workspace?.features.supplyChainLoaderType || 0;

    const [searchParams] = useSearchParams();
    const delegateId = searchParams.get('delegateId') || '';

    const Document =
        supplyChainLoaderType === 0
            ? require('./default_template.xlsx')
            : require('./action_template.xlsx');

    const { submit: importDefault, loading } = useImportDefaultTemplate();

    const submitTrace = async () => {
        if (!files || files.length === 0) {
            toast.error('Please select a file to import');
            return;
        }

        const file = files[0];

        await importDefault(
            delegateId || context.workspace?._id || '',
            file,
            productId
        );
    };

    return (
        <>
            <div className='space-y-4'>
                <div
                    onClick={() => {
                        saveAs(
                            new Blob([Document], {
                                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                            }),
                            'product-supply-chain.xlsx'
                        );
                    }}
                    className='flex cursor-pointer items-center space-x-4'
                >
                    <div className='flex h-6 w-6 flex-none items-center justify-center rounded-full bg-[#800000] text-sm text-white'>
                        1
                    </div>
                    <p className='text-xs '>
                        Click to{' '}
                        <span className='font-bold text-blue-600 underline'>
                            download
                        </span>{' '}
                        the template and fill in the relationships
                    </p>
                </div>
            </div>

            <div className='space-y-4'>
                <div className='flex items-center space-x-4'>
                    <div className='flex h-6 w-6 flex-none items-center justify-center rounded-full bg-[#800000] text-white'>
                        2
                    </div>
                    <p className='text-xs text-gray-700'>
                        Drag the completed template to the box below
                    </p>
                </div>

                <div className='flex flex-col gap-4'>
                    <FileUploader
                        dropzoneOptions={{
                            ...dropZoneConfig,
                            accept: {
                                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                                    ['.xlsx'],
                            },
                        }}
                        value={files}
                        reSelect
                        onValueChange={setFiles}
                    >
                        <FileInput className='group h-full min-h-fit border border-dashed border-gray-400 bg-gray-200 hover:bg-gray-300'>
                            <div className='flex h-full w-full flex-col items-center justify-center gap-4 pb-4 pt-3 text-center '>
                                <CloudUpload size={30} />
                                <span className='text-xs text-gray-500'>
                                    Drag the file here
                                    <br />
                                    Only XLSX files are allowed
                                </span>
                            </div>
                        </FileInput>

                        <FileUploaderContent className='overflow-hidden'>
                            {files &&
                                files.length > 0 &&
                                files.map((file, i) => (
                                    <TooltipWrapper label={file.name}>
                                        <FileUploaderItem
                                            key={i}
                                            index={i}
                                            className='flex justify-between gap-8 truncate text-xs'
                                            childrenClassName='w-4/5'
                                        >
                                            <Paperclip className='h-4 w-4 flex-none stroke-current' />
                                            <span className='truncate'>
                                                {file.name}
                                            </span>
                                        </FileUploaderItem>
                                    </TooltipWrapper>
                                ))}
                        </FileUploaderContent>
                    </FileUploader>

                    <Button
                        variant='default'
                        className='w-min self-end'
                        size='sm'
                        onClick={() => {
                            submitTrace();
                        }}
                        loading={loading}
                    >
                        Submit
                    </Button>
                </div>
            </div>
        </>
    );
}
