import { useQuery } from '@tanstack/react-query';
import { toast } from 'sonner';
import { BASE_CATEGORIES_QUERY_KEY } from './category-query-keys';
import { useCategoryService } from './use-category-service';
import { useContextStore } from 'src/app/stores/context-store';
import { useMemo } from 'react';

interface BaseCategoryModel {
    base: {
        id: string;
        code: string;
        name: {
            locales: {
                text: string;
                localeName: string;
            }[];
        };
        description: {
            locales: {
                text: string;
                localeName: string;
            }[];
        };
    };
    code: string;
    name: {
        locales: {
            text: string;
            localeName: string;
        }[];
    };
    description: {
        locales: {
            text: string;
            localeName: string;
        }[];
    };
    unit: string;
    _id: string;
}

export function useBaseCategories(type?: string) {
    const { service } = useCategoryService();
    const context = useContextStore();
    const loaderType = useMemo(() => {
        return context.workspace?.features.supplyChainLoaderType === 1
            ? 'hardgoods'
            : 'apparel';
    }, [context.workspace?.features.supplyChainLoaderType]);

    return useQuery<BaseCategoryModel[]>({
        queryKey: [BASE_CATEGORIES_QUERY_KEY, type || loaderType],
        queryFn: async () => {
            return await service.loadBaseCategories(type || loaderType);
        },
        onError: (err) => {
            const error = err as Error;

            toast.error(`Failed to load base categories: ${error.message}`);
        },
        // enabled: !!type,
    });
}
