import { TextInputV2 } from 'src/app/components/Form/TextInputV2';

export function SupplierContactsFields() {
    return (
        <div>
            <TextInputV2 name='firstName' required label='First Name' />

            <TextInputV2 name='lastName' label='Last Name' required />

            <TextInputV2 name='email' label='Email' required />
        </div>
    );
}
